import React from "react";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Assess.css";
import axios from "axios";

type ChatMessageProps = {
  isUser?: boolean;
  children: React.ReactNode;
};

const ChatMessage = styled.div<ChatMessageProps>`
  text-align: ${({ isUser }) => (isUser ? "right" : "left")};
`;

interface ChatInterfaceProps {
  initialChat: string[];
}

interface StudentInfoResponse {
  textbook: Record<string, subInfo>;
  age: string;
  student_id: string;
  grade: string;
}

interface subInfo {
  publisher: string;
  subject: string;
  grade: string;
}

// Replace with your actual API call
const ChatInterface: React.FC<ChatInterfaceProps> = ({ initialChat }) => {
  const [chat, setChat] = useState(initialChat);

  const sendMessageToBot = async (message: string | FormData) => {
    console.log("HIHIHI");
    try {
      let response;
      if (typeof message === "string") {
        response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/api/chatbot/test`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ user_input: message }),
          }
        );
      } else if (message instanceof FormData) {
        // for (const [key, value] of message.entries()) {
        //   console.log(`${key}:`, value);
        // }
        response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/api/progress-review/qa-review`,
          {
            method: "POST",
            body: message,
          }
        );
      }
      if (!response) {
        throw new Error("No response from the server.");
      }
      const data = await response.json();
      if (!response.ok) {
        console.error("Server responded with an error:", data);
        return;
      }
      // console.log(data.payload)
      const botResponse =
        `Answers:\n${data.payload.model_output.answer_breakdown}\nGrade: ${data.payload.model_output.grade}\nComments: ${data.payload.model_output.comments}\nSuggestions for improvement: ${data.payload.model_output.suggested_improvements}` ??
        "Message received.";
      setChat([
        ...chat,
        typeof message === "string" ? message : "File Uploaded",
        botResponse,
      ]);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className="ChatContainer">
      <div className="messagesWrapper">
        {/* Render chat messages in chronological order */}
        {chat.map((msg, index) => {
          const isUser = index % 2 !== 0;
          const messageClass = isUser ? "user-message" : "bot-message";

          let renderedLines;
          if (typeof msg === "string") {
            // Splitting the message by \n and mapping each part to a new line
            // console.log(msg)
            // renderedLines = msg.split('\n').map((line, i) => <span key={i}>{line}<br /></span>);
            renderedLines = msg.replace(/\\n/g, "\n");
          } else {
            // If the message isn't a string, render it directly (assuming it's a valid JSX element or other renderable content)
            renderedLines = msg;
          }

          return (
            <div key={index} className={`chat-message ${messageClass}`}>
              {isUser ? "Bot: " : "User: "}
              <div className="text-pre-line">{renderedLines}</div>
            </div>
          );
        })}
      </div>
      {/* The ChatInput component remains at the bottom */}
      <ChatInput onSendMessage={sendMessageToBot} />
    </div>
  );
};

interface ChatInputProps {
  onSendMessage: (message: string | FormData) => void;
  studentId?: string | "";
}

const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage, studentId }) => {
  const [message, setMessage] = useState("");
  const [isRecording, setIsRecording] = useState(false); // Track recording state
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // New state for selected file
  const [attachmentText, setAttachmentText] = useState<string>("Attach");
  const [recordedBlob, setRecordedBlob] = useState<Blob | null>(null);
  const [voiceMessageText, setVoiceMessageText] = useState("");
  const [subject, setSubject] = useState("");
  const [studentInfo, setStudentInfo] = useState<StudentInfoResponse[]>([]);
  const [student_id, setStudentId] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    if (selectedFile) {
      const { studentId } = location.state || {};
      if (studentId) {
        setStudentId(studentId);
        console.log(studentId);
      }
      // const fetchData = async () => {
      //   try {
      //     const response = await axios.get(
      //       `${process.env.REACT_APP_API_ENDPOINT}/api/students/${student_id}`
      //     );
      //     setStudentInfo([response.data]);
      //     console.log(response.data);
      //   } catch (error) {
      //     console.error('Error fetching data:', error);
      //   }
      // };
      // fetchData();
      setAttachmentText(selectedFile.name);
    } else {
      setAttachmentText("Attach");
    }
  }, [selectedFile, student_id]);

  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleSend = async () => {
    if (message.trim() || selectedFile) {
      try {
        let formData: FormData | undefined = undefined;
        if (selectedFile) {
          formData = new FormData();
          formData.append("image_files", selectedFile, selectedFile.name);
          formData.append("topic", message);
          formData.append("date", "2024-07-05");
          formData.append("student_id", student_id);
          formData.append("publisher", "pearson"); //# TODO
          formData.append("subject", subject);
          formData.append("grade", "primary5");
          setSelectedFile(null);
          setAttachmentText("Attach");
          setMessage("");
        } else {
          await onSendMessage(message);
          setMessage("");
        }

        if (formData) {
          // Sending the file via FormData
          await onSendMessage(formData);
        }
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      // Here you could also implement logic to handle the selected file (e.g., upload it)
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSend();
    }
  };

  const triggerFileSelect = () => {
    document.getElementById("file-input")?.click(); // Trigger file input click
  };

  return (
    <div className="chat-input-container">
      <input
        type="text"
        className="input-field"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder="Type a message..."
      />
      {/* Attachment button */}
      <button className="attachment-button" onClick={triggerFileSelect}>
        📎 {attachmentText} {/* Display dynamic text here */}
      </button>
      {/* Hidden file input */}
      <select
        className="dropdown-menu-prac"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      >
        <option value="">Choose Subject</option>
        <option value="english">English</option>
        <option value="math">Mathematics</option>
      </select>
      <input
        id="file-input"
        type="file"
        style={{ display: "none" }}
        onChange={handleFileSelect}
      />
      <button className="send-button" onClick={handleSend}>
        Send
      </button>
    </div>
  );
};

export default ChatInterface;
export { ChatInput };
