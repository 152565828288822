import React, { useState } from 'react';  
import './TeacherApp.css';
import { useNavigate } from 'react-router-dom';
import NavBar from '../NavBar/NavBar';  
import SearchBar from '../SearchBar/SearchBar';  
import Grid from '@mui/material/Grid';
import Card from '../Card/Card';
  
const cardsData = [  
  { title: 'Practice Question', description: 'Create some practice questions for a specific piece of content.', icon: '🔍', color: '#E0FFE1', page:"practice"},
  { title: 'Exam Generator', description: "Create an exam paper for a specific topic to test students' knowledge.", icon: '❓', color: '#E0FFE1' },
  { title: 'Lesson Plan', description: 'Create structured, detailed lesson plans tailored for curriculum and students\' needs.', icon: '✏️', color: '#E0E7FF', page:"studyplan" },  
  { title: 'Pre-Lesson', description: 'Create pre-lesson notes on a specific topic to enhance student learning.', icon: '📋', color: '#FFE4E1', page:"prenotes" },  
  { title: 'Post-Lesson', description: 'Create quick end-of-lesson notes to reinforce student understanding.', icon: '🎟️', color: '#E0E7FF' },
  { title: 'Adjust Notes', description: 'Adjust the complexity of notes for a specific topic.', icon: '📏', color: '#FFE4E1' },    
  { title: 'Work Assesment', description: "Mark the student's work and assess performance according to accuracy.", icon: '✅', color: '#E0E7FF',page:"assess" },  
  { title: 'Progress Review', description: 'Review and evaulate the summarized performance of the student.', icon: '📈', color: '#E0FFE1' }, 
];  
  
const App: React.FC = () => {  
  const [activeCategory, setActiveCategory] = useState<string>('all');
  const navigate = useNavigate();
  const [studentId, setStudentId] = useState<string | null>(null);

  type CategoryKey = keyof typeof categorizedData;
  const handleCardClick = (title: string) => {  
    alert(`You clicked on ${title}`);  
  };  
  const handleCategoryChange = (category: string) => {
    setActiveCategory(category);
  };
  const isKnownCategory = (category: string): category is CategoryKey => 
    ['all', 'learn', 'create'].includes(category as CategoryKey);
  const categorizedData = {
    all: cardsData,
    learn: cardsData.filter(
      card =>
        ['Exam Generator', 'Practice Question', 'Progress Review', 'Work Assessment', 'Adjust Notes'].includes(card.title)
    ),
    create: cardsData.filter(
      card =>
        ['Exam Generator', 'Practice Question', 'Lesson Plan', 'Pre-Lesson', 'Post-Lesson'].includes(card.title)
    ),
  };
  const filteredCards = isKnownCategory(activeCategory)
    ? activeCategory === 'all' 
      ? cardsData 
      : categorizedData[activeCategory]
    : [];

    const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const studentIdInput = (event.target as any).studentId;
      setStudentId(studentIdInput.value);
    };
  return (  
    <div className="App"> 
        <div className='head'>
          <NavBar onCategoryChange={handleCategoryChange} activeCategory={activeCategory} />
            <SearchBar />
        </div> 
        {!studentId && (
        <form onSubmit={handleLogin}>
          <label htmlFor="studentId">Enter Student ID:</label>
          <input type="text" id="studentId" name="studentId" />
          <button type="submit">Login</button>
        </form>
      )}
      {studentId && (
      <div className="card-grid"> 
        <Grid container spacing={2}>
            {/* First row with 3 buttons */}
            <Grid item xs={12}>
            <Grid container justifyContent="space-between">
            <Grid item xs={0.5} /> {/* Empty grid for left indent */}
            {filteredCards.slice(0, 3).map((card, index) => (
                <Grid item key={index} xs={3.5}>
                <Card  
                    key={index}  
                    title={card.title}  
                    description={card.description}  
                    icon={card.icon}  
                    color={card.color}  
                    onClick={() => navigate(`/${card.page}`,{state: {studentId}})}/>
                </Grid>
            ))}
            <Grid item xs={0.5} /> {/* Empty grid for right indent */}
            </Grid>
            </Grid>   
            
            <Grid item xs={12}>
            <Grid container justifyContent="space-between">
            <Grid item xs={0.5} /> {/* Empty grid for left indent */}
            {filteredCards.slice(3, 6).map((card, index) => (
                <Grid item key={index} xs={3.5}>
                <Card  
                    key={index}  
                    title={card.title}  
                    description={card.description}  
                    icon={card.icon}  
                    color={card.color}  
                    onClick={() => navigate(`/${card.page}`,{state: {studentId}})}
                />
                </Grid>
            ))}
            <Grid item xs={0.5} /> {/* Empty grid for right indent */}
            </Grid>
            </Grid>

            <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item xs={0.5} /> {/* Empty grid to push the buttons to the center */}
            {filteredCards.slice(6).map((card, index) => (
              <Grid item key={index + 6} xs={3.5}>
                <Card  
                    key={index}  
                    title={card.title}  
                    description={card.description}  
                    icon={card.icon}  
                    color={card.color}  
                    onClick={() => navigate(`/${card.page}`,{state: {studentId}})}/>
              </Grid>
            ))}
            <Grid item xs={0.5} /> {/* Another empty grid for symmetry */}
          </Grid>
        </Grid>
        </Grid>
      </div>
      )}
    </div>  
  );  
  function navigateToTChatPage(title: string) {
    // Navigate to TChatPage with the card title as a parameter
    window.location.href = `/TChat/{title}`;
  }
};
  
export default App;  
