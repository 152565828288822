// Setup function for this Teacher Page
import React from "react";
import { useParams } from "react-router-dom";
//import { TeacherFunctions, TeacherFunctions2 } from '../../components/TeacherFunctions';
//import { TeacherFunctions } from '../../components/TeacherFunctions';
import SApp from "../../components/StudentApp/StudentApp";
import "./component/style.css";

export default function TeacherPage() {
  return (
    <>
      {/* <TeacherFunctions2 /> */}
      <div className="page-container">
        <div className="student-page">
          <header className="header">
            <div className="header-left">
              <div className="title">
                <strong>AIBO</strong>
              </div>
            </div>
          </header>
          <div className="main-content-student">
            <SApp />
          </div>
        </div>
      </div>
    </>
  );
}
