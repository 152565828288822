import React, { useEffect, useState } from "react";
import styled from "styled-components";
import StudentTable from "../../components/LessonPlan/LessonPlan";
import "./component/style.css";
import StudentInformation from "../../components/ProfileInfo/ProfileInfo";
import ProfileChart from "../../components/ProfileChart/ProfileChart";
import { useLocation, useNavigate } from "react-router-dom";

export default function ProfilePage() {
  const [student_id, setStudentId] = useState<string | null>(null);
  const [chartType, setChartType] = useState<string>("academic_ability");
  const location = useLocation();
  const navigate = useNavigate();
  //   console.log(chartType);

  useEffect(() => {
    const { studentId } = location.state || {};
    // console.log(studentId);
    setStudentId(studentId);
  }, []);

  const changeChartAcademic = () => {
    setChartType("academic_ability");
  };

  const changeChartSkills = () => {
    setChartType("personality_strength");
  };

  const handleNagivateLesson = () => {
    const { studentId } = location.state || {};
    navigate("/handbook", { state: { studentId: studentId } });
  };
  const handleNagivateHome = () => {
    const { studentId } = location.state || {};
    navigate("/student", { state: { studentId: studentId } });
  };

  return (
    <div className="page-container">
      <div className="profile-page">
        <header className="header">
          <div className="header-left">
            <div className="title">
              <strong>Student Profile</strong>
            </div>
          </div>
          <div className="header-right">
            <button className="header-button" onClick={handleNagivateHome}>
              Home
            </button>
            {/* <button className="header-button" onClick={handleNagivateLesson}>
              Lesson
            </button> */}
          </div>
        </header>
        <div className="main-content">
          <div className="profile-area">
            <div className="student-info-area">
              <StudentInformation studentId={student_id} />
              {/* <button className="progress-button">Progress</button> */}
            </div>
            <div className="chart-area">
              <div className="chart-select">
                {/* <button className="chart-button" onClick={changeChartAcademic}>
                  Academic
                </button> */}
                <button className="chart-button" onClick={changeChartSkills}>
                  Personality
                </button>
              </div>
              <div className="chart-info">
                <ProfileChart chartType={chartType} studentId={student_id} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
