import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./dashboard.css";
import Modal from "../../../../components/Modal/Modal";

interface StudentDataResponse {
  [studentId: string]: {
    name: string;
    age: string;
    school: string;
    grade: string;
    subjects: string[];
    lesson_per_week: number;
  };
}

interface StatusDataResponse {
  [studentId: string]: {
    status: string;
    lesson_status: {
      topic: string;
      action: string;
      status: string;
    }[];
    harmful_content: {
      session_id: string;
      harmful: boolean;
    };
    attention_status: string;
    attention_update_ts: string;
    update_ts: string;
  };
}

interface ChatLog {
  [sessionID: string]: SpecificChatLog[];
}

interface SpecificChatLog {
  ai: string;
  human: string;
}

const Dashboard: React.FC = () => {
  const [studentData, setStudentData] = useState<StudentDataResponse>({});
  const [statusData, setStatusData] = useState<StatusDataResponse>({});
  const [onlineStatus, setOnlineStatus] = useState<{
    [studentId: string]: string;
  }>({});
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [chatLog, setChatLog] = useState<ChatLog>({});
  const [specificChatLog, setSpecificChatLog] = useState<SpecificChatLog[]>([]);
  const [harmfulStudentId, setHarmfulStudentId] = useState<string>("");

  useEffect(() => {
    fetchData();
    fetchStatusData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/students_all`
      );
      setStudentData(response.data);
    } catch (error) {
      console.error("Error fetching student data:", error);
    }
  };

  const fetchStatusData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/monitor/all-status`
      );
      const status = response.data.payload;
      setStatusData(status);
      updateOnlineStatus(status);
    } catch (error) {
      console.error("Error fetching status data:", error);
    }
  };

  const fetchChatLog = async (studentId: string, date: string) => {
    try {
      const chatLogResponse = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/monitor/agent-log/${studentId}/${date}`
      );
      setChatLog(chatLogResponse.data.payload);
      setSpecificChatLog(
        chatLogResponse.data.payload[
          statusData[studentId].harmful_content.session_id
        ]
      );
    } catch (error) {
      console.error("Error fetching chat log:", error);
      setChatLog({});
    }
  };

  const updateOnlineStatus = (statusData: StatusDataResponse) => {
    const currentTime = new Date().getTime();
    const newOnlineStatus: { [studentId: string]: string } = {};
    Object.keys(statusData).forEach((studentId) => {
      const classStatus = statusData[studentId].status;
      const updateTs = new Date(statusData[studentId].update_ts).getTime();
      const attentionUpdateTs = new Date(
        statusData[studentId].attention_update_ts
      ).getTime();
      const diffMinutes = (currentTime - updateTs) / (1000 * 60);
      const attentionDiffMinutes =
        (currentTime - attentionUpdateTs) / (1000 * 60);
      newOnlineStatus[studentId] =
        (diffMinutes <= 10 || attentionDiffMinutes <= 10) &&
        classStatus === "tutoring"
          ? "online"
          : "offline";
    });
    setOnlineStatus(newOnlineStatus);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchStatusData();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const handleHarmfulClick = async (student_id: string) => {
    const date = new Date().toISOString().split("T")[0];
    await fetchChatLog(student_id, date);
    setHarmfulStudentId(student_id);
    openModal();
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDismissClick = async (studentId: string, decision: string) => {
    sendHarmfulDecision(studentId, decision);
    await fetchStatusData();
    closeModal();
  };

  const sendHarmfulDecision = async (studentId: string, decision: string) => {
    try {
      const requestBody = {
        student_id: studentId,
        decision: decision,
      };
      // console.log(student_id);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/monitor/harmful-status`,
        requestBody
      );
      //   console.log(response);
    } catch (error) {
      console.error("Error updating english progress:", error);
    }
  };

  return (
    <>
      <div className="infoHeader">
        <strong>Dashboard v2.0.0</strong>
      </div>
      <div>
        <div className="toolBar">
          <div className="refreshBar">
            <button className="refreshButton" onClick={fetchStatusData}>
              Refresh
            </button>
            <div>Last Refreshed: {new Date().toLocaleTimeString()}</div>
          </div>
        </div>
        <div className="dashboard">
          {Object.keys(statusData).map((studentId) => (
            <div
              key={studentId}
              className={`student-card ${
                onlineStatus[studentId] === "online" ? "online" : "offline"
              }`}
            >
              {studentData[studentId] && (
                <>
                  <div className="studentTitle">{studentId}</div>
                  <div>Name: {studentData[studentId].name}</div>
                  <div>Grade: {studentData[studentId].grade}</div>
                  <div>
                    Subjects: {studentData[studentId].subjects.join(", ")}
                  </div>
                </>
              )}
              <div>Status: {statusData[studentId].status}</div>
              {statusData[studentId].status === "tutoring" ? (
                <>
                  <div>
                    <strong>Attention Status:</strong>{" "}
                    {statusData[studentId].attention_status === "normal" ? (
                      <span className="normalText">Normal</span>
                    ) : statusData[studentId].attention_status === "idle_3" ? (
                      <span className="idle3Text">Idle (3 minutes)</span>
                    ) : statusData[studentId].attention_status ===
                      "out_of_focus" ? (
                      <span className="out_of_focusText">Out of tab</span>
                    ) : (
                      <span className="idle10Text">Idle (10 minutes)</span>
                    )}
                  </div>
                  <div>
                    Last Update:{" "}
                    {new Date(
                      statusData[studentId].attention_update_ts
                    ).toLocaleString()}
                  </div>
                  {statusData[studentId].harmful_content.harmful && (
                    <button
                      className="harmfulButton"
                      onClick={() => handleHarmfulClick(studentId)}
                    >
                      ⚠️
                    </button>
                  )}
                </>
              ) : null}
            </div>
          ))}
        </div>
      </div>
      <Modal show={isModalOpen} onClose={closeModal}>
        <>
          <h2>Potential Harmful Chat Log</h2>
          <div className="chatHistory">
            <table className="logTable">
              <thead>
                <tr>
                  <th>Human</th>
                  <th>AI</th>
                </tr>
              </thead>
              <tbody>
                {chatLog &&
                  specificChatLog &&
                  specificChatLog.map((log, index) => (
                    <tr key={index}>
                      <td className="tableCell">{log.human}</td>
                      <td className="tableCellAI">{log.ai}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="buttonBar">
              <button
                className="dismissButton"
                onClick={() => handleDismissClick(harmfulStudentId, "dismiss")}
              >
                Dismiss
              </button>
              <button className="informButton">Inform</button>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};

export default Dashboard;
