import React, { useState, ChangeEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./component/LoginPage.css"; // Import the CSS file for styling
import { FaUser } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";

interface StudentDataResponse {
  [studentId: string]: {
    name: string;
    age: string;
    school: string;
    grade: string;
    subjects: string[];
    lesson_per_week: number;
  };
}

interface LoginResponse {
  status: string;
  have_pre_assess: boolean;
  message: string;
}

const LoginPage: React.FC = () => {
  const [studentData, setStudentData] = useState<StudentDataResponse>({});
  const [userType, setUserType] = useState<string>("student");
  const [studentId, setStudentId] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [warning, setWarning] = useState<string | null>(null);
  const [userPlaceholder, setUserPlaceholder] = useState<string>("Student ID");

  const [loginResponsePayload, setLoginResponsePayload] =
    useState<LoginResponse | null>(null);
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!studentId || !password) {
      setWarning("Please enter both Student ID and Password.");
      return;
    }
    setIsLoading(true);
    setError(null);
    setWarning(null);
    try {
      // Check if student ID and password are correct
      const loginResponse = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/login`,
        {
          student_id: studentId,
          password: password,
          role: userType,
        }
      );

      const loginData = loginResponse.data.payload;
      setLoginResponsePayload(loginData);

      if (
        !loginData.have_pre_assess &&
        loginData.status === "success" &&
        userType === "student"
      ) {
        navigate("/preassessment", { state: { studentId: studentId } });
      } else if (
        loginData.have_pre_assess &&
        loginData.status === "success" &&
        userType === "student"
      ) {
        navigate("/handbook", { state: { studentId: studentId } });
      } else if (loginData.status === "success" && userType === "parent") {
        const temp = studentId.slice(0, -1);
        console.log(temp);
        setStudentId(temp); // Remove the last character
        navigate("/parent", { state: { studentId: temp } });
      } else {
        setError("Invalid student ID or password.");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleStudentIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStudentId(e.target.value);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const studentButton = () => {
    setUserType("student");
    setUserPlaceholder("Student ID");
    console.log(userType);
  };

  const parentButton = () => {
    setUserType("parent");
    setUserPlaceholder("Parent ID");
    console.log(userType);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [studentId, password]);

  return (
    <div className="login-page-container">
      <div className="login-page-form-container">
        <div className="login-page-title-container">
          <span className="login-page-title">AIBO</span>
          {/* <span className="login-page-title-span">Your Study Partner</span> */}
        </div>
        <div className="login-user-types">
          <button
            onClick={studentButton}
            className={`userButton ${userType === "student" ? "on" : "off"}`}
          >
            <strong>Student</strong>
          </button>
          <button
            onClick={parentButton}
            className={`userButton ${userType === "parent" ? "on" : "off"}`}
          >
            <strong>Parent</strong>
          </button>
        </div>
        <div className="login-page-background">
          <div className="login-page-form">
            <div className="login-page-form-group">
              <span className="login-page-form-icon">
                <FaUser />
              </span>
              <input
                className="login-page-form-input"
                type="text"
                id="studentId"
                placeholder={userPlaceholder}
                value={studentId}
                onChange={handleStudentIdChange}
              />
            </div>
            <div className="login-page-form-group">
              <span className="login-page-form-icon">
                <RiLockPasswordFill />
              </span>
              <input
                className="login-page-form-input"
                type="password"
                id="password"
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
            <button
              className="login-page-form-button"
              type="button"
              onClick={handleLogin}
              // disabled={!studentId || !password || isLoading}
            >
              {isLoading ? "Logging in..." : "Login"}
            </button>
            {error && <div className="login-page-error">{error}</div>}
            {warning && <div className="login-page-warning">{warning}</div>}
            {/* <a href="#" className="login-page-forgot-password">Forgot Password</a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
