import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios"; // Make sure to install axios if you haven't already
import { useLocation } from "react-router-dom";
import "./PInfoTab.css";
import { use } from "marked";

const Box = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  color: #ffffff;
`;

interface InfoTabProps {
  studentId?: string | null;
}

interface StudentInfoResponse {
  name: string;
  age: string;
  grade: string;
  school: string;
}

interface WeekInfoResponse {
  current_week: number;
  current_lesson: number;
}

const StudentInformation: React.FC<InfoTabProps> = ({ studentId }) => {
  const [studentInfo, setStudentInfo] = useState<StudentInfoResponse[]>([]);
  const [student_id, setStudentId] = useState<string | null>(studentId || null);
  const [weekInfo, setWeekInfo] = useState<WeekInfoResponse[]>([]);
  const location = useLocation();
  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (!hasFetchedData.current) {
      const { studentId } = location.state || {};
      setStudentId(studentId);
      // setStudentId("1001")
      // console.log("studentId:", studentId);
      fetchData();
      hasFetchedData.current = true;
    }
  }, []);

  const fetchData = async () => {
    // const { studentId } = location.state || {};
    // setStudentId(studentId);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/students/${studentId}`
      );
      setStudentInfo([response.data]);
      // console.log("2");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <>
      <Box className="infoTabContainer">
        <h3 style={{ textAlign: "center" }}>
          <strong>學生資料</strong>
        </h3>
        <div className="studentInfo-parent">
          {studentInfo.map((student, index) => (
            <div key={index} className="studentInfoItem">
              <p>學生學號: {studentId}</p>
              <p>姓名: {student.name}</p>
              <p>年級: {student.grade}</p>
            </div>
          ))}
        </div>
      </Box>
    </>
  );
};

export default StudentInformation;
