import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import {
  RadarChart,
  Radar,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";

interface Skill {
  name: string;
  value: number;
}

interface ProfileChartProps {
  studentId?: string | null;
  chartType: string;
}

interface ChartInfoResponse {
  description: {
    title: string;
    // academic_ability: AcademicAbility;
    personality_strength: PersonalityStrength;
  };
}

interface AcademicAbility {
  grammar: number;
  vocabulary: number;
  essay_writing: number;
  nutrition_knowledge: number;
}

interface PersonalityStrength {
  curiosity: number;
  perspective: number;
  love_of_learning: number;
  creativity: number;
  judgment: number;
  social_intelligence: number;
  teamwork: number;
}

const ProfileChart: React.FC<ProfileChartProps> = ({
  chartType,
  studentId,
}) => {
  const [skillsData, setSkillsData] = useState<Skill[]>([]);
  const location = useLocation();

  useEffect(() => {
    fetchData();
  }, [chartType]); // Dependencies array includes chartType and studentId

  const fetchData = async () => {
    try {
      const { studentId } = location.state || {};
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/students/${studentId}`
      );
      const data: ChartInfoResponse = response.data;
      const extractedSkills = extractSkills(data, chartType);
      setSkillsData(extractedSkills);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const formatSkillName = (skillName: string): string => {
    return skillName
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const extractSkills = (
    data: ChartInfoResponse,
    chartType: string
  ): Skill[] => {
    const skills =
      chartType === "personality_strength"
        ? // ? data.description.academic_ability
          data.description.personality_strength
        : data.description.personality_strength;
    return Object.entries(skills).map(([skillName, value]) => ({
      name: formatSkillName(skillName),
      value,
    }));
  };

  return (
    <div
      style={{
        height: "400px",
        width: "100%",
        fontSize: "20px",
      }}
    >
      {skillsData && skillsData.length > 0 ? (
        <ResponsiveContainer width="100%" height="100%">
          <RadarChart cx="50%" cy="50%" outerRadius="70%" data={skillsData}>
            <PolarGrid />
            <PolarAngleAxis dataKey="name" tick={{ fill: "white" }} />
            <PolarRadiusAxis tick={false} />
            <Radar
              name="Skills"
              dataKey="value"
              stroke="#4A6C16"
              fill="#49E377"
              fillOpacity={0.6}
            />
          </RadarChart>
        </ResponsiveContainer>
      ) : (
        <p>Loading data...</p> // Fallback UI while data is being fetched
      )}
    </div>
  );
};

export default ProfileChart;
