import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./StudyPlan.css";

// Define interfaces
interface Lesson {
  date: string;
  topic: Record<string, string>;
  chapter: string;
  extra: string;
  target: string;
  duration: number;
}

interface ProgressSummaryPayload {
  [chapter: string]: Record<string, Record<string, ProgressTopic>>;
}

interface ProgressTopic {
  Topic: string;
  Description: string;
  level?: string;
  comment?: string;
}

interface ProgressSummaryResponse {
  errorMessage: string;
  payload: ProgressSummaryPayload;
}

interface ApiResponse {
  errorMessage: string;
  payload: Record<string, Lesson>;
}

const StudentTable = () => {
  const [studentIdInput, setStudentIdInput] = useState("");
  const [studentsData, setStudentsData] = useState<ApiResponse[]>([]);
  const [progressSummary, setProgressSummary] = useState<
    ProgressSummaryResponse[]
  >([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/some-path");
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    // Optional: Trigger data fetch when component mounts or 'studentIdInput' changes
    // Make sure to implement logic to prevent infinite loops
  }, [studentIdInput]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/progress-review/study-plan/${studentIdInput}`
      );
      setStudentsData([response.data]);
      console.log(studentsData);
      setShowForm(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/progress-review/progress-summary/${studentIdInput}`
      );
      setProgressSummary([response.data]);
      console.log(progressSummary);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const testData = {
    chapter3: {
      "1": {
        Topic: "Prepositional phrases",
        Description:
          "Prepositional phrases are phrases that begin with a preposition and describe the relationship between a noun and another word in the sentence.",
        level: "",
        comment: "",
      },
      "2": {
        Topic: "'Who'",
        Description:
          "The word 'who' is used to add information to a noun or noun phrase.",
        level: "",
        comment: "",
      },
      "3": {
        Topic: "Hair",
        Description:
          "Hair can be described in terms of its type, such as curly blonde hair, straight black hair, short brown hair, and long straight hair.",
        level: "",
        comment: "",
      },
      "4": {
        Topic: "Face",
        Description:
          "The shape of a person's face can be described, such as a round face or a long face. Facial hair, like a moustache or a beard, can also be mentioned.",
        level: "",
        comment: "",
      },
      "5": {
        Topic: "Accessories",
        Description:
          "Accessories are items that people wear to enhance their appearance, such as glasses, sunglasses, and a bow tie.",
        level: "",
        comment: "",
      },
      "6": {
        Topic: "Clothing Items",
        Description:
          "Clothing items that people wear can be described, such as a suit, a cardigan, mittens, gloves, a vest, or sandals.",
        level: "",
        comment: "",
      },
      "7": {
        Topic: "'With' + body parts/accessories",
        Description:
          "'With' is used followed by body parts or accessories to provide more information about someone's appearance.",
        level: "",
        comment: "",
      },
      "8": {
        Topic: "'In' + clothing items",
        Description:
          "'In' is used followed by clothing items to describe what someone is wearing.",
        level: "",
        comment: "",
      },
      "9": {
        Topic: "Your face",
        Description:
          "Facial recognition technology can be used to identify individuals based on their unique facial features.",
        level: "",
        comment: "",
      },
      "10": {
        Topic: "Your fingerprints",
        Description:
          "Fingerprint scanning is commonly used for identification purposes as every person has a unique set of fingerprints.",
        level: "",
        comment: "",
      },
      "11": {
        Topic: "Your irises",
        Description:
          "Iris scanning technology is used to match individuals based on their unique iris patterns.",
        level: "",
        comment: "",
      },
      "12": {
        Topic: "DNA",
        Description:
          "DNA determines physical characteristics, including appearance, and is unique to each individual.",
        level: "",
        comment: "",
      },
    },
    chapter4: {
      "1": {
        Topic: "Vocabulary",
        Description:
          "Learn different vocabulary related to accessories and objects.",
        level: "",
        comment: "",
      },
      "2": {
        Topic: "Structure Table",
        Description:
          "Learn how to form questions and answers using possessive pronouns.",
        level: "",
        comment: "",
      },
      "3": {
        Topic: "Language Focus",
        Description:
          "Focus on asking and answering questions about ownership using possessive pronouns.",
        level: "",
        comment: "",
      },
      "4": {
        Topic: "Phonics",
        Description:
          "Learn about intonation in Yes/No questions and statements.",
        level: "",
        comment: "",
      },
      "5": {
        Topic: "Values",
        Description: "Understand the value of honesty.",
        level: "",
        comment: "",
      },
      "6": {
        Topic: "Skill Practice",
        Description: "Practice adding interesting details to a story.",
        level: "",
        comment: "",
      },
    },
  };

  const navigateToLessonPlan = (studentId: string, date: string) => {
    navigate("/lessonplan", { state: { studentId, date } });
  };

  return (
    <div>
      {showForm ? (
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Enter Student ID"
            value={studentIdInput}
            onChange={(e) => setStudentIdInput(e.target.value)}
          />
          <button type="submit">Submit</button>
        </form>
      ) : studentsData.length > 0 ? (
        <table className="studyPlanTable">
          <thead>
            <tr>
              <th>Date</th>
              <th>Subject</th>
              <th>Chapter</th>
              <th>Duration</th>
              <th>Lesson Review</th>
            </tr>
          </thead>
          <tbody>
            {studentsData[0].payload &&
              Object.entries(studentsData[0].payload).map(
                ([subject, courseDetails]) =>
                  Object.entries(courseDetails).map(([date, details]) => (
                    <tr key={`${subject}-${date}`}>
                      <td>{date}</td>
                      <td>{subject}</td>
                      <td>{details.chapter}</td>
                      <td>{details.duration} minutes</td>
                      <td>
                        {/* Wrap the button in a div to attach the click handler */}
                        <div
                          onClick={() =>
                            navigateToLessonPlan(studentIdInput, date)
                          }
                          role="button" // For accessibility
                          tabIndex={0} // So the div can receive focus
                          onKeyDown={(e) => {
                            if (e.key === "Enter")
                              navigateToLessonPlan(studentIdInput, date);
                          }} // Handle keyboard navigation
                        >
                          <Link
                            to="/lessonplan"
                            className="lesson-details-link"
                          >
                            <button>Lesson Details</button>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
              )}
          </tbody>
        </table>
      ) : (
        <p>Loading student data...</p>
      )}
      <div>
        {/* ... (Keep your existing form and table code) */}

        {/* Add a button to trigger the popup */}
        {studentsData.length > 0 && (
          <button onClick={togglePopup}>View Progress Summary</button>
        )}

        {/* Conditional rendering of the popup */}
        {showPopup && (
          <div className="popup">
            <h2>Progress Summary</h2>
            <button onClick={togglePopup}>Close</button>
            <ul>
              {progressSummary[0]?.payload && (
                <>
                  {Object.entries(progressSummary[0].payload).map(
                    ([chapter, chapterTopics]) => (
                      <li key={chapter}>
                        <h3>Chapter: {chapter}</h3>
                        {Object.entries(chapterTopics).map(
                          ([topicKey, topics]) => (
                            <div key={topicKey}>
                              <h4>Topic Group: {topicKey}</h4>
                              {Object.entries(topics).map(
                                ([topicNum, topicInfo]) => (
                                  <div key={topicNum}>
                                    <span>Topic: {topicInfo.Topic} </span>
                                    <span>
                                      Description: {topicInfo.Description}{" "}
                                    </span>
                                    <span>
                                      Level: {topicInfo.level || "N/A"}{" "}
                                    </span>
                                    <span>
                                      Comment: {topicInfo.comment || "N/A"}{" "}
                                    </span>
                                  </div>
                                )
                              )}
                            </div>
                          )
                        )}
                      </li>
                    )
                  )}
                </>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentTable;
