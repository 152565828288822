import React, { useState } from "react";
import "./component/style.css";
import Dashboard from "./component/dashboard/dashboard";
import SearchPage from "./component/searchPage/searchPage";
import ManagementPage from "./component/management/management";

const AdminPage: React.FC = () => {
  const [selectedPage, setSelectedPage] = useState<string>("dashboard");

  const renderPage = () => {
    switch (selectedPage) {
      case "dashboard":
        return <Dashboard />;
      case "search":
        return <SearchPage />;
      case "management":
        return <ManagementPage />;
      default:
        return null;
    }
  };

  const renderButton = (page: string, label: string) => (
    <div
      className={`pageButton ${
        selectedPage === page ? "pageButtonSelected" : ""
      }`}
      onClick={() => setSelectedPage(page)}
    >
      {label}
    </div>
  );

  return (
    <div className="adminPanel">
      <div className="panelHeader">
        <span className="titleText">v2.0.0</span>
        <h1 className="panelTitle">
          <span className="titleHeading">A</span>IBO{" "}
          <span className="titleHeading">M</span>anagement{" "}
          <span className="titleHeading">P</span>anel
        </h1>
        <div className="pageButtonGroup">
          {renderButton("dashboard", "Dashboard")}
          {renderButton("search", "Search")}
          {renderButton("management", "Management")}
        </div>
      </div>
      <div className="infoWrapper">{renderPage()}</div>
    </div>
  );
};

export default AdminPage;
