import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "./MainListen.css";
import TextToSpeech from "../Text2speech/text2speech";
import { DotLoader } from "react-spinners";

interface ListenInfoProps {
  topic: string;
  studentId?: string | null;
  sessionID: string;
  highlightIndex: number;
  onSubmitAction: (selectedAnswers: Record<string, string>) => void;
  status: string;
}

interface ListenInfoResponse {
  listening_paragraph: string;
  assessment: Record<
    string,
    { question: string; options: Record<string, string>; answer: string }
  >;
}

interface QuestionOption {
  label: string;
  value: string;
  checked: boolean;
}

interface AssessmentFormState {
  [key: string]: QuestionOption[];
}

const MainListen: React.FC<ListenInfoProps> = ({
  topic,
  studentId,
  sessionID,
  onSubmitAction,
  highlightIndex,
  status,
}) => {
  const location = useLocation();
  const hasFetchedData = useRef(false);
  const [student_id, setStudentId] = useState<string | null>(null);
  const [ListenInfo, setListenInfo] = useState<ListenInfoResponse | null>(null);
  const [answers, setAnswers] = useState<AssessmentFormState>({});
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!hasFetchedData.current && status === "not_started") {
      // console.log("effect")
      const { studentId } = location.state || {};
      setStudentId(studentId);

      fetchLog();
      hasFetchedData.current = true;
      // setReadingInfo(testData)
    }
    if (status !== "not_started") {
      // console.log("effect")
      const { studentId } = location.state || {};
      setStudentId(studentId);

      fetchLog();
      hasFetchedData.current = true;
      // setReadingInfo(testData)
    }
  }, []);

  const fetchLog = async () => {
    const { studentId } = location.state || {};
    setStudentId(studentId);
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based in JavaScript
    const date = String(now.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${date}`;
    // const formattedDate = `${year}-${month}-21`;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/monitor/lesson-log/${studentId}/${formattedDate}`
      );
      const botResponse = response.data.payload;
      console.log("getHistory");

      // Check if the bot response has the same structure as testData
      if (typeof botResponse === "object" && !Array.isArray(botResponse)) {
        Object.values(botResponse).forEach((record: unknown) => {
          // Type guard to ensure the record has the expected properties
          if (
            typeof record === "object" &&
            record !== null &&
            "action" in record &&
            "log" in record &&
            "topic" in record
          ) {
            const learningRecord = record as {
              action: string;
              log: ListenInfoResponse;
              topic: string;
            };

            if (
              learningRecord.action === "listening" &&
              learningRecord.topic === topic
            ) {
              // Display the log for the record
              setListenInfo(learningRecord.log);
            }
          }
        });
      } else {
        console.warn("Unexpected bot response format.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    setLoading(true); // Set loading to true
    // console.log("fetch")
    const { studentId } = location.state || {};
    setStudentId(studentId);
    try {
      const requestBody = {
        student_id: studentId,
        topic: topic,
        session_id: sessionID,
        topic_index: highlightIndex.toString(),
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/english-listening`,
        requestBody
      );
      // console.log(response.data.payload);
      setListenInfo(response.data.payload);
    } catch (error) {
      console.error("Error fetching reading info:", error);
    } finally {
      setLoading(false);
      hasFetchedData.current = true;
    }
  };

  useEffect(() => {
    if (ListenInfo) {
      const initialAnswers: AssessmentFormState = {};
      Object.keys(ListenInfo.assessment).forEach((key) => {
        initialAnswers[key] = Object.entries(
          ListenInfo.assessment[key].options
        ).map(([label, value]) => ({
          label,
          value,
          checked: false,
        }));
      });
      setAnswers(initialAnswers);
    }
  }, [ListenInfo]);

  useEffect(() => {
    if (ListenInfo) {
      const allAnswered = Object.values(answers).every((options) =>
        options.some((opt) => opt.checked)
      );
      setAllQuestionsAnswered(allAnswered);
    }
  }, [answers, ListenInfo]);

  const handleAnswerChange = (questionId: string, optionValue: string) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: prevAnswers[questionId].map((option) =>
        option.value === optionValue
          ? { ...option, checked: true }
          : { ...option, checked: false }
      ),
    }));
  };

  const getSelectedAnswers = () => {
    console.log("submit");
    const selectedAnswers: Record<string, string> = {};
    Object.keys(answers).forEach((questionId) => {
      const selectedOption = answers[questionId].find(
        (option) => option.checked
      );
      if (selectedOption) {
        selectedAnswers[questionId] = selectedOption.label;
      }
    });
    onSubmitAction(selectedAnswers);
  };

  return (
    <>
      {loading ? ( // Conditionally render loading message
        <>
          <h4>Listening</h4>
          <div className="loading-container">
            <p>AIBO忙碌緊...</p>
            <DotLoader color="#21B0ED" />
          </div>
        </>
      ) : (
        <>
          {ListenInfo && (
            <>
              {/* <p className="readingPara">{ListenInfo.listening_paragraph}</p> */}
              <div className="audio-options">
                <h4>Listening</h4>
                <TextToSpeech
                  text={ListenInfo.listening_paragraph}
                  pause={true}
                  stop={true}
                />
              </div>
              <div>
                {Object.entries(ListenInfo.assessment).map(
                  ([questionId, questionData]) => (
                    <div key={questionId}>
                      <p className="readingQ">
                        {questionId}. {questionData.question}
                      </p>
                      <ul>
                        {Object.entries(questionData.options).map(
                          ([label, value]) => {
                            const inputId = `question-${questionId}-option-${value}`;
                            return (
                              <p className="readingA" key={value}>
                                <label
                                  htmlFor={inputId}
                                  style={{ cursor: "pointer" }}
                                >
                                  <input
                                    type="radio"
                                    name={`question-${questionId}`}
                                    id={inputId}
                                    value={label}
                                    checked={
                                      answers[questionId]?.find(
                                        (opt) => opt.value === value
                                      )?.checked || false
                                    }
                                    onChange={() =>
                                      handleAnswerChange(questionId, value)
                                    }
                                    style={{ marginRight: "8px" }} // Optional: add some space between the radio button and the text
                                  />
                                  {label}: {value}
                                </label>
                              </p>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  )
                )}
              </div>
              <button
                className={` ${
                  !allQuestionsAnswered
                    ? "submitButton-disabled"
                    : "submitButton"
                }`}
                disabled={!allQuestionsAnswered}
                onClick={() => getSelectedAnswers()}
              >
                Submit Answers
              </button>
            </>
          )}
        </>
      )}
    </>
  );
};

export default MainListen;
