import React, { useState } from 'react';  
import styled from 'styled-components';  
import StudentTable from '../../components/LessonPlan/LessonPlan';


export default function LessonPlanPage() {
  return (
    <>
    {/* <TeacherFunctions2 /> */}
    <StudentTable />
    
    </>
  )
}