import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import "./component/style.css";

interface ReviewInfoResponse {
  title: string;
  personality_strength: {
    curiosity: number;
    perspective: number;
    love_of_learning: number;
    creativity: number;
    judgement: number;
    social_intelligence: number;
    teamwork: number;
  };
}

const PreAssessment: React.FC = () => {
  const [studentId, setStudentId] = useState<string>("");
  const [answers, setAnswers] = useState<{ [key: string]: string }>({});
  const [reviewInfo, setReviewInfo] = useState<ReviewInfoResponse | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const location = useLocation();
  const navigate = useNavigate();

  const handleChange = (question: string, answer: string) => {
    setAnswers((prev) => ({ ...prev, [question]: answer }));
  };

  useEffect(() => {
    const { studentId } = location.state || {};
    setStudentId(studentId);
  }, [location.state]);

  const handleSubmit = async () => {
    const message = questions
      .map((q) => `${q.question}: ${answers[q.key]}`)
      .join("\n");
    console.log(message);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/progress-review/pre-assessment-review`,
        { grade: "primary5", quiz_answer: message, student_id: studentId }
      );
      console.log(response.data.payload);
      setReviewInfo(response.data.payload);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error submitting the form", error);
      alert("Submission failed. Please try again.");
    }
  };

  const questions = [
    {
      key: "extracurricular",
      title: "課外活動 (Extracurricular Activities)",
      question: "你最喜歡的課外活動是什麼？",
      options: [
        { value: "a", label: "與朋友聊天" },
        { value: "b", label: "探索大自然" },
        { value: "c", label: "看書" },
        { value: "d", label: "玩電子遊戲" },
        { value: "e", label: "做運動" },
      ],
    },
    {
      key: "perspective",
      title: "觀點 (Perspective)",
      question: "當你遇到一個問題時，你會怎麼做？",
      options: [
        { value: "a", label: "從不同角度來看問題" },
        { value: "b", label: "問問爸爸媽媽的意見" },
        { value: "c", label: "和朋友聊聊" },
        { value: "d", label: "不理會問題" },
      ],
    },
    {
      key: "loveOfLearning",
      title: "熱愛學習 (Love of Learning)",
      question: "你在學校最喜歡的科目是什麼？",
      options: [
        { value: "a", label: "科學" },
        { value: "b", label: "數學" },
        { value: "c", label: "語文" },
        { value: "d", label: "美術" },
        { value: "e", label: "體育" },
        { value: "f", label: "沒有特別喜歡的科目" },
      ],
    },
    {
      key: "creativity",
      title: "創造力 (Creativity)",
      question: "在你的空閒時間，你最喜歡：",
      options: [
        { value: "a", label: "畫畫或塗色" },
        { value: "b", label: "寫故事" },
        { value: "c", label: "做手工" },
        { value: "d", label: "看電視或玩電子遊戲" },
      ],
    },
    {
      key: "judgment",
      title: "判斷力 (Judgment)",
      question: "當你需要做一個決定時，你會：",
      options: [
        { value: "a", label: "向家人或朋友請教" },
        { value: "b", label: "列出優缺點" },
        { value: "c", label: "依靠直覺" },
        { value: "d", label: "做一些研究" },
        { value: "e", label: "觀察別人怎麼做" },
        { value: "f", label: "猶豫不決，很難做出決定" },
      ],
    },
    {
      key: "socialIntelligence",
      title: "社交智力 (Social Intelligence)",
      question: "當你和朋友在一起時，你會怎麼做？",
      options: [
        { value: "a", label: "安排有趣的活動" },
        { value: "c", label: "聽朋友講故事" },
        { value: "d", label: "一起玩遊戲" },
        { value: "e", label: "聊有趣的話題" },
        { value: "f", label: "有時候覺得難以融入" },
      ],
    },
    {
      key: "teamwork",
      title: "團隊合作 (Teamwork)",
      question: "當你和同學一起完成一個項目時，你會：",
      options: [
        { value: "a", label: "分擔工作" },
        { value: "b", label: "提出自己的想法" },
        { value: "c", label: "聽取別人的建議" },
        { value: "d", label: "協調大家的意見" },
        { value: "e", label: "有時候會覺得合作很困難" },
      ],
    },
    {
      key: "problemSolving",
      title: "解決問題 (Problem Solving)",
      question: "當你遇到困難時，你會：",
      options: [
        { value: "a", label: "尋求幫助" },
        { value: "b", label: "自己嘗試解決" },
        { value: "c", label: "查找相關資料" },
        { value: "d", label: "放棄並做其他事情" },
      ],
    },
    {
      key: "empathy",
      title: "同理心 (Empathy)",
      question: "當你看到別人難過時，你會：",
      options: [
        { value: "a", label: "安慰他們" },
        { value: "b", label: "問他們發生了什麼" },
        { value: "c", label: "給予建議" },
        { value: "d", label: "保持距離" },
      ],
    },
  ];

  const allQuestionsAnswered = questions.every((q) => answers[q.key]);
  const canSubmit = studentId.trim() !== "" && allQuestionsAnswered;

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      handleSubmit();
    }
  };

  const handleNagivateHome = () => {
    setIsModalOpen(false);
    const { studentId } = location.state || {};
    navigate("/student", { state: { studentId: studentId } });
  };

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div className="page-container">
      <div className="pre-assessment-page">
        <header className="header">
          <div className="header-left">
            <div className="title">
              <strong>Pre-Assessment</strong>
            </div>
          </div>
        </header>
        <div className="main-content">
          <div className="form-container">
            <form>
              <div className="form-content">
                <div className="question-container">
                  <p>
                    <strong>{currentQuestion.question}</strong>
                  </p>
                  <div className="button-row">
                    {currentQuestion.options.map((option) => (
                      <button
                        type="button"
                        key={option.value}
                        onClick={() =>
                          handleChange(currentQuestion.key, option.label)
                        }
                        style={{
                          backgroundColor:
                            answers[currentQuestion.key] === option.label
                              ? "lightblue"
                              : "white",
                        }}
                        className="option-button"
                      >
                        {option.label}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
              {answers[currentQuestion.key] && (
                <button
                  type="button"
                  onClick={handleNextQuestion}
                  className="submit-button-pre"
                >
                  {currentQuestionIndex < questions.length - 1
                    ? "Next"
                    : "Submit"}
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
      {isModalOpen && reviewInfo && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Review Info</h2>
            <p>Title: {reviewInfo.title}</p>
            <p>Curiosity: {reviewInfo.personality_strength.curiosity}</p>
            <p>Perspective: {reviewInfo.personality_strength.perspective}</p>
            <p>
              Love of Learning:{" "}
              {reviewInfo.personality_strength.love_of_learning}
            </p>
            <p>Creativity: {reviewInfo.personality_strength.creativity}</p>
            <p>Judgement: {reviewInfo.personality_strength.judgement}</p>
            <p>
              Social Intelligence:{" "}
              {reviewInfo.personality_strength.social_intelligence}
            </p>
            <p>Teamwork: {reviewInfo.personality_strength.teamwork}</p>
            <button
              onClick={() => handleNagivateHome()}
              className="close-button"
            >
              Confirm
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PreAssessment;
