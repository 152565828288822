import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import Tooltip from "../Tooltip/Tooltip";

interface DictionaryProps {
  word: string;
  selectedWord: string | null;
  setSelectedWord: (word: string | null) => void;
  tooltipVisible: boolean;
  setTooltipVisible: (visible: boolean) => void;
  tooltipPosition: { top: number; left: number };
  setTooltipPosition: (position: { top: number; left: number }) => void;
}

const Word = styled.span`
  margin: 0 2px;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
`;

const Dictionary: React.FC<DictionaryProps> = ({
  word,
  selectedWord,
  setSelectedWord,
  tooltipVisible,
  setTooltipVisible,
  tooltipPosition,
  setTooltipPosition,
}) => {
  const [explanation, setExplanation] = useState<string>("");
  const wordRef = useRef<HTMLSpanElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null); // Add a ref for the tooltip

  const fetchExplanation = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/english-dictionary`,
        { words: word }
      );
      console.log(response.data);
      setExplanation(response.data.payload);
    } catch (error) {
      console.error("Error fetching word explanation:", error);
      setExplanation("Error fetching explanation");
    }
  };

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (wordRef.current) {
      const rect = wordRef.current.getBoundingClientRect();
      setTooltipPosition({ top: rect.top - 30, left: rect.left });
    }
    fetchExplanation();
    setSelectedWord(word);
    setTooltipVisible(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wordRef.current &&
      !wordRef.current.contains(event.target as Node) &&
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target as Node)
    ) {
      setTooltipVisible(false);
      setSelectedWord(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Word ref={wordRef} onClick={handleClick}>
        {word}
      </Word>
      {selectedWord === word && (
        <Tooltip
          ref={tooltipRef} // Pass the ref to the Tooltip component
          word={word}
          explanation={explanation}
          visible={tooltipVisible}
          position={tooltipPosition}
        />
      )}
    </>
  );
};

export default Dictionary;
