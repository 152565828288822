import React, { useState } from 'react';  
import styled from 'styled-components';  
import StudentInformation from '../../components/InfoTab/InfoTab';  
import ChatHistory from '../../components/ChatHistory/ChatHistory';  
import Documents from '../../components/UpDoc/UpDoc';  
import ChatInterface from '../../components/ChatInterface/ChatInterface';  
import { ChatInput } from '../../components/ChatInterface/ChatInterface';  
  
const Container = styled.div`  
  display: flex;  
  height: 100vh;  
`;  
  
const InfoSection = styled.div`  
  width: 30%;  
  border-right: 1px solid #ccc;  
  padding: 20px;  
`;  
  
const ChatSection = styled.div`  
  width: 70%;  
  display: flex;  
  flex-direction: column;  
`;  
  
const TChatPage: React.FC = () => {  
  const [chatHistory, setChatHistory] = useState<string[]>([]);
  const [currentChat, setCurrentChat] = useState<string[]>([]);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const handleSendMessage = (message: string) => {
    setCurrentChat([...currentChat, message]);
    setChatHistory([...chatHistory, message]);
    setCurrentChat(prevChat => [...prevChat, `You: ${message}`]);
  };

  const handleViewHistory = (index: string) => {
    const numericIndex = parseInt(index, 10);
    if (!isNaN(numericIndex)) {
      setCurrentChat([chatHistory[numericIndex]]);
    } else {
      console.error('Invalid index:', index);
    }
  };

  const handleSelectId = (id: string) => {
    setSelectedId(id);
  };

  return (
    <Container>
      <InfoSection>
        <StudentInformation />
        <ChatHistory onSelectId={handleSelectId} />
        <Documents />
      </InfoSection>
      <ChatSection>
        <ChatInterface initialChat={currentChat}/>
      </ChatSection>
    </Container>
  );
};

  
export default TChatPage;  
