import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./management.css";
import { DotLoader } from "react-spinners";
import { upload } from "@testing-library/user-event/dist/upload";

interface TextbookInfo {
  chapter: string;
  publisher: string;
  grade: string;
  subject: string;
}

interface StudentInfo {
  student_id: string;
  name: string;
  age: string;
  school: string;
  grade: string;
  subjects: string[];
  textbook: Record<string, TextbookInfo>;
  available_week: number;
  lesson_duration: number;
  lesson_per_week: number;
}

interface TextbookAllInfo {
  [publisher: string]: Record<string, string[]>;
}

interface NewTextbookInfo {
  [publisher: string]: Record<string, string[]>;
}

const ManagementPage: React.FC = () => {
  const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);
  const [studentInfo, setStudentInfo] = useState<StudentInfo>({
    student_id: "",
    name: "",
    age: "",
    school: "",
    grade: "",
    subjects: [],
    textbook: {},
    available_week: 0,
    lesson_duration: 90,
    lesson_per_week: 0,
  });
  const [textbookAllInfo, setTextbookAllInfo] = useState<TextbookAllInfo>({});
  const [submittedForm, setSubmittedForm] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<
    Record<string, File | null>
  >({});
  const [attachedTextbook, setAttachedTextbook] = useState<
    Record<string, boolean>
  >({});
  const [attachmentText, setAttachmentText] = useState<Record<string, string>>(
    {}
  );
  const [newTextbookInfo, setNewTextbookInfo] = useState<NewTextbookInfo>({});
  const [loading, setLoading] = useState<Record<string, boolean>>({});
  const [uploadReturnMessage, setUploadReturnMessage] = useState<string>("");

  const [studentExist, setStudentExist] = useState<boolean>(false);

  useEffect(() => {
    fetchTextbookInfo();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === "subjects") {
      setStudentInfo((prevStudentInfo) => ({
        ...prevStudentInfo,
        subjects: value.split(","),
      }));
    } else {
      setStudentInfo((prevStudentInfo) => ({
        ...prevStudentInfo,
        [name]: value,
      }));
    }
  };

  const handleTextbookChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    subject: string
  ) => {
    const { name, value } = e.target;
    setStudentInfo((prevStudentInfo) => ({
      ...prevStudentInfo,
      textbook: {
        ...prevStudentInfo.textbook,
        [subject]: {
          ...prevStudentInfo.textbook[subject],
          [name]: value,
          subject: subject, // Automatically set the subject
        },
      },
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // reset student exist status
      setStudentExist(false);
      // Send API request
      console.log("studentInfo", studentInfo);
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/students/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(studentInfo),
        }
      );

      //   save text book info into new text book info
      const newInfo = { ...newTextbookInfo };
      Object.keys(studentInfo.textbook).forEach((subject) => {
        const publisher = studentInfo.textbook[subject].publisher;
        const grade = studentInfo.textbook[subject].grade;
        if (!newInfo[publisher]) {
          newInfo[publisher] = {};
        }
        if (!newInfo[publisher][subject]) {
          newInfo[publisher][subject] = [];
        }
        if (!newInfo[publisher][subject].includes(grade)) {
          newInfo[publisher][subject].push(grade);
        }
      });
      setNewTextbookInfo(newInfo);

      setSubmittedForm(true);
      if (!response.ok) {
        if (response.status === 400) {
          setStudentExist(true);
        }
        throw new Error("Failed to update class status");
      }
    } catch (error) {
      console.error("Error updating class status:", error);
    }
  };

  const handleSubmitFile = (
    publisher: string,
    subject: string,
    grade: string,
    chapter: string
  ) => {
    setLoading((prevLoading) => ({
      ...prevLoading,
      [`${publisher}_${subject}_${grade}`]: true,
    }));

    const selectedFile = selectedFiles[`${publisher}_${subject}_${grade}`];
    if (selectedFile) {
      const updatedFileName = `${publisher}_${subject}_${grade}_chapter${chapter}.pdf`;
      const updatedFile = new File([selectedFile], updatedFileName, {
        type: selectedFile.type,
      });

      const formData = new FormData();
      formData.append("pdf_file", updatedFile);

      UploadTextbook(formData, publisher, subject, grade);
    }
  };

  const UploadTextbook = async (
    formData: FormData,
    publisher: string,
    subject: string,
    grade: string
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/upload-syllabus`,
        formData
      );
      setUploadReturnMessage(response.data.payload);
    } catch (error) {
      console.error("Error uploading textbook", error);
      setLoading((prevLoading) => ({
        ...prevLoading,
        [`${publisher}_${subject}_${grade}`]: false,
      }));
    } finally {
      setLoading((prevLoading) => ({
        ...prevLoading,
        [`${publisher}_${subject}_${grade}`]: false,
      }));
    }
  };

  const fetchTextbookInfo = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/textbook_all/`
      );
      setTextbookAllInfo(response.data.payload);
    } catch (error) {
      console.error("Error fetching textbook info:", error);
    }
  };

  const triggerFileSelect = (
    publisher: string,
    subject: string,
    grade: string
  ) => {
    document
      .getElementById(`file-input-${publisher}-${subject}-${grade}`)
      ?.click(); // Trigger file input click
  };

  const handleFileSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    publisher: string,
    subject: string,
    grade: string
  ) => {
    setAttachedTextbook((prevAttachedTextbook) => ({
      ...prevAttachedTextbook,
      [`${publisher}_${subject}_${grade}`]: false,
    }));
    setSelectedFiles((prevSelectedFiles) => ({
      ...prevSelectedFiles,
      [`${publisher}_${subject}_${grade}`]: null,
    }));
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      setSelectedFiles((prevSelectedFiles) => ({
        ...prevSelectedFiles,
        [`${publisher}_${subject}_${grade}`]: selectedFile,
      }));
      setAttachedTextbook((prevAttachedTextbook) => ({
        ...prevAttachedTextbook,
        [`${publisher}_${subject}_${grade}`]: true,
      }));
    }
  };

  useEffect(() => {
    Object.keys(selectedFiles).forEach((key) => {
      if (selectedFiles[key]) {
        setAttachmentText((prevAttachmentText) => ({
          ...prevAttachmentText,
          [key]: selectedFiles[key]?.name || "Upload Textbook",
        }));
      } else {
        setAttachmentText((prevAttachmentText) => ({
          ...prevAttachmentText,
          [key]: "Upload Textbook",
        }));
      }
    });
  }, [selectedFiles]);

  const handleInitStudentButton = () => {
    try {
      initStudentData();
    } catch (error) {
      console.error("Error initializing student form", error);
    }
  };

  const initStudentData = async () => {
    try {
      const requestBody = {
        student_id: studentInfo.student_id,
      };
      // console.log(student_id);
      const response = axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/init-student-progress`,
        requestBody
      );
      console.log(response);
    } catch (error) {
      console.error("Error updating english progress:", error);
    }
  };

  return (
    <>
      <div className="infoHeader">
        <strong>Management</strong>
      </div>
      <div>
        <form className="studentForm" onSubmit={handleSubmit}>
          <label htmlFor="student_id">Student ID:</label>
          <input
            type="text"
            id="student_id"
            name="student_id"
            value={studentInfo.student_id}
            onChange={handleChange}
          />
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={studentInfo.name}
            onChange={handleChange}
          />
          <label htmlFor="age">Age:</label>
          <input
            type="text"
            id="age"
            name="age"
            value={studentInfo.age}
            onChange={handleChange}
          />
          <label htmlFor="school">School:</label>
          <input
            type="text"
            id="school"
            name="school"
            value={studentInfo.school}
            onChange={handleChange}
          />
          <label htmlFor="grade">Grade:</label>
          <input
            type="text"
            id="grade"
            name="grade"
            value={studentInfo.grade}
            onChange={handleChange}
          />
          <label htmlFor="available_week">Available weeks:</label>
          <input
            type="text"
            id="available_week"
            name="available_week"
            value={studentInfo.available_week}
            onChange={handleChange}
          />
          <label htmlFor="lesson_per_week">Lessons per week:</label>
          <input
            type="number"
            id="lesson_per_week"
            name="lesson_per_week"
            value={studentInfo.lesson_per_week}
            onChange={handleChange}
          />
          <label htmlFor="subjects">
            Subjects: (Selected {selectedSubjects.join(", ")})
          </label>
          <select
            id="subjects"
            name="subjects"
            multiple
            onChange={(e) => {
              const selectedOptions = Array.from(
                e.target.selectedOptions,
                (option) => option.value
              );
              setSelectedSubjects(selectedOptions);
              setStudentInfo((prevStudentInfo) => ({
                ...prevStudentInfo,
                subjects: selectedOptions,
                textbook: selectedOptions.reduce((acc, subject) => {
                  acc[subject] = {
                    ...prevStudentInfo.textbook[subject],
                    subject: subject,
                  };
                  return acc;
                }, {} as Record<string, TextbookInfo>),
              }));
            }}
          >
            <option value="english">English</option>
            <option value="math">Math</option>
            <option value="chinese">Chinese</option>
            <option value="general_studies">General Studies</option>
          </select>
          {selectedSubjects.map((subject) => (
            <div key={subject}>
              <h4>{subject} Textbook Details</h4>
              <label htmlFor={`${subject}_publisher`}>Publisher:</label>
              <input
                type="text"
                id={`${subject}_publisher`}
                name="publisher"
                value={studentInfo.textbook[subject]?.publisher || ""}
                onChange={(e) => handleTextbookChange(e, subject)}
              />
              <label htmlFor={`${subject}_grade`}>Grade:</label>
              <input
                type="text"
                id={`${subject}_grade`}
                name="grade"
                value={studentInfo.textbook[subject]?.grade || ""}
                onChange={(e) => handleTextbookChange(e, subject)}
              />
            </div>
          ))}
          <button type="submit">Submit</button>
          {studentExist ? <p>Student already exist!</p> : <></>}
        </form>

        {submittedForm && !studentExist ? (
          <div>
            <h3>Upload New Textbook</h3>
            <h3>
              Remember the file name have to be
              *publisher*_*subject*_*grade*_chapter*X*
            </h3>
            {Object.keys(newTextbookInfo).map((publisher) =>
              Object.keys(newTextbookInfo[publisher]).map((subject) => (
                <div
                  key={`${publisher}_${subject}_${newTextbookInfo[publisher][subject][0]}`}
                >
                  <h4>{`${publisher} - ${subject} - ${newTextbookInfo[publisher][subject][0]}`}</h4>
                  <button
                    className="attachment-button-textbook"
                    onClick={() =>
                      triggerFileSelect(
                        publisher,
                        subject,
                        newTextbookInfo[publisher][subject][0]
                      )
                    }
                  >
                    📤
                    {attachmentText[
                      `${publisher}_${subject}_${newTextbookInfo[publisher][subject][0]}`
                    ] || "Upload Textbook"}
                  </button>
                  <input
                    id={`file-input-${publisher}-${subject}-${newTextbookInfo[publisher][subject][0]}`}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) =>
                      handleFileSelect(
                        e,
                        publisher,
                        subject,
                        newTextbookInfo[publisher][subject][0]
                      )
                    }
                  />
                  <label htmlFor={`${subject}_chapter`}>Chapter:</label>
                  <input
                    type="text"
                    id={`${subject}_chapter`}
                    name="chapter"
                    value={studentInfo.textbook[subject]?.chapter || ""}
                    onChange={(e) => handleTextbookChange(e, subject)}
                  />
                  {attachedTextbook[
                    `${publisher}_${subject}_${newTextbookInfo[publisher][subject][0]}`
                  ] && uploadReturnMessage !== "Done" ? (
                    loading[
                      `${publisher}_${subject}_${newTextbookInfo[publisher][subject][0]}`
                    ] ? (
                      // Show loading button
                      <button
                        className="submit-button-textbook"
                        style={{ backgroundColor: "green" }}
                      >
                        Loading
                        <DotLoader />
                      </button>
                    ) : attachedTextbook[
                        `${publisher}_${subject}_${newTextbookInfo[publisher][subject][0]}`
                      ] && uploadReturnMessage === "Exist" ? (
                      <>Textbook already exist!</>
                    ) : (
                      // Show submit button
                      <button
                        className="submit-button-textbook"
                        onClick={() =>
                          handleSubmitFile(
                            publisher,
                            subject,
                            newTextbookInfo[publisher][subject][0],
                            studentInfo.textbook[subject]?.chapter || ""
                          )
                        }
                        style={{ backgroundColor: "green" }}
                      >
                        Submit
                      </button>
                    )
                  ) : (
                    // Show disabled button
                    <button
                      className="submit-button-textbook"
                      style={{
                        backgroundColor: "grey",
                        color: "rgba(255, 255, 255, 0.7)",
                      }}
                    >
                      Submit
                    </button>
                  )}
                  {uploadReturnMessage !== "" ? (
                    <>
                      <button onClick={handleInitStudentButton}>
                        Initialize
                      </button>
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
              ))
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default ManagementPage;
