// src/components/HomePage.tsx
import React from "react";
import { Link } from "react-router-dom";
import "./component/style.css";
const HomePage: React.FC = () => {
  return (
    <div className="home-page">
      <h1>Welcome to Our Educational Platform</h1>
      <div className="grid-container buttons-container">
        <div className="section student-section">
          <h2>Student Section</h2>
          <Link to="/student" className="button">
            Student Page
          </Link>
          <Link to="/practice" className="button">
            Practice Page
          </Link>
          <Link to="/assess" className="button">
            Assess Page
          </Link>
          <Link to="/studyplan" className="button">
            Study Plan Page
          </Link>
          <Link to="/schat" className="button">
            SChat Page
          </Link>
          <Link to="/pronounce" className="button">
            Pronounce Page
          </Link>
        </div>
        <div className="section teacher-section">
          <h2>Teacher Section</h2>
          <Link to="/teacher" className="button">
            Teacher Page
          </Link>
          <Link to="/tchat" className="button">
            TChat Page
          </Link>
          <Link to="/lessonplan" className="button">
            Lesson Plan Page
          </Link>
          <Link to="/noteschat" className="button">
            Notes Chat Page
          </Link>
          <Link to="/mathchat" className="button">
            Math Chat Page
          </Link>
        </div>
        <div className="section parent-section">
          <h2>Parent Section</h2>
          <Link to="/parent" className="button">
            Parent Page
          </Link>
        </div>
      </div>
      <div className="login-section">
        <Link to="/login" className="button">
          Login
        </Link>
      </div>
      <div className="admin-section">
        <Link to="/admin" className="button">
          Admin Page
        </Link>
      </div>
      <div className="preassessment-section">
        <Link to="/preassessment" className="button">
          Pre-Assessment Page
        </Link>
      </div>
    </div>
  );
};

export default HomePage;
