import React from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import "./component/style.css";
import ChatInterface from "../../components/UploadHandbook/UploadHandbook";
import UploadForm from "../../components/UploadForm/UploadForm";
import { DotLoader } from "react-spinners";
import { colors } from "@mui/material";
import test from "node:test";

interface Question {
  question: string;
}

interface Questions {
  [key: string]: Question;
}

interface HandbookQResponse {
  homework: string[];
}

interface HomeworkItem {
  name: string;
  subject: string;
  due_date: string;
}

interface UploadFormProps {
  testData: HomeworkItem[];
}

const HandbookCheckPage = () => {
  const [currentChat, setCurrentChat] = useState<string[]>([]);
  const [uploadedHandbook, setUploadedHandbook] = useState<Boolean>(false);
  const [attachedHandbook, setAttachedHandbook] = useState<Boolean>(false);
  const [attachmentText, setAttachmentText] = useState<string>("Attach");
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // New state for selected file
  const [handbookQ, setHandbookQ] = useState<HandbookQResponse>({
    homework: [],
  });
  const [homeworkInfo, setHomeworkInfo] = useState<HomeworkItem>();
  const [loading, setLoading] = useState(false);
  const [studentId, setStudentId] = useState<string>("");
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    const { studentId } = location.state || {};
    setStudentId(studentId);
  }, [location.state]);

  const triggerFileSelect = () => {
    document.getElementById("file-input")?.click(); // Trigger file input click
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const { studentId } = location.state || {};
      setSelectedFile(event.target.files[0]);
      setAttachedHandbook(true);
      // Here you could also implement logic to handle the selected file (e.g., upload it)
    }
  };

  const UploadHandbook = async (formData: FormData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/progress-review/handbook`,
        formData
      );
      console.log(response.data.payload);
      setHandbookQ(response.data.payload);
    } catch (error) {
      console.error("Error updating english progress:", error);
    } finally {
      setLoading(false);
      setUploadedHandbook(true);
    }
  };

  const handleSubmitFile = () => {
    setLoading(true);
    if (selectedFile) {
      const { studentId } = location.state || {};
      //   console.log(studentId);
      const formData = new FormData();
      formData.append("image_files", selectedFile);
      formData.append("student_id", studentId);
      UploadHandbook(formData);
    }
  };

  const updateClassStatus = async (studentId: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/monitor/class-status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ student_id: studentId, status: "tutoring" }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to updating class status");
      }
    } catch (error) {
      console.error("Error updating class status:", error);
    }
  };

  const handleNoHomework = () => {
    const { studentId } = location.state || {};

    updateClassStatus(studentId);
    triggerHomeworkRemoval(studentId);
    navigate(`/main`, { state: { studentId } });
  };

  useEffect(() => {
    if (selectedFile) {
      setAttachmentText(selectedFile.name);
    } else {
      setAttachmentText("Upload Handbook");
    }
  }, [selectedFile]);

  const handleNagivateProfile = () => {
    const { studentId } = location.state || {};
    navigate("/profile", { state: { studentId: studentId } });
  };
  const handleNagivateHome = () => {
    const { studentId } = location.state || {};
    navigate("/student", { state: { studentId: studentId } });
  };

  const triggerHomeworkRemoval = async (studentId: string) => {
    try {
      // Send API request
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/remove-outdated-homework`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ student_id: studentId }),
        }
      );
      // console.log("Triggered homework removal");
    } catch (error) {
      console.error("Error submitting homework:", error);
    }
  };

  return (
    <div className="page-container">
      <div className="handbook-page">
        <header className="header">
          <div className="header-left">
            <div className="title">
              <strong>Student Handbook</strong>
            </div>
          </div>
          <div className="header-right">
            <button className="header-button" onClick={handleNagivateHome}>
              Home
            </button>
            <button className="header-button" onClick={handleNagivateProfile}>
              Profile
            </button>
          </div>
        </header>
        <div className="main-content">
          <div className="handbook-area">
            <div className="chatbot-area">
              {uploadedHandbook === false ? (
                <div className="chatbot-content">
                  <p className="handbook-studentID">
                    <strong>SID:</strong> {studentId}
                  </p>
                  <div className="buttons-area">
                    <button
                      className="attachment-button-handbook"
                      onClick={triggerFileSelect}
                    >
                      📤{attachmentText}
                    </button>
                    <input
                      id="file-input"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileSelect}
                    />
                    {attachedHandbook === true ? (
                      loading === true ? (
                        // Show loading button
                        <button className="submit-button-handbook">
                          <div className="loading-button">
                            Loading <DotLoader />
                          </div>
                        </button>
                      ) : (
                        // Show submit button
                        <button
                          className="submit-button-handbook"
                          onClick={handleSubmitFile}
                        >
                          Submit
                        </button>
                      )
                    ) : (
                      <button
                        className="submit-button-handbook"
                        style={{
                          backgroundColor: "grey",
                          color: "rgba(255, 255, 255, 0.7)",
                        }}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                  <div
                    className="noHomeworkText"
                    onClick={() => {
                      handleNoHomework();
                    }}
                  >
                    I have no homework today.
                  </div>
                </div>
              ) : (
                <UploadForm handbook={handbookQ} selectedFile={selectedFile} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HandbookCheckPage;
