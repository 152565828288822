import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios"; // Make sure to install axios if you haven't already
import { useLocation } from "react-router-dom";
import "./ProfileInfo.css";
import { use } from "marked";

const Box = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
`;

interface InfoTabProps {
  studentId?: string | null;
}

interface StudentInfoResponse {
  name: string;
  age: string;
  grade: string;
  school: string;
}

interface WeekInfoResponse {
  current_week: number;
  current_lesson: number;
}

const StudentInformation: React.FC<InfoTabProps> = ({ studentId }) => {
  const [studentInfo, setStudentInfo] = useState<StudentInfoResponse[]>([]);
  const [student_id, setStudentId] = useState<string | null>(studentId || null);
  const [weekInfo, setWeekInfo] = useState<WeekInfoResponse[]>([]);
  const location = useLocation();
  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (!hasFetchedData.current) {
      const { studentId } = location.state || {};
      setStudentId(studentId);
      // setStudentId("1001")
      // console.log("studentId:", studentId);
      fetchData();
      hasFetchedData.current = true;
    }
  }, []);

  const fetchData = async () => {
    const { studentId } = location.state || {};
    setStudentId(studentId);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/students/${studentId}`
      );
      setStudentInfo([response.data]);
      // console.log("2");
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    try {
      const response1 = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/english-lesson-progress/${studentId}`
      );
      setWeekInfo([response1.data.payload]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <>
      <Box className="infoTabContainer">
        <h3 className="title">Student Info</h3>
        <div className="ProfileInfo">
          {studentInfo.map((student, index) => (
            <div key={index} className="studentInfoItem">
              <p>Student ID: {student_id}</p>
              <p>Name: {student.name}</p>
              <p>Age: {student.age}</p>
              <p>Grade: {student.grade}</p>
            </div>
          ))}
          {weekInfo.map((info, index) => (
            <div key={index} className="studentInfoItem">
              <p>Week: {info.current_week}</p>
              <p>Lesson: {info.current_lesson}</p>
            </div>
          ))}
        </div>
      </Box>
    </>
  );
};

export default StudentInformation;
