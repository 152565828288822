import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { marked } from "marked";
import "./MainHomework.css";

interface HomeworkInfoProps {
  homeworkResponse: string;
}

const MainHomework: React.FC<HomeworkInfoProps> = ({ homeworkResponse }) => {
  const [preprocessedHomeworkResponse, setPreprocessedHomeworkResponse] =
    useState<string>("");
  const [processedResponse, setProcessedResponse] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    // console.log(processedResponse);
    const preprocessHomeworkResponse = async () => {
      if (typeof homeworkResponse === "string" && homeworkResponse.length > 0) {
        setPreprocessedHomeworkResponse(await marked(homeworkResponse));
        setProcessedResponse(true);
      }
    };

    preprocessHomeworkResponse();
  }, [homeworkResponse]);

  return (
    <>
      {processedResponse ? (
        <div
          className="homeworkResponse"
          dangerouslySetInnerHTML={{ __html: preprocessedHomeworkResponse }}
        />
      ) : (
        "在左側上傳作業圖片即可開始！"
      )}
    </>
  );
};

export default MainHomework;
