import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./UploadForm.css";

interface HomeworkItem {
  name: string;
  subject: string;
  due_date: string;
  seek_assistance: boolean;
  homework_index: string;
  complete: boolean;
}

interface UploadFormProps {
  handbook: HandbookItems;
  selectedFile: File | null;
}

interface InitialItem {
  name: string;
}

interface HandbookItems {
  homework: string[];
}

const UploadForm: React.FC<UploadFormProps> = ({ handbook, selectedFile }) => {
  const [homeworkItems, setHomeworkItems] = useState<
    Record<string, HomeworkItem>
  >({});
  const [errors, setErrors] = useState<string[]>([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Initialize homework items with default values
    let initialItems: Record<string, HomeworkItem> = {};
    handbook.homework.forEach((item, index) => {
      initialItems[item] = {
        name: item,
        subject: "",
        due_date: "",
        seek_assistance: false,
        complete: false,
        homework_index: index.toString(),
      };
    });
    setHomeworkItems(initialItems);
  }, [handbook]);

  const handleSubjectChange = (
    name: string,
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const updatedItems = { ...homeworkItems };
    updatedItems[name].subject = e.target.value;
    setHomeworkItems(updatedItems);
  };

  const handledue_dateChange = (
    name: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedItems = { ...homeworkItems };
    updatedItems[name].due_date = e.target.value;
    setHomeworkItems(updatedItems);
  };

  const handleRemoveItem = (name: string) => {
    const updatedItems = { ...homeworkItems };
    delete updatedItems[name];
    setHomeworkItems(updatedItems);
  };

  const handleAddItem = () => {
    const newItemName = `new_item_${Object.keys(homeworkItems).length + 1}`;
    const updatedItems = {
      ...homeworkItems,
      [newItemName]: {
        name: newItemName,
        subject: "",
        due_date: "",
        seek_assistance: false,
        complete: false,
        homework_index: Object.keys(homeworkItems).length.toString(),
      },
    };
    setHomeworkItems(updatedItems);
  };

  const validateItems = () => {
    const newErrors: string[] = [];
    Object.entries(homeworkItems).forEach(([name, item]) => {
      if (!item.name) {
        newErrors.push(`作業 ${name} 缺少名稱`);
      }
      if (!item.subject) {
        newErrors.push(`作業 ${name} 缺少科目`);
      }
      if (!item.due_date) {
        newErrors.push(`作業 ${name} 缺少日期`);
      }
    });
    return newErrors;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validationErrors = validateItems();
    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }
    const { studentId } = location.state || {};
    const data = Object.values(homeworkItems);
    try {
      // Send API request
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/update-homework`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ student_id: studentId, homework_data: data }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to submit homework");
      }
      // console.log("Homework submitted successfully");

      triggerHomeworkRemoval(studentId);

      // Update class status
      updateClassStatus(studentId);
      // Optionally, navigate to main page
      navigate(`/main`, { state: { studentId } });
    } catch (error) {
      console.error("Error submitting homework:", error);
    }
  };

  const triggerHomeworkRemoval = async (studentId: string) => {
    try {
      // Send API request
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/remove-outdated-homework`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ student_id: studentId }),
        }
      );
      // console.log("Triggered homework removal");
    } catch (error) {
      console.error("Error submitting homework:", error);
    }
  };

  const updateClassStatus = async (studentId: string) => {
    try {
      // Send API request
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/monitor/class-status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ student_id: studentId, status: "tutoring" }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to updating class status");
      }
    } catch (error) {
      console.error("Error updating class status:", error);
    }
  };

  return (
    <>
      <form className="formContainer" onSubmit={handleSubmit}>
        <div className="formWrapper">
          {/* {selectedFile && (
            <div className="handbookImageWrapper">
              <img
                className="handbookImage"
                src={URL.createObjectURL(selectedFile)}
                alt="handbook"
              />
            </div>
          )} */}
          {/* <div className="dataRow">
            <p>Name</p>
          </div> */}
          <div className="dataRow">
            <div className="dataRowTitle">
              <p className="dataTitle">
                <strong>Name</strong>
              </p>
              <p className="dataTitle">
                <strong>Subject</strong>
              </p>
              <p className="dataTitle">
                <strong>Due Date</strong>
              </p>
            </div>
            {Object.entries(homeworkItems).map(([name, item]) => (
              <div key={name} className="dataRowItem">
                <input
                  className="name-input"
                  type="text"
                  value={item.name}
                  onChange={(e) => {
                    const updatedItems = { ...homeworkItems };
                    updatedItems[name].name = e.target.value;
                    setHomeworkItems(updatedItems);
                  }}
                />
                <select
                  className="dropdown-menu-prac"
                  value={item.subject}
                  onChange={(e) => handleSubjectChange(name, e)}
                >
                  <option value="" className="">
                    選擇科目
                  </option>
                  <option value="english">英文</option>
                  <option value="chinese">中文</option>
                  <option value="math">數學</option>
                  <option value="general_studies">常識</option>
                  <option value="others">其他</option>
                </select>
                {/* <input
                  type="text"
                  placeholder="Due Date"
                  onChange={(e) => console.log(e.target.value)}
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                /> */}
                <input
                  className="date-input"
                  aria-label="Date"
                  type="date"
                  value={item.due_date}
                  onChange={(e) => handledue_dateChange(name, e)}
                />
                <button
                  type="button"
                  className="remove-button"
                  onClick={() => handleRemoveItem(name)}
                >
                  -
                </button>
              </div>
            ))}
            <button
              type="button"
              className="add-button"
              onClick={handleAddItem}
            >
              +
            </button>
          </div>
          <div className="submit-wrapper">
            <button type="submit" className="submit-button">
              遞交
            </button>
            {errors.length > 0 && (
              <div className="error-messages">
                <p className="error-text">You are missing something!</p>
              </div>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default UploadForm;
