import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "./MainRead.css";
import { DotLoader } from "react-spinners";
import Dictionary from "../Dictionary/Dictionary"; // Import the Dictionary component

interface ReadInfoProps {
  topic: string;
  studentId?: string | null;
  sessionID: string;
  highlightIndex: number;
  onSubmitAction: (selectedAnswers: Record<string, string>) => void;
  status: string;
}

interface ReadInfoResponse {
  reading_paragraph: string;
  assessment: Record<
    string,
    { question: string; options: Record<string, string>; answer: string }
  >;
  short_answer_question: Record<string, { question: string; answer: string }>;
}

interface QuestionOption {
  label: string;
  value: string;
  checked: boolean;
}

interface AssessmentFormState {
  [key: string]: QuestionOption[];
}

interface ShortAnswerFormState {
  [key: string]: string;
}

const ParagraphContainer = styled.div`
  position: relative; // Ensure the container is positioned relatively
`;

const MainRead: React.FC<ReadInfoProps> = ({
  topic,
  studentId,
  sessionID,
  onSubmitAction,
  highlightIndex,
  status,
}) => {
  const location = useLocation();
  const hasFetchedData = useRef(false);
  const [student_id, setStudentId] = useState<string | null>(null);
  const [readingInfo, setReadingInfo] = useState<ReadInfoResponse | null>(null);
  const [answers, setAnswers] = useState<AssessmentFormState>({});
  const [shortAnswers, setShortAnswers] = useState<ShortAnswerFormState>({});
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
  const [loading, setLoading] = useState(false);
  const minFontSize = 22;
  const maxFontSize = 28;
  const [fontSize, setFontSize] = useState(24);
  // State for managing tooltip visibility and selected word
  const [selectedWord, setSelectedWord] = useState<string | null>(null);
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [tooltipPosition, setTooltipPosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });

  useEffect(() => {
    if (!hasFetchedData.current && status === "not_started") {
      // console.log("effect")
      const { studentId } = location.state || {};
      setStudentId(studentId);
      fetchLog();
      hasFetchedData.current = true;
    }
    if (status !== "not_started") {
      // console.log("effect")
      const { studentId } = location.state || {};
      setStudentId(studentId);
      fetchLog();
      hasFetchedData.current = true;
    }
  }, []);

  const fetchLog = async () => {
    const { studentId } = location.state || {};
    setStudentId(studentId);
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based in JavaScript
    const date = String(now.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${date}`;
    // const formattedDate = `${year}-${month}-21`;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/monitor/lesson-log/${studentId}/${formattedDate}`
      );
      const botResponse = response.data.payload;
      console.log("getHistory");
      // Check if the bot response has the same structure as testData
      if (typeof botResponse === "object" && !Array.isArray(botResponse)) {
        Object.values(botResponse).forEach((record: unknown) => {
          // Type guard to ensure the record has the expected properties
          if (
            typeof record === "object" &&
            record !== null &&
            "action" in record &&
            "log" in record &&
            "topic" in record
          ) {
            const learningRecord = record as {
              action: string;
              log: ReadInfoResponse;
              topic: string;
            };
            if (
              learningRecord.action === "reading" &&
              learningRecord.topic === topic
            ) {
              // Display the log for the record
              setReadingInfo(learningRecord.log);
            }
          }
        });
      } else {
        console.warn("Unexpected bot response format.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const fetchData = async () => {
  //   setLoading(true);
  //   const { studentId } = location.state || {};
  //   setStudentId(studentId);
  //   try {
  //     const requestBody = {
  //       student_id: studentId,
  //       topic: topic,
  //       session_id: sessionID,
  //       topic_index: highlightIndex.toString(),
  //     };
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_ENDPOINT}/api/tools/english-reading`,
  //       requestBody
  //     );
  //     setReadingInfo(response.data.payload);
  //   } catch (error) {
  //     console.error("Error fetching reading info:", error);
  //   } finally {
  //     setLoading(false);
  //     hasFetchedData.current = true;
  //   }
  // };

  useEffect(() => {
    if (readingInfo) {
      const initialAnswers: AssessmentFormState = {};
      Object.keys(readingInfo.assessment).forEach((key) => {
        initialAnswers[key] = Object.entries(
          readingInfo.assessment[key].options
        ).map(([label, value]) => ({
          label,
          value,
          checked: false,
        }));
      });
      setAnswers(initialAnswers);

      const initialShortAnswers: ShortAnswerFormState = {};
      Object.keys(readingInfo.short_answer_question).forEach((key) => {
        initialShortAnswers[key] = "";
      });
      setShortAnswers(initialShortAnswers);
    }
  }, [readingInfo]);

  useEffect(() => {
    if (readingInfo) {
      const allMCAnswered = Object.values(answers).every((options) =>
        options.some((opt) => opt.checked)
      );
      const allShortAnswered = Object.values(shortAnswers).every(
        (answer) => answer.trim() !== ""
      );
      setAllQuestionsAnswered(allMCAnswered && allShortAnswered);
    }
  }, [answers, shortAnswers, readingInfo]);

  const handleAnswerChange = (questionId: string, optionValue: string) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: prevAnswers[questionId].map((option) =>
        option.value === optionValue
          ? { ...option, checked: true }
          : { ...option, checked: false }
      ),
    }));
  };

  const handleShortAnswerChange = (questionId: string, value: string) => {
    setShortAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: value,
    }));
  };

  const getSelectedAnswers = () => {
    const selectedAnswers: Record<string, string> = {};
    const mcQuestionCount = Object.keys(answers).length;

    // Add multiple-choice answers to selectedAnswers
    Object.keys(answers).forEach((questionId) => {
      const selectedOption = answers[questionId].find(
        (option) => option.checked
      );
      if (selectedOption) {
        selectedAnswers[questionId] = selectedOption.label;
      }
    });

    // Add short answer questions to selectedAnswers with continued numbering
    Object.keys(shortAnswers).forEach((questionId, index) => {
      const newQuestionId = (mcQuestionCount + index + 1).toString();
      selectedAnswers[newQuestionId] = shortAnswers[questionId];
    });

    onSubmitAction(selectedAnswers);
  };

  const renderParagraphWithDictionary = (paragraph: string) => {
    return paragraph.split(" ").map((word, index) => (
      <>
        <Dictionary
          key={index}
          word={word}
          selectedWord={selectedWord}
          setSelectedWord={setSelectedWord}
          tooltipVisible={tooltipVisible}
          setTooltipVisible={setTooltipVisible}
          tooltipPosition={tooltipPosition}
          setTooltipPosition={setTooltipPosition}
        />{" "}
      </>
    ));
  };

  const enlargeText = () => {
    setFontSize(fontSize + 2);
    if (fontSize > maxFontSize) {
      setFontSize(maxFontSize);
    }
  };

  const shrinkText = () => {
    setFontSize(fontSize - 2);
    if (fontSize < minFontSize) {
      setFontSize(minFontSize);
    }
  };

  return (
    <>
      {loading ? (
        <>
          <h4>Reading</h4>
          <div className="loading-container">
            <p>AIBO忙碌緊...</p>
            <DotLoader color="#21B0ED" />
          </div>
        </>
      ) : (
        <>
          {readingInfo && (
            <>
              <div className="ReadingPart">
                <h4>Reading</h4>
                <div className="sizingButtons">
                  <p style={{ fontSize: "20px" }}>Font Size:</p>
                  <button className="sizeButton" onClick={() => enlargeText()}>
                    +
                  </button>
                  <button className="sizeButton" onClick={() => shrinkText()}>
                    -
                  </button>
                </div>
                <ParagraphContainer className="paragraph-container">
                  <p className="readingPara-ex" style={{ fontSize: fontSize }}>
                    {renderParagraphWithDictionary(
                      readingInfo.reading_paragraph
                    )}
                    {/* {readingInfo.reading_paragraph} */}
                  </p>
                </ParagraphContainer>
              </div>
              <div className="AnswerPart">
                {Object.entries(readingInfo.assessment).map(
                  ([questionId, questionData], index) => (
                    <div key={questionId}>
                      <p className="readingQ">
                        {index + 1}. {questionData.question}
                      </p>
                      <ul>
                        {Object.entries(questionData.options).map(
                          ([label, value]) => {
                            const inputId = `question-${questionId}-option-${value}`;
                            return (
                              <p className="readingA" key={value}>
                                <label
                                  htmlFor={inputId}
                                  style={{ cursor: "pointer" }}
                                >
                                  <input
                                    type="radio"
                                    name={`question-${questionId}`}
                                    id={inputId}
                                    value={label}
                                    checked={
                                      answers[questionId]?.find(
                                        (opt) => opt.value === value
                                      )?.checked || false
                                    }
                                    onChange={() =>
                                      handleAnswerChange(questionId, value)
                                    }
                                    style={{ marginRight: "8px" }} // Optional: add some space between the radio button and the text
                                  />
                                  {label}: {value}
                                </label>
                              </p>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  )
                )}
                {Object.entries(readingInfo.short_answer_question).map(
                  ([questionId, questionData], index) => (
                    <div key={questionId}>
                      <p className="readingQ">
                        {Object.keys(readingInfo.assessment).length + index + 1}
                        . {questionData.question}
                      </p>
                      <textarea
                        className="shortAnswerInput"
                        value={shortAnswers[questionId]}
                        placeholder="Enter your answer here..."
                        onChange={(e) =>
                          handleShortAnswerChange(questionId, e.target.value)
                        }
                      />
                    </div>
                  )
                )}
                <button
                  className={` ${
                    !allQuestionsAnswered
                      ? "submitButton-disabled"
                      : "submitButton"
                  }`}
                  disabled={!allQuestionsAnswered}
                  onClick={() => getSelectedAnswers()}
                >
                  Submit Answers
                </button>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default MainRead;
