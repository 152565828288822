import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios"; // Make sure to install axios if you haven't already
import { useLocation } from "react-router-dom";

// Custom hook to fetch student ID from URL
const useFetchStudentId = () => {
  const [studentId, setStudentId] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    const { studentId } = location.state || {};
    if (studentId) {
      setStudentId(studentId);
      // console.log(studentId)
    }
  }, [location]);

  return studentId;
};

// Custom hook to fetch data based on student ID
const useFetchData = (studentId: string | null) => {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (studentId) {
      axios
        .get(
          `${process.env.REACT_APP_API_ENDPOINT}/api/general-chatbot/session/${studentId}`
        )
        .then((response) => {
          setData(response.data);
          // console.log('Data fetched:', response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [studentId]);

  return data;
};

const Box = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
`;

const Button = styled.button`
  margin-top: 5px;
  color: white;
`;

const ChatHistory: React.FC<{ onSelectId: (id: string) => void }> = ({
  onSelectId,
}) => {
  const studentId = useFetchStudentId();
  const data = useFetchData(studentId); // Use the custom hook to fetch data
  const payload = data?.payload || [];
  const recentPayload = payload.slice(-5);

  // Assuming data contains chatHistory, update chatHistory accordingly

  return (
    <Box>
      <h3>Chat History</h3>
      {recentPayload.map((id: string, index: number) => (
        <Button key={index} onClick={() => onSelectId(id)}>
          {id}
        </Button>
      ))}
    </Box>
  );
};

export default ChatHistory;
