// Setup function for this Teacher Page
import React from'react';
import { useParams } from'react-router-dom';
//import { TeacherFunctions, TeacherFunctions2 } from '../../components/TeacherFunctions';
//import { TeacherFunctions } from '../../components/TeacherFunctions';
import TeacherApp from '../../components/TeacherApp/TeacherApp'; 


export default function TeacherPage() {
  return (
    <>
    {/* <TeacherFunctions2 /> */}
    <TeacherApp />
    
    </>
  )
}