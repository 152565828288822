import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import "./LessonChat.css";
import axios from "axios";
import ChatHistory from "../ChatHistory/ChatHistory";
import { time } from "console";

type ChatMessageProps = {
  isUser?: boolean;
  children: React.ReactNode;
};

const ChatMessage = styled.div<ChatMessageProps>`
  text-align: ${({ isUser }) => (isUser ? "right" : "left")};
`;

interface ChatInterfaceProps {
  initialChat: any[];
  selectedId?: string | null; // Add selectedId property
}

// Replace with your actual API call
const ChatInterface: React.FC<ChatInterfaceProps> = ({
  initialChat,
  selectedId: initialSelectedId,
}) => {
  const [chat, setChat] = useState(initialChat);
  const [student_Id, setstudentId] = useState("");
  const [isNewChat, setIsNewChat] = useState(false);
  const [timeStamp, settimeStamp] = useState<string | null>(null);
  const [history, setHistory] = useState<any[]>([]);
  const [selectedId, setSelectedId] = useState<string | null>(
    initialSelectedId || null
  );
  const location = useLocation();
  const resetChat = () => {
    setChat([]);
    setIsNewChat(true);
    setSelectedId(null); // Clear selected id when resetting chat
    setHistory([]); // Clear chat history
  };

  useEffect(() => {
    const { studentId } = location.state || {};
    if (studentId) {
      setstudentId(studentId);
      console.log(studentId);
    }
  }, [location]);

  useEffect(() => {
    const now = new Date();
    const initialtimeStamp = `${student_Id}_${
      now.getMonth() + 1
    }${now.getDate()}_${now.getHours()}${now.getMinutes()}${now.getSeconds()}`;
    settimeStamp(initialtimeStamp);
  }, [student_Id]);

  useEffect(() => {
    if (isNewChat) {
      const now = new Date();
      const newtimeStamp = `${student_Id}_${
        now.getMonth() + 1
      }${now.getDate()}_${now.getHours()}${now.getMinutes()}${now.getSeconds()}`;
      settimeStamp(newtimeStamp);
      setIsNewChat(false);
      setSelectedId(null);
    }

    console.log(selectedId);
    // Call API if there's a selectedId
    if (selectedId && student_Id) {
      axios
        .get(
          `${process.env.REACT_APP_API_ENDPOINT}/api/general-chatbot/history/${student_Id}/${selectedId}`
        )
        .then((response) => {
          console.log("API response:", response.data);
          setHistory(response.data.payload);
          const formattedHistory = response.data.payload
            .map((item: any) => {
              if (item.user) {
                return { isUser: true, message: item.user };
              } else if (item.bot) {
                return { isUser: false, message: item.bot };
              }
              return null;
            })
            .filter((item: any) => item !== null);
          // Append the formatted history to the existing chat
          setChat((prevChat) => [...prevChat, ...formattedHistory]);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [location, isNewChat, selectedId, student_Id]);

  const sendMessageToBot = async (message: string | FormData) => {
    console.log("HIHIHI");
    const userPayload = {
      user_input: message,
    };
    try {
      let response;
      if (typeof message === "string") {
        const formData = new FormData();
        formData.append("user_input", message);

        response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/api/general-chatbot`,
          {
            method: "POST",
            body: formData,
          }
        );
      }
      if (!response) {
        throw new Error("No response from the server.");
      }
      const data = await response.json();
      if (!response.ok) {
        console.error("Server responded with an error:", data);
        return;
      }

      // Format the bot's response to match the chat structure
      const botResponse = data.payload; // Assuming the payload is already correctly formatted
      const newChat = [
        { isUser: true, message: message }, // User message
        { isUser: false, message: botResponse }, // Bot message
      ];

      // Use a functional state update to ensure the latest state is used
      setChat((prevChat) => [...prevChat, ...newChat]);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className="chat-container-lesson-chat">
      <button className="new-chat-button" onClick={resetChat}>
        + New Chat
      </button>
      <div className="messagesWrapper-lesson-chat">
        {/* Render chat messages in chronological order */}
        {chat.map((msg, index) => {
          const messageClass = msg.isUser ? "user-message" : "bot-message";
          // If the message is a string, replace \n with actual new lines for rendering
          let renderedLines;
          if (typeof msg.message === "string") {
            renderedLines = msg.message.replace(/\\n/g, "\n");
          } else {
            renderedLines = msg.message;
          }
          return (
            <div key={index} className={`chat-message ${messageClass}`}>
              {msg.isUser ? "User: " : "Bot: "}
              <div className="text-pre-line">{renderedLines}</div>
            </div>
          );
        })}
      </div>
      {/* The ChatInput component remains at the bottom */}
      <ChatInput
        onSendMessage={sendMessageToBot}
        setChat={setChat}
        studentId={student_Id}
        timeStamp={timeStamp}
      />
    </div>
  );
};

interface ChatInputProps {
  onSendMessage: (message: string | FormData) => Promise<void>;
  setChat: React.Dispatch<React.SetStateAction<any[]>>;
  studentId: string;
  timeStamp: string | null;
}

const ChatInput: React.FC<ChatInputProps> = ({
  onSendMessage,
  setChat,
  studentId,
  timeStamp,
}) => {
  const [message, setMessage] = useState("");
  const [publisher, setPublisher] = useState("");
  const [subject, setSubject] = useState("");
  const [grade, setGrade] = useState("");
  const [chapter, setChapter] = useState("");
  const [useTextbook, setUseTextbook] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // New state for selected file
  const [attachmentText, setAttachmentText] = useState<string>("Attach");

  const sessiontimeStamp = timeStamp || "";

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSend();
    }
  };

  const handleSend = async () => {
    try {
      // Combine message, selectedFile, and dropdown values into a single payload
      const formData = new FormData();
      formData.append("message", message);
      formData.append("publisher", publisher);
      formData.append("subject", subject);
      formData.append("grade", grade);
      formData.append("chapter", chapter);
      formData.append("student_id", studentId);
      formData.append("session_id", sessiontimeStamp);
      formData.append("use_textbook", useTextbook.toString());

      let response;
      response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/general-chatbot`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response) {
        throw new Error("No response from the server.");
      }
      const data = await response.json();
      console.log(data);
      if (!response.ok) {
        console.error("Server responded with an error:", data);
        return;
      }
      const botResponse = JSON.stringify(data.payload) ?? "Message received.";
      setChat((prevChat) => [
        ...prevChat,
        { isUser: true, message: message },
        { isUser: false, message: botResponse },
      ]);
      setMessage("");
      setChapter("");
      setPublisher("");
      setGrade("");
      setSubject("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      // Here you could also implement logic to handle the selected file (e.g., upload it)
    }
  };

  const triggerFileSelect = () => {
    document.getElementById("file-input")?.click(); // Trigger file input click
  };

  return (
    <div>
      <div className="chat-input-container0">
        <button className="functionButton">Pre-Notes</button>
        <button className="functionButton">Practice Question</button>
        <button className="functionButton">Assess Work</button>
      </div>
      <div className="chat-input-container1">
        <input
          type="text"
          className="input-field-prac"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)} // Add the onKeyDown handler here
          placeholder="Type a message..."
        />
        <button className="attachment-button" onClick={triggerFileSelect}>
          📎 {attachmentText} {/* Display dynamic text here */}
        </button>
        {/* Hidden file input */}
        <input
          id="file-input"
          type="file"
          style={{ display: "none" }}
          onChange={handleFileSelect}
        />
        <button className="send-button" onClick={handleSend}>
          Send
        </button>
      </div>
      <div className="chat-input-container2">
        <label>
          <input
            type="checkbox"
            checked={useTextbook}
            onChange={(e) => setUseTextbook(e.target.checked)}
          />
          Use Textbook
        </label>
        <select
          className="dropdown-menu-prac"
          value={publisher}
          onChange={(e) => setPublisher(e.target.value)}
        >
          <option value="">Choose Publisher</option>
          <option value="pearson">Pearson</option>
          <option value="oxford">Oxford</option>"
        </select>
        <select
          className="dropdown-menu-prac"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        >
          <option value="">Choose Subject</option>
          <option value="english">English</option>
          <option value="math">Mathematics</option>
        </select>
        <select
          className="dropdown-menu-prac"
          value={grade}
          onChange={(e) => setGrade(e.target.value)}
        >
          <option value="">Choose Grade</option>
          <option value="primary_1">Primary 1</option>
          <option value="primary_2">Primary 2</option>
          <option value="primary_3">Primary 3</option>
          <option value="primary4">Primary 4</option>
          <option value="primary5">Primary 5</option>
        </select>
        <select
          className="dropdown-menu-prac"
          value={chapter}
          onChange={(e) => setChapter(e.target.value)}
        >
          <option value="">Choose Chapter</option>
          <option value="adverbs">Adverb</option>
          <option value="grammar">Grammar</option>
          <option value="4">4</option>
          <option value="2-1">2-1</option>
        </select>
      </div>
    </div>
  );
};

export default ChatInterface;
export { ChatInput };
