import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { TiTick, TiTickOutline } from "react-icons/ti";
import { FaXmark } from "react-icons/fa6";
import "./ParentTable.css";

// Define interfaces
interface ProgressDetail {
  status: string;
  comment: string;
}

interface ProgressTopic {
  Topic: string;
  Description: string;
  [key: string]: any; // Allow dynamic keys for progress types
}

interface Week {
  [lesson: string]: LessonData;
}

interface LessonData {
  lesson_date: string;
  lesson_progress: Record<string, ProgressTopic>;
}

interface PTableProps {
  studentId: string;
}

interface HomeworkItem {
  name: string;
  subject: string;
  due_date: string;
  seek_assistance: boolean;
}

interface StudentStatusResponse {
  status: string;
  lesson_status: {
    topic: string;
    action: string;
    status: string;
  }[];
  attention_status: string;
  attention_update_ts: string;
  update_ts: string;
}

const ParentTable: React.FC<PTableProps> = ({ studentId }) => {
  const [lessonInfo, setLessonInfo] = useState<Week>({});
  const [showModal, setShowModal] = useState(false);
  const [currentProgress, setCurrentProgress] = useState<
    Record<string, ProgressTopic>
  >({});
  const [reportInfo, setReportInfo] = useState("");
  const [showReportModal, setShowReportModal] = useState(false);
  const [homeworkItems, setHomeworkItems] = useState<HomeworkItem[]>([]);
  const [lessonStatus, setLessonStatus] = useState<StudentStatusResponse>();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchLessonData();
  }, []);

  const handleReport = async (week: string, lesson: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/progress-review/report/${studentId}/${week}/${lesson}`
      );
      setReportInfo(response.data.payload);
      window.open(reportInfo);
      // setShowReportModal(true);
      // console.log(response.data.payload);
    } catch (error) {
      console.error("Error fetching lesson data:", error);
    }
  };

  const handleProgressClick = (date: string) => {
    fetchStatusData(date);
    // setCurrentProgress(progressData);
    setShowModal(true);
  };

  const fetchStatusData = async (date: string) => {
    const { studentId } = location.state || {};
    try {
      //   console.log(date);
      const statusResponse = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/monitor/status/${studentId}/${date}`
      );
      setLessonStatus(statusResponse.data.payload);
      console.log(statusResponse.data.payload.lesson_status);
    } catch (error) {
      console.error("Error fetching status data:", error);
    }
  };

  const fetchLessonData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/english-progress/${studentId}`
      );
      setLessonInfo(response.data.payload);
    } catch (error) {
      console.error("Error fetching lesson data:", error);
    }
  };

  function renderTableRows(data: Week) {
    return Object.entries(data).map(([week, lessons]) => {
      // Convert lessons object to an array and sort by lesson_date
      const sortedLessons = Object.entries(lessons).sort(
        ([, lessonDataA], [, lessonDataB]) => {
          const dateA = new Date(lessonDataA.lesson_date);
          const dateB = new Date(lessonDataB.lesson_date);
          return dateA.getTime() - dateB.getTime();
        }
      );

      return (
        <tbody key={week}>
          {sortedLessons.map(([lesson, lessonData]) => (
            <tr key={lesson}>
              <td>{lessonData.lesson_date}</td>
              <td>英文</td>
              <td>{week}</td>
              <td>{lesson}</td>
              <td>
                <button
                  className="report-button"
                  onClick={() => handleProgressClick(lessonData.lesson_date)}
                >
                  查看進度
                </button>
              </td>
              <td>
                <button
                  onClick={() => handleReport(week, lesson)}
                  className="report-button"
                >
                  查看報告
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      );
    });
  }

  const fetchHomeworkData = async (studentId: string, date: string) => {
    try {
      const homeworkResponse = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/homework/${studentId}/${date}`
      );
      setHomeworkItems(homeworkResponse.data.payload);
    } catch (error) {
      //   console.error("Error fetching homework data:", error);
      setHomeworkItems([]);
    }
  };

  // Aggregating the lesson status data
  const aggregatedData = lessonStatus?.lesson_status?.reduce((acc, item) => {
    if (!acc[item.topic]) {
      acc[item.topic] = [];
    }
    if (item.action != "review") {
      acc[item.topic].push({ action: item.action, status: item.status });
    }
    return acc;
  }, {} as Record<string, { action: string; status: string }[]>);

  return (
    <div>
      <>
        <table className="studyPlanTable">
          <thead>
            <tr>
              <th>日期</th>
              <th>科目</th>
              <th>學週</th>
              <th>課程</th>
              <th>進度</th>
              <th>報告</th>
            </tr>
          </thead>
          {renderTableRows(lessonInfo)}
        </table>
        {showModal && (
          <div className="modal-background" onClick={() => setShowModal(false)}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <h2>Progress Details</h2>
              <table className="modal-table">
                <thead>
                  <tr>
                    <th>單元</th>
                    <th>課堂內容</th>
                    <th>狀態</th>
                  </tr>
                </thead>
                <tbody>
                  {aggregatedData &&
                    Object.entries(aggregatedData).map(([topic, actions]) => (
                      <tr key={topic}>
                        <td>{topic}</td>
                        <td>
                          {actions.map((actionDetail, index) => (
                            <div key={index}>{actionDetail.action}</div>
                          ))}
                        </td>
                        <td>
                          {actions.map((actionDetail, index) => (
                            <div key={index}>
                              <>
                                {actionDetail.status === "done" ? (
                                  <TiTick />
                                ) : (
                                  <FaXmark />
                                )}
                              </>
                            </div>
                          ))}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <button onClick={() => setShowModal(false)}>關閉</button>
            </div>
          </div>
        )}
        {showReportModal && (
          <div
            className="modal-background"
            onClick={() => setShowReportModal(false)}
          >
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <h2>報告</h2>
              <ReactMarkdown>{reportInfo}</ReactMarkdown>
              <button onClick={() => setShowReportModal(false)}>關閉</button>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default ParentTable;
