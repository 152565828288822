import React, { useState } from 'react';  
import styled from 'styled-components';  
import StudentTable from '../../components/StudyPlan/StudyPlan';


export default function StudyPlanPage() {
  return (
    <>
    {/* <TeacherFunctions2 /> */}
    <StudentTable />
    
    </>
  )
}