import React from "react";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { ReactMic, ReactMicStopEvent } from "react-mic";
import convertToWav from "audiobuffer-to-wav";
import "./ChatInterface.css";

type ChatMessageProps = {
  isUser?: boolean;
  children: React.ReactNode;
};

const ChatMessage = styled.div<ChatMessageProps>`
  text-align: ${({ isUser }) => (isUser ? "right" : "left")};
`;

interface ChatInterfaceProps {
  initialChat: string[];
}

// Replace with your actual API call
const ChatInterface: React.FC<ChatInterfaceProps> = ({ initialChat }) => {
  const [chat, setChat] = useState(initialChat);

  const sendMessageToBot = async (message: string | FormData) => {
    console.log("HIHIHI");
    try {
      let response;
      if (typeof message === "string") {
        response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/api/chatbot/test`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ user_input: message }),
          }
        );
      } else if (message instanceof FormData) {
        // for (const [key, value] of message.entries()) {
        //   console.log(`${key}:`, value);
        // }
        response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/api/content-creation/speaking`,
          {
            method: "POST",
            body: message,
          }
        );
      }
      if (!response) {
        throw new Error("No response from the server.");
      }
      const data = await response.json();
      if (!response.ok) {
        console.error("Server responded with an error:", data);
        return;
      }
      // console.log(data.payload)
      const botResponse =
        JSON.stringify(data.payload.model_output.score) ?? "Message received.";
      setChat([
        ...chat,
        typeof message === "string" ? message : "Voice Message Sent",
        botResponse,
      ]);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className="ChatContainer">
      <div className="messagesWrapper">
        {/* Render chat messages in chronological order */}
        {chat.map((msg, index) => {
          const isUser = index % 2 !== 0;
          const messageClass = isUser ? "user-message" : "bot-message";

          // Check if the message is an object indicating both text and file
          if (
            typeof msg === "object" &&
            msg !== null &&
            ("text" in msg || "file" in msg)
          ) {
            const { text, file } = msg; // Destructure the message object
            return (
              <div key={index} className={`chat-message ${messageClass}`}>
                {isUser ? "Bot: " : "User: "}
                {text && <span>{text}</span>}
                {file && <p>Received a file.</p>}{" "}
                {/* Or you can implement a way to handle/download the file */}
              </div>
            );
          } else {
            // Regular text message
            return (
              <div key={index} className={`chat-message ${messageClass}`}>
                {isUser ? "Bot: " : "User: "}
                {msg}
              </div>
            );
          }
        })}
      </div>
      {/* The ChatInput component remains at the bottom */}
      <ChatInput onSendMessage={sendMessageToBot} />
    </div>
  );
};

interface ChatInputProps {
  onSendMessage: (message: string | FormData) => void;
}

const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage }) => {
  const [message, setMessage] = useState("");
  const [isRecording, setIsRecording] = useState(false); // Track recording state
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // New state for selected file
  const [attachmentText, setAttachmentText] = useState<string>("Attach");
  const [recordedBlob, setRecordedBlob] = useState<Blob | null>(null);
  const [voiceMessageText, setVoiceMessageText] = useState("");

  useEffect(() => {
    if (selectedFile) {
      setAttachmentText(selectedFile.name);
    } else {
      setAttachmentText("Attach");
    }
  }, [selectedFile]);

  const handleStartRecording = () => {
    setIsRecording(true);
  };

  const handleStopRecording = (recordedData: ReactMicStopEvent) => {
    setIsRecording(false);
    setRecordedBlob(recordedData.blob);
  };

  const handleSendVoiceMessage = async () => {
    if (recordedBlob && message.trim()) {
      try {
        // Convert the WebM Blob to an AudioBuffer
        const audioContext = new AudioContext();
        const arrayBuffer = await recordedBlob.arrayBuffer();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

        // Convert the AudioBuffer to a WAV ArrayBuffer
        const wavArrayBuffer = convertToWav(audioBuffer, { float32: true });

        // Convert the WAV ArrayBuffer to a Blob
        const wavBlob = new Blob([wavArrayBuffer], { type: "audio/wav" });

        // Now, send the WAV Blob
        const formData = new FormData();
        formData.append("audio_file", wavBlob, "student-recording.wav");
        formData.append("text_message", message); // Include the text message
        await onSendMessage(formData);
        setRecordedBlob(null); // Reset after sending
        setVoiceMessageText(""); // Clear the voice message text
      } catch (error) {
        console.error("Error converting WebM to WAV or sending:", error);
      }
    }
  };

  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleSend = async () => {
    if (message.trim() || selectedFile) {
      try {
        let userPayload: any = { text: message };
        if (selectedFile) {
          const fileBase64 = await fileToBase64(selectedFile);
          const base64Data = fileBase64.split(",")[1];
          userPayload.file = base64Data;
          setSelectedFile(null);
        }
        await onSendMessage(userPayload.text); // Ensure only the text is sent if no file is selected
        setMessage("");
      } catch (error) {
        console.error(
          "Error converting file to base64 or sending message:",
          error
        );
      }
    }
  };

  const handleVoiceRecord = () => {
    if (!isRecording) {
      // Start recording logic would go here
      console.log("Voice recording started");
      setIsRecording(true);
    } else {
      // Stop recording and process the recorded audio to text - this is where you'd implement speech recognition
      console.log("Voice recording stopped");
      setIsRecording(false);
      // In a real scenario, you'd convert the recorded audio to text here
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSend();
    }
  };
  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      // Here you could also implement logic to handle the selected file (e.g., upload it)
    }
  };

  const triggerFileSelect = () => {
    document.getElementById("file-input")?.click(); // Trigger file input click
  };

  return (
    <>
      <div className="chat-input-container1-CI">
        <input
          type="text"
          className="input-field"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
          placeholder="Type a message..."
        />
      </div>
      <div className="chat-input-container2-CI">
        {/* Voice input button */}
        {isRecording ? (
          <button
            className="voice-button"
            onClick={() => setIsRecording(false)}
          >
            🎙️Stop Recording
          </button>
        ) : (
          <button className="voice-button" onClick={() => setIsRecording(true)}>
            🎙️Record
          </button>
        )}
        <ReactMic
          record={isRecording}
          className="sound-wave hidden-mic" // Apply the hidden-mic class always
          onStop={handleStopRecording}
          //audioEncoder="pcm" // This line specifies the recording format as WAV
        />
        {recordedBlob && (
          <button
            className="send-voice-button"
            onClick={handleSendVoiceMessage}
            disabled={!message.trim()}
          >
            Send Recording with Text
          </button>
        )}
        {/* Attachment button */}
        <button className="attachment-button" onClick={triggerFileSelect}>
          📎 {attachmentText} {/* Display dynamic text here */}
        </button>
        {/* Hidden file input */}
        <input
          id="file-input"
          type="file"
          style={{ display: "none" }}
          onChange={handleFileSelect}
        />
        <button className="send-button" onClick={handleSend}>
          Send
        </button>
      </div>
    </>
  );
};

export default ChatInterface;
export { ChatInput };
