import { use } from "marked";
import React, { useState, useEffect } from "react";

const TextToSpeech = ({ text, pause, stop }) => {
  const [isPaused, setIsPaused] = useState(false);
  const [utterance, setUtterance] = useState(null);
  const [voice, setVoice] = useState(null);
  const [pitch, setPitch] = useState(0.9);
  const [rate, setRate] = useState(0.6);
  const [volume, setVolume] = useState(1);

  useEffect(() => {
    const synth = window.speechSynthesis;
    const u = new SpeechSynthesisUtterance(text);
    const voices = synth.getVoices();

    setUtterance(u);
    setVoice(voices.find((v) => v.name === "Aaron"));

    return () => {
      synth.cancel();
    };
  }, [text]);

  const handlePlay = () => {
    const synth = window.speechSynthesis;
    setPitch(0.9);
    setRate(0.6);
    setVolume(1);
    const voices = synth.getVoices();

    if (isPaused) {
      synth.resume();
    } else {
      utterance.voice = voices.find((v) => v.name === "Aaron");
      utterance.voice = voice;
      utterance.pitch = pitch;
      utterance.rate = rate;
      utterance.volume = volume;
      synth.speak(utterance);
    }

    setIsPaused(false);
  };

  const handlePause = () => {
    const synth = window.speechSynthesis;

    synth.pause();

    setIsPaused(true);
  };

  const handleStop = () => {
    const synth = window.speechSynthesis;

    synth.cancel();

    setIsPaused(false);
  };

  const handleVoiceChange = (event) => {
    const voices = window.speechSynthesis.getVoices();
    setVoice(voices.find((v) => v.name === event.target.value));
  };

  const handlePitchChange = (event) => {
    setPitch(parseFloat(event.target.value));
  };

  const handleRateChange = (event) => {
    setRate(parseFloat(event.target.value));
  };

  const handleVolumeChange = (event) => {
    setVolume(parseFloat(event.target.value));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <label>
        Voice: {voice?.name}
        <select value={voice?.name} onChange={handleVoiceChange}>
          {window.speechSynthesis.getVoices().map((voice) => (
            <option key={voice.name} value={voice.name}>
              {voice.name}
            </option>
          ))}
        </select>
      </label>

      <br />

      <label>
        Pitch: {pitch}
        <input
          type="range"
          min="0.5"
          max="2"
          step="0.1"
          value={pitch}
          onChange={handlePitchChange}
        />
      </label>

      <br />

      <label>
        Speed: {rate}
        <input
          type="range"
          min="0.5"
          max="2"
          step="0.1"
          value={rate}
          onChange={handleRateChange}
        />
      </label>
      <br />
      <label>
        Volume: {volume}
        <input
          type="range"
          min="0"
          max="1"
          step="0.1"
          value={volume}
          onChange={handleVolumeChange}
        />
      </label>

      <br /> */}

      <button
        style={{ backgroundColor: "#6ddad9", marginRight: "10px" }}
        onClick={handlePlay}
      >
        {isPaused ? "Resume" : "Play"}
      </button>
      {pause === true ? (
        <button
          style={{ backgroundColor: "#6ddad9", marginRight: "10px" }}
          onClick={handlePause}
        >
          Pause
        </button>
      ) : (
        <></>
      )}
      {stop === true ? (
        <button
          style={{ backgroundColor: "#6ddad9", marginRight: "10px" }}
          onClick={handleStop}
        >
          Stop
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TextToSpeech;
