import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./LessonPlan.css";
import { Dict } from "styled-components/dist/types";

// Define interfaces
interface Lesson {
  date?: string;
  topic?: string;
  instruction?: string;
  material?: string;
  status?: string;
  duration?: string; // Made optional to match the data structure provided
}

interface TopicMeta {
  topic: string;
  level: string;
  comment: string; // Add the comment property
}

interface Topic {
  topic: Record<string, TopicMeta>;
}

interface Progress {
  "Lesson Objectives": string;
  Topics: Record<string, TopicMeta>; // Update to TopicMeta directly, as your data seems to be a flat list, not nested
  Summary?: string;
  Homework?: string;
}

interface ApiResponse {
  errorMessage: string;
  payload: Record<string, Record<string, Lesson>>;
}

interface ProgressApiResponse {
  errorMessage: string;
  payload: Record<string, Progress>; // Progress is not nested, so update accordingly
}

interface transcriptApiResponse {
  errorMessage: string;
  payload: Record<string, string>;
}

const StudentTable = () => {
  const [studentsData, setStudentsData] = useState<ApiResponse[]>([]);
  const [progressData, setProgressData] = useState<ProgressApiResponse[]>([]);
  const [transcriptData, setTranscriptData] = useState<transcriptApiResponse[]>(
    []
  );
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve studentId and date from the location state
    const { studentId, date } = location.state || {};
    if (studentId && date) {
      fetchData(studentId, date);
      fetchProgress(studentId, date);
      fetchTranscript(studentId, date);
      // console.log(progressData.length);
    }
  }, [location]);

  const fetchData = async (studentId: string, date: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/progress-review/lesson-plan/${studentId}/${date}`
      );
      setStudentsData([response.data]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchTranscript = async (studentId: string, date: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/progress-review/audio-transcript/${studentId}/${date}`
      );
      setTranscriptData([response.data]);
      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchProgress = async (studentId: string, date: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/progress-review/progress/${studentId}/${date}`
      );
      setProgressData([response.data]);
      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching progress:", error);
    }
  };

  const fetchAudio = async (studentId: string, date: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/progress-review/audio-recording/${studentId}/${date}`
      );
      const audioData = response.data;
      for (const subject in audioData.payload) {
        if (
          audioData.payload.hasOwnProperty(subject) &&
          typeof audioData.payload[subject] === "string"
        ) {
          window.open(audioData.payload[subject], "_blank"); // Open each URL found in the payload
        }
      }
    } catch (error) {
      console.error("Error fetching lesson notes:", error);
    }
  };

  const fetchLessonNotes = async (studentId: string, date: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/progress-review/lesson-notes/${studentId}/${date}`
      );
      const notesData = response.data;
      for (const subject in notesData.payload) {
        if (
          notesData.payload.hasOwnProperty(subject) &&
          typeof notesData.payload[subject] === "string"
        ) {
          window.open(notesData.payload[subject], "_blank"); // Open each URL found in the payload
        }
      }
    } catch (error) {
      console.error("Error fetching lesson notes:", error);
    }
  };

  const fetchClassPractice = async (studentId: string, date: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/content-creation/class-practice/${studentId}/${date}`
      );
      const notesData = response.data;
      for (const subject in notesData.payload) {
        if (
          notesData.payload.hasOwnProperty(subject) &&
          typeof notesData.payload[subject] === "string"
        ) {
          window.open(notesData.payload[subject], "_blank"); // Open each URL found in the payload
        }
      }
    } catch (error) {
      console.error("Error fetching class practice:", error);
    }
  };

  const renderLessonPlan = () => {
    // Check if studentsData2.payload contains any null values for its properties
    const hasNullValues = Object.values(studentsData[0]?.payload || {}).some(
      (value) => value === null
    );

    if (!studentsData || studentsData.length === 0 || hasNullValues) {
      return <p>No lesson plan available</p>;
    }
  };

  const testData = {
    errorMessage: "",
    payload: {
      english:
        "Guest-1: Hi, I'm Ivan. What's your name? My name is Natalie. \nGuest-1: So I would like to ask you a simple questions. The first question is do you prefer working on group projects or individual assignments? \nGuest-2: I prefer to work individually because I think cooperating with others is very hard and it requires a lot of effort to communicate to produce a good work. \nGuest-1: So when you are facing a stressful situation, what will you do to relieve the stress? \nGuest-2: If I'm feeling very stressful, I will go and sleep because I believe that taking a rest is very important to. \nGuest-2: Actually restart yourself and work better. \n",
    },
  };

  return (
    <div>
      {renderLessonPlan()}
      {/* Start of the table structure */}
      <table className="LessonPlanTable">
        <thead>
          <tr>
            <th>Session</th>
            <th>Topic</th>
            <th>Instruction</th>
            <th>Duration</th>
            <th>Material</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {/* Rendering lessons only if there's valid data */}
          {studentsData.length > 0 && !studentsData[0].errorMessage && (
            <>
              {Object.entries(studentsData[0].payload).map(
                ([subject, lessons]) => (
                  <>
                    {Object.entries(lessons).map(([session, details]) => (
                      <tr key={`${subject}-${session}`}>
                        <td>{session || "N/A"}</td>
                        <td>{details.topic || "N/A"}</td>
                        <td>{details.instruction || "N/A"}</td>
                        <td>{details.duration || "N/A"}</td>
                        <td>{details.material || "N/A"}</td>
                        <td>{details.status || "N/A"}</td>
                      </tr>
                    ))}
                  </>
                )
              )}
            </>
          )}
          {/* Message when no lesson plan is available */}
          {!studentsData.length ||
            (studentsData[0].errorMessage && (
              <tr>
                <td>No lesson plan available</td>
              </tr>
            ))}
        </tbody>
      </table>
      {/* End of the table structure */}
      {/* Button to fetch lesson notes */}
      <button
        onClick={() => {
          const { studentId, date } = location.state || {};
          if (studentId && date) {
            fetchLessonNotes(studentId, date);
          }
        }}
      >
        Fetch Lesson Notes
      </button>
      <button
        onClick={() => {
          const { studentId, date } = location.state || {};
          if (studentId && date) {
            fetchClassPractice(studentId, date);
          }
        }}
      >
        Fetch Class Practice
      </button>
      <button
        onClick={() => {
          const { studentId, date } = location.state || {};
          if (studentId && date) {
            fetchAudio(studentId, date);
          }
        }}
      >
        Fetch Recording
      </button>
      <div>
        <h3>Progress</h3>
        {progressData.length > 0 && progressData[0].payload && (
          <div>
            <ul>
              {Object.entries(progressData[0].payload).map(
                ([subject, subjectData]) => (
                  <>
                    <li>
                      <strong>Lesson Objectives:</strong>{" "}
                      {subjectData["Lesson Objectives"]}
                    </li>
                    {Object.entries(subjectData.Topics).map(
                      ([topicKey, topicDetails]) => (
                        <li key={topicKey}>
                          <strong>Topic {topicKey}:</strong>{" "}
                          {topicDetails.topic} ({topicDetails.level})
                          <br />
                          <em>Comment:</em> {topicDetails.comment}
                        </li>
                      )
                    )}
                    <li>
                      <strong>Summary:</strong> {subjectData.Summary || "N/A"}
                    </li>
                    <li>
                      <strong>Homework:</strong> {subjectData.Homework || "N/A"}
                    </li>
                  </>
                )
              )}
            </ul>
          </div>
        )}
      </div>
      <div>
        <h3>Transcript</h3>
        {transcriptData.length > 0 && transcriptData[0].payload && (
          <div>
            <ul>
              {Object.entries(transcriptData[0].payload).map(
                ([subject, subjectData]) => (
                  <>
                    <p key={subject}>
                      <p>{subjectData || "N/A"}</p>
                    </p>
                  </>
                )
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentTable;
