import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "./MainSchedule.css";

interface LessonScheduleProps {
  highlightIndex: number;
  onLearningAction: (
    topic: string,
    action: string,
    length: number,
    status: string
  ) => void;
  onProgressAction: (
    topic: string,
    action: string,
    statusIndex: number
  ) => void;
  studentId?: string | null;
}

interface LessonInfoResponse {
  topic: string;
  action: string;
  status: string;
}

const LessonSchedule: React.FC<LessonScheduleProps> = ({
  highlightIndex,
  onLearningAction,
  onProgressAction,
  studentId,
}) => {
  const [lessonInfo, setLessonInfo] = useState<LessonInfoResponse[]>([]);
  const [student_id, setStudentId] = useState<string | null>(studentId || null);
  const location = useLocation();
  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (!hasFetchedData.current) {
      const { studentId } = location.state || {};
      setStudentId(studentId);
      fetchData();
      hasFetchedData.current = true;
    }
  }, []);

  const fetchData = async () => {
    const { studentId } = location.state || {};
    setStudentId(studentId);
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based in JavaScript
    const date = String(now.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${date}`;
    // const formattedDate = `${year}-${month}-21`;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/monitor/status/${studentId}/${formattedDate}`
      );
      setLessonInfo(response.data.payload.lesson_status);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (lessonInfo.length > 0) {
      const inProgressLesson = lessonInfo.find(
        (lesson) => lesson.status === "in_progress"
      );
      const inProgressLessonIndex = lessonInfo.findIndex(
        (lesson) => lesson.status === "in_progress"
      );

      console.log("in Progress lesson", inProgressLesson);
      console.log("in Progress index", inProgressLessonIndex);

      if (inProgressLesson) {
        onProgressAction(
          inProgressLesson.topic,
          inProgressLesson.action,
          inProgressLessonIndex
        );
      }
    }
  }, [lessonInfo]);

  useEffect(() => {
    if (
      lessonInfo.length > 0 &&
      highlightIndex >= 0 &&
      highlightIndex < lessonInfo.length
    ) {
      onLearningAction(
        lessonInfo[highlightIndex].topic,
        lessonInfo[highlightIndex].action,
        lessonInfo.length,
        lessonInfo[highlightIndex].status
      );
    }
  }, [highlightIndex, lessonInfo]);

  return (
    <>
      {Array.isArray(lessonInfo) &&
        lessonInfo.map((lesson, index) => (
          <div
            key={index}
            className={`schedule-button ${
              index === highlightIndex ? "highlighted" : ""
            }`}
          >
            {lesson.topic} - {lesson.action}
          </div>
        ))}
    </>
  );
};

export default LessonSchedule;
