import React from "react";
import "./NavBar.css";

interface NavBarProps {
  onCategoryChange: (category: string) => void;
  activeCategory: string; // Add this line to the interface
}

const NavBar: React.FC<NavBarProps> = ({
  onCategoryChange,
  activeCategory,
}) => {
  const handleClick = (category: string) => {
    onCategoryChange(category);
  };

  return (
    <div className="nav-bar">
      <button
        className={`nav-button ${
          activeCategory === "all" ? "active" : "inactive"
        }`}
        data-category="all"
        onClick={() => handleClick("all")}
      >
        All
      </button>
      <button
        className={`nav-button ${
          activeCategory === "learn" ? "active" : "inactive"
        }`}
        data-category="Learn"
        onClick={() => handleClick("learn")}
      >
        learn
      </button>
      <button
        className={`nav-button ${
          activeCategory === "create" ? "active" : "inactive"
        }`}
        data-category="create"
        onClick={() => handleClick("create")}
      >
        Create
      </button>
      {/* Add more buttons as needed */}
    </div>
  );
};

export default NavBar;
