import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./searchPage.css";
import Modal from "../../../../components/Modal/Modal";

interface StudentInfoResponse {
  name: string;
  age: string;
  grade: string;
  school: string;
  subjects: string[];
  textbook: Record<
    string,
    { publisher: string; subject: string; grade: string }
  >;
  lesson_duration: number;
  lesson_per_week: number;
  description: {
    academic_ability: Record<string, number>;
    personality_strength: Record<string, number>;
  };
}

interface StudentStatusResponse {
  status: string;
  lesson_status: {
    topic: string;
    action: string;
    status: string;
  }[];
  attention_status: string;
  attention_update_ts: string;
  update_ts: string;
}

interface HomeworkItem {
  name: string;
  subject: string;
  due_date: string;
  seek_assistance: boolean;
}

interface ChatLog {
  [key: string]: {
    ai: string;
    human: string;
  }[];
}

const SearchPage: React.FC = () => {
  const [inputValue, setInputValue] = useState("");
  const [studentId, setStudentId] = useState("");
  const [studentInfo, setStudentInfo] = useState<StudentInfoResponse[]>([]);
  const [studentStatus, setStudentStatus] = useState<StudentStatusResponse>();
  const [homeworkItems, setHomeworkItems] = useState<HomeworkItem[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [chatLog, setChatLog] = useState<ChatLog>({});
  const [logSummary, setLogSummary] = useState<string>("");
  const [selectedChatLogDate, setSelectedChatLogDate] = useState<string>("");
  const [selectedLessonStatusIndex, setSelectedLessonStatusIndex] =
    useState<number>();
  const [selectedLessonLogSession, setSelectedLessonLogSession] =
    useState<string>();
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const fetchStudentData = async (inputValue: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/students/${inputValue}`
      );
      //   console.log(response);
      if (response.data.name === "Unknown") {
        alert("Student not found");
        return;
      }
      setStudentInfo([response.data]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchStatusData = async (studentId: string, date: string) => {
    try {
      //   console.log(date);
      const statusResponse = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/monitor/status/${studentId}/${date}`
      );
      //   console.log(statusResponse);
      setStudentStatus(statusResponse.data.payload);
    } catch (error) {
      console.error("Error fetching status data:", error);
      setStudentStatus(undefined);
    }
  };

  const fetchHomeworkData = async (studentId: string, date: string) => {
    try {
      const homeworkResponse = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/homework/${studentId}/${date}`
      );
      setHomeworkItems(homeworkResponse.data.payload);
    } catch (error) {
      //   console.error("Error fetching homework data:", error);
      setHomeworkItems([]);
    }
  };

  const fetchChatLog = async (studentId: string, date: string) => {
    try {
      const chatLogResponse = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/monitor/agent-log/${studentId}/${date}`
      );
      //   console.log(chatLogResponse);
      setChatLog(chatLogResponse.data.payload);
    } catch (error) {
      console.error("Error fetching chat log:", error);
      setChatLog({});
    }
  };

  const fetchLogSummary = async (studentId: string, date: string) => {
    try {
      const logSummaryResponse = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/monitor/agent-log-summary/${studentId}/${date}`
      );
      //   console.log(logSummaryResponse);
      setLogSummary(logSummaryResponse.data.payload);
    } catch (error) {
      //   console.error("Error fetching log summary:", error);
      setLogSummary("");
    }
  };

  const handleButtonClick = () => {
    setStudentId(inputValue);
    fetchStudentData(inputValue);
    setInputValue("");
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = event.target.value;
    setSelectedDate(date);
    if (studentId && date) {
      fetchHomeworkData(studentId, date);
      fetchStatusData(studentId, date);
      fetchChatLog(studentId, date);
      fetchLogSummary(studentId, date);
    }
  };

  const handleLessonStatusClick = (selectedDate: string, index: number) => {
    setSelectedLessonStatusIndex(index);
    setSelectedLessonLogSession(
      studentId + "_" + selectedDate + "_topic" + index
    );
    openModal();
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleRefreshButtonClick = () => {
    if (studentId && selectedDate) {
      fetchHomeworkData(studentId, selectedDate);
      fetchStatusData(studentId, selectedDate);
      fetchChatLog(studentId, selectedDate);
      fetchLogSummary(studentId, selectedDate);
    }
  };

  useEffect(() => {
    if (studentId && selectedDate) {
      const intervalId = setInterval(() => {
        fetchHomeworkData(studentId, selectedDate);
        fetchStatusData(studentId, selectedDate);
        fetchChatLog(studentId, selectedDate);
        fetchLogSummary(studentId, selectedDate);
      }, 10000); // Fetch data every 10 seconds

      return () => clearInterval(intervalId); // Clear interval on component unmount
    }
  }, [studentId, selectedDate]);

  return (
    <>
      <div className="infoHeader">
        <strong>Search</strong>
      </div>
      <div className="searchBar">
        <input
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Enter student ID"
          className="search-input"
        />
        <button onClick={handleButtonClick} className="search-button">
          Search
        </button>
        {studentId && (
          <>
            <div className="dateInputBar">
              <input
                className="dateInput"
                type="date"
                value={selectedDate}
                onChange={handleDateChange}
              />
              <button
                className="todayButton"
                onClick={() => {
                  setSelectedDate(new Date().toISOString().split("T")[0]);
                  if (studentId && new Date().toISOString().split("T")[0]) {
                    fetchHomeworkData(
                      studentId,
                      new Date().toISOString().split("T")[0]
                    );
                    fetchStatusData(
                      studentId,
                      new Date().toISOString().split("T")[0]
                    );
                    fetchChatLog(
                      studentId,
                      new Date().toISOString().split("T")[0]
                    );
                    fetchLogSummary(
                      studentId,
                      new Date().toISOString().split("T")[0]
                    );
                  }
                }}
              >
                Today
              </button>
            </div>
            <div className="refreshRow">
              <button
                className="refreshButton"
                onClick={handleRefreshButtonClick}
              >
                Refresh
              </button>
              Previous: {new Date().toLocaleTimeString()}
            </div>
          </>
        )}
      </div>
      {studentInfo.length > 0 ? (
        <div className="outerDetailContainer">
          <div className="studentDetailWrapper">
            <div className="studentInfo infoBlock">
              <h2>Student Information</h2>
              <div>
                <strong>Student ID:</strong> {studentId}
              </div>
              <div>
                <strong>Name:</strong> {studentInfo[0].name}
              </div>
              <div>
                <strong>Age:</strong> {studentInfo[0].age}
              </div>
              <div>
                <strong>Grade:</strong> {studentInfo[0].grade}
              </div>
              <div>
                <strong>School:</strong> {studentInfo[0].school}
              </div>
              <div>
                <strong>Subjects:</strong> {studentInfo[0].subjects.join(", ")}
              </div>
              <div>
                <strong>Textbook Publisher:</strong>{" "}
                {studentInfo[0].textbook.english.publisher}
              </div>
              <div>
                <strong>Lesson Per Week:</strong>{" "}
                {studentInfo[0].lesson_per_week}
              </div>
            </div>
            <div className="studentStatus infoBlock">
              {studentStatus !== undefined &&
                studentStatus?.status === "tutoring" && (
                  <>
                    <h2>Student Status</h2>
                    {studentStatus !== undefined ? (
                      <>
                        <div>
                          <strong>Status:</strong> {studentStatus?.status}
                        </div>
                        {new Date().toISOString().split("T")[0] ===
                        selectedDate ? (
                          <>
                            <strong>Attention Status:</strong>{" "}
                            {studentStatus?.attention_status === "normal" ? (
                              <span className="normalText">Normal</span>
                            ) : studentStatus?.attention_status === "idle_3" ? (
                              <span className="idle3Text">
                                Idle (3 minutes)
                              </span>
                            ) : (
                              <span className="idle10Text">
                                Idle (10 minutes)
                              </span>
                            )}
                            <div>
                              <strong>Update Time:</strong>{" "}
                              {studentStatus?.update_ts}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <div>No student status found for the selected date.</div>
                    )}
                  </>
                )}
            </div>
            <div className="homeworkList infoBlock">
              <h2>Homework List</h2>
              <div>
                {homeworkItems.length > 0 ? (
                  <table className="homeworkTable" cellSpacing={15}>
                    <thead>
                      <tr>
                        <th>Homework</th>
                        <th>Subject</th>
                        <th>Due Date</th>
                        <th>Uploaded image</th>
                      </tr>
                    </thead>
                    <tbody>
                      {homeworkItems.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.subject}</td>
                          <td>{item.due_date}</td>
                          <td>{item.seek_assistance ? "Yes" : "No"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div>No homework items found for the selected date.</div>
                )}
              </div>
            </div>
          </div>
          <div className="studentLessonStatus">
            <h2>Lesson Status</h2>
            <div>
              {selectedDate && studentStatus !== undefined ? (
                <div>
                  <table className="statusTable">
                    <thead>
                      <tr>
                        <th>Topic</th>
                        <th>Action</th>
                        <th>Status</th>
                        <th>Lesson Chatlog</th>
                      </tr>
                    </thead>
                    <tbody>
                      {studentStatus?.lesson_status.map((lesson, index) => (
                        <tr key={index}>
                          <td className="topicCell">{lesson.topic}</td>
                          <td className="actionCell">{lesson.action}</td>
                          <td className="statusCell">{lesson.status}</td>
                          <td className="logCell">
                            {studentId +
                              "_" +
                              selectedDate +
                              "_topic" +
                              index in
                            chatLog ? (
                              <button
                                className={
                                  selectedLessonStatusIndex === index &&
                                  isModalOpen
                                    ? "selected"
                                    : "chatLogDate"
                                }
                                onClick={() =>
                                  handleLessonStatusClick(selectedDate, index)
                                }
                              >
                                View
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {logSummary ? (
                    <div className="logSummary">
                      <strong>Log Summary:</strong> {logSummary}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div>Select a lesson status for details.</div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="noDataMessage">Type in student ID for informations</div>
      )}
      <Modal show={isModalOpen} onClose={closeModal}>
        <div>
          <h2>Lesson Log for {selectedLessonLogSession}</h2>
          <div className="modalContent">
            <table className="logTable">
              <thead>
                <tr>
                  <th>AI</th>
                  <th>Human</th>
                </tr>
              </thead>
              <tbody>
                {selectedLessonLogSession &&
                  chatLog &&
                  chatLog[selectedLessonLogSession].map((log, index) => (
                    <tr key={index}>
                      <td className="tableCellAI">{log.ai}</td>
                      <td className="tableCell">{log.human}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SearchPage;
