import React from "react";
import { Routes, Route } from "react-router";
import {
  StudentPage,
  TeacherPage,
  LoginPage,
  ParentPage,
  NotFoundPage,
  TChatPage,
  SChatPage,
  PronouncePage,
  PracticePage,
  AssessPage,
  StudyPlanPage,
  PreNotesPage,
  LessonPlanPage,
  NotesChatPage,
  MathChatPage,
  HomePage,
  MainPage,
  ProfilePage,
  HandbookCheckPage,
  AdminPage,
  PreAssessment,
} from "./pages/page-routes";

export const Rroutes = () => {
  return (
    // <Suspense>
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/student" element={<StudentPage />} />
      <Route path="/teacher" element={<TeacherPage />} />
      <Route path="/tchat" element={<TChatPage />} />
      <Route path="/parent" element={<ParentPage />} />
      <Route path="/schat" element={<SChatPage />} />
      <Route path="/pronounce" element={<PronouncePage />} />
      <Route path="/practice" element={<PracticePage />} />
      <Route path="/assess" element={<AssessPage />} />
      <Route path="/studyplan" element={<StudyPlanPage />} />
      <Route path="/lessonplan" element={<LessonPlanPage />} />
      <Route path="/noteschat" element={<NotesChatPage />} />
      <Route path="/prenotes" element={<PreNotesPage />} />
      <Route path="/mathchat" element={<MathChatPage />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/main" element={<MainPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/handbook" element={<HandbookCheckPage />} />
      <Route path="/admin" element={<AdminPage />} />
      <Route path="/preassessment" element={<PreAssessment />} />
      <Route path="*" element={<LoginPage />} />
    </Routes>
  );
};

export default Rroutes;
