import React, { useState } from "react";
import styled from "styled-components";
// import { ReactMic, ReactMicStopEvent } from "react-mic";
import convertToWav from "audiobuffer-to-wav";
import "./Pronounce.css";

type ChatMessageProps = {
  isUser?: boolean;
  children: React.ReactNode;
};

const ChatMessage = styled.div<ChatMessageProps>`
  text-align: ${({ isUser }) => (isUser ? "left" : "right")};
`;

interface ChatInterfaceProps {
  initialChat: string[];
}

interface ChatMessage {
  sender: "user" | "bot";
  content: string | React.ReactNode;
}

interface BotResponse {
  details: Record<string, Detail>;
  score: Score;
}

interface Detail {
  word: string;
  accuracy_score: number;
  error_type: string;
}

interface Score {
  accuracy_score: number;
  prosody_score: number;
  completeness_score: number;
  fluency_score: number;
}

// Replace with your actual API call
const ChatInterfacePronounce: React.FC<ChatInterfaceProps> = ({
  initialChat,
}) => {
  const [chat, setChat] = useState<ChatMessage[]>(() => {
    return initialChat.map((str) => ({ sender: "user", content: str }));
  });

  const sendMessageToBot = async (message: string | FormData) => {
    try {
      let response;
      let botResponse: string | BotResponse;

      if (typeof message === "string") {
        response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/api/chatbot/test`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ user_input: message }),
          }
        );
      } else if (message instanceof FormData) {
        response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/api/content-creation/speaking`,
          {
            method: "POST",
            body: message,
          }
        );
      }

      if (!response) {
        throw new Error("Server responded with an error.");
      }

      const data = await response.json();
      if (data.payload && data.payload.model_output) {
        botResponse = data.payload.model_output;
      } else {
        botResponse = "Message received.";
      }

      const botResponseContent =
        typeof botResponse === "string"
          ? botResponse
          : renderBotResponse(botResponse);

      // Update chat state with the message and bot response
      setChat((prevChat) => [
        ...prevChat,
        {
          sender: "user",
          content: typeof message === "string" ? message : "Voice Message Sent",
        },
        { sender: "bot", content: botResponseContent }, // Ensure consistency in state type
      ]);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const renderBotResponse = (botResponse: BotResponse): React.ReactNode => {
    return (
      <div>
        {botResponse.details &&
          Object.entries(botResponse.details).map(([word, details]) => (
            <div key={word}>
              <span>Word: {word} </span>
              <span>Accuracy Score: {details.accuracy_score} </span>
              <span>Error type: {details.error_type} </span>
            </div>
          ))}
        {botResponse.score && (
          <div>
            <span>Overall Score: </span> <br />
            <span>Accuracy: {botResponse.score.accuracy_score} </span>
            <span>Prosody: {botResponse.score.prosody_score} </span>
            <span>Completeness: {botResponse.score.completeness_score} </span>
            <span>Fluency: {botResponse.score.fluency_score} </span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="ChatContainer">
      <div className="messagesWrapper">
        {chat.map((msg, index) => {
          const { sender, content } = msg;
          return (
            <div
              key={index}
              className={`chat-message ${
                sender === "user" ? "user-message" : "bot-message"
              }`}
            >
              {/* Wrap all children in a single div or fragment */}
              <div>
                {sender === "user" ? "User: " : "Bot: "}
                {content}
              </div>
            </div>
          );
        })}
      </div>
      {/* The ChatInput component remains at the bottom */}
      <ChatInput onSendMessage={sendMessageToBot} />
    </div>
  );
};

interface ChatInputProps {
  onSendMessage: (message: string | FormData) => void;
}

const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage }) => {
  const [message, setMessage] = useState("");
  const [isRecording, setIsRecording] = useState(false); // Track recording state
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // New state for selected file
  const [attachmentText, setAttachmentText] = useState<string>("Attach");
  const [recordedBlob, setRecordedBlob] = useState<Blob | null>(null);
  const [voiceMessageText, setVoiceMessageText] = useState("");

  const handleStartRecording = () => {
    setIsRecording(true);
  };

  // const handleStopRecording = (recordedData: ReactMicStopEvent) => {
  //   setIsRecording(false);
  //   setRecordedBlob(recordedData.blob);
  // };

  const handleSendVoiceMessage = async () => {
    if (recordedBlob && message.trim()) {
      try {
        // Convert the WebM Blob to an AudioBuffer
        const audioContext = new AudioContext();
        const arrayBuffer = await recordedBlob.arrayBuffer();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

        // Convert the AudioBuffer to a WAV ArrayBuffer
        const wavArrayBuffer = convertToWav(audioBuffer, { float32: true });

        // Convert the WAV ArrayBuffer to a Blob
        const wavBlob = new Blob([wavArrayBuffer], { type: "audio/wav" });

        // Now, send the WAV Blob
        const formData = new FormData();
        formData.append("audio_file", wavBlob, "student-recording.wav");
        formData.append("text_message", message); // Include the text message
        await onSendMessage(formData);
        setRecordedBlob(null); // Reset after sending
        setVoiceMessageText(""); // Clear the voice message text
        setMessage("");
      } catch (error) {
        console.error("Error converting WebM to WAV or sending:", error);
      }
    }
  };

  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleSend = async () => {
    if (message.trim() || selectedFile) {
      try {
        let userPayload: any = { text: message };
        if (selectedFile) {
          const fileBase64 = await fileToBase64(selectedFile);
          const base64Data = fileBase64.split(",")[1];
          userPayload.file = base64Data;
          setSelectedFile(null);
        }
        await onSendMessage(userPayload.text); // Ensure only the text is sent if no file is selected
        setMessage("");
      } catch (error) {
        console.error(
          "Error converting file to base64 or sending message:",
          error
        );
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendVoiceMessage();
    }
  };

  const handleVoiceRecord = () => {
    if (!isRecording) {
      // Start recording logic would go here
      console.log("Voice recording started");
      setIsRecording(true);
    } else {
      // Stop recording and process the recorded audio to text - this is where you'd implement speech recognition
      console.log("Voice recording stopped");
      setIsRecording(false);
      // In a real scenario, you'd convert the recorded audio to text here
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      // Here you could also implement logic to handle the selected file (e.g., upload it)
    }
  };

  const triggerFileSelect = () => {
    document.getElementById("file-input")?.click(); // Trigger file input click
  };

  return (
    <div className="chat-input-container-pronounce">
      <input
        type="text"
        className="input-field"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder="Type a message..."
      />
      {/* Voice input button */}
      {isRecording ? (
        <button className="voice-button" onClick={() => setIsRecording(false)}>
          🎙️Stop Recording
        </button>
      ) : (
        <button className="voice-button" onClick={() => setIsRecording(true)}>
          🎙️Record
        </button>
      )}
      {/* <ReactMic
        record={isRecording}
        className="sound-wave hidden-mic" // Apply the hidden-mic class always
        onStop={handleStopRecording}
        //audioEncoder="pcm" // This line specifies the recording format as WAV
      /> */}
      {recordedBlob && (
        <button
          className="send-voice-button"
          onClick={handleSendVoiceMessage}
          disabled={!message.trim()}
        >
          Send Recording with Text
        </button>
      )}
    </div>
  );
};

export default ChatInterfacePronounce;
export { ChatInput };
