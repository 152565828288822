import React from "react";
import Rroutes from "./routes";

const App: React.FC = () => {
  return (
    <div>
      <Rroutes />
    </div>
  );
};

export default App;
