import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { DotLoader } from "react-spinners";
import "./MainWrite.css";

interface WriteInfoProps {
  topic: string;
  studentId?: string | null;
  sessionID: string;
  highlightIndex: number;
  onSubmitAction: (selectedAnswers: Record<string, string>) => void;
  status: string;
}

interface WriteInfoResponse {
  scenario: string;
  assessment: Record<
    string,
    {
      question: string;
      chinese_translation: string;
      english_translation: string;
    }
  >;
}

const MainWrite: React.FC<WriteInfoProps> = ({
  topic,
  studentId,
  sessionID,
  onSubmitAction,
  highlightIndex,
  status,
}) => {
  const location = useLocation();
  const hasFetchedData = useRef(false);
  const [student_id, setStudentId] = useState<string | null>(null);
  const [writingInfo, setWritingInfo] = useState<WriteInfoResponse | null>(
    null
  );
  const [answers, setAnswers] = useState<Record<string, string>>({});
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastFocusedQuestionId, setLastFocusedQuestionId] = useState<
    string | null
  >(null);

  useEffect(() => {
    // if (!hasFetchedData.current && status === "not_started") {
    console.log(status);
    if (status === "not_started") {
      // console.log("effect")
      const { studentId } = location.state || {};
      setStudentId(studentId);

      fetchLog();
      hasFetchedData.current = true;
    }
    if (status !== "not_started") {
      // console.log("effect")
      const { studentId } = location.state || {};
      setStudentId(studentId);

      fetchLog();
      hasFetchedData.current = true;
    }
  }, []);

  const fetchLog = async () => {
    const { studentId } = location.state || {};
    setStudentId(studentId);
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based in JavaScript
    const date = String(now.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${date}`;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/monitor/lesson-log/${studentId}/${formattedDate}`
      );
      const botResponse = response.data.payload;
      console.log("getHistory");

      // Check if the bot response has the same structure as testData
      if (typeof botResponse === "object" && !Array.isArray(botResponse)) {
        Object.values(botResponse).forEach((record: unknown) => {
          // Type guard to ensure the record has the expected properties
          if (
            typeof record === "object" &&
            record !== null &&
            "action" in record &&
            "log" in record &&
            "topic" in record
          ) {
            const learningRecord = record as {
              action: string;
              log: WriteInfoResponse;
              topic: string;
            };

            if (
              learningRecord.action === "writing" &&
              learningRecord.topic === topic
            ) {
              // Display the log for the record
              setWritingInfo(learningRecord.log);
              // Initialize answers state with empty strings for each question
              const initialAnswers = Object.keys(
                learningRecord.log.assessment
              ).reduce((acc, questionId) => {
                acc[questionId] = "";
                return acc;
              }, {} as Record<string, string>);
              setAnswers(initialAnswers);
            }
          }
        });
      } else {
        console.warn("Unexpected bot response format.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    console.log("write");
    const { studentId } = location.state || {};
    setStudentId(studentId);
    try {
      const requestBody = {
        student_id: studentId,
        topic: topic,
        session_id: sessionID,
        topic_index: highlightIndex.toString(),
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/english-writing`,
        requestBody
      );
      const fetchedWritingInfo = response.data.payload;
      setWritingInfo(fetchedWritingInfo);
      // Initialize answers state with empty strings for each question
      const initialAnswers = Object.keys(fetchedWritingInfo.assessment).reduce(
        (acc, questionId) => {
          acc[questionId] = "";
          return acc;
        },
        {} as Record<string, string>
      );
      setAnswers(initialAnswers);
    } catch (error) {
      console.error("Error fetching reading info:", error);
    } finally {
      setLoading(false);
      hasFetchedData.current = true;
    }
  };

  useEffect(() => {
    const allAnswered = Object.values(answers).every(
      (answer) => answer.trim().split(/\s+/).length >= 2
    );
    setAllQuestionsAnswered(allAnswered);
  }, [answers]);

  const handleAnswerChange = (
    questionId: string,
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: event.target.value,
    }));
  };

  const handleBlur = (questionId: string) => {
    if (lastFocusedQuestionId) {
      console.log(
        `Answer for question ${lastFocusedQuestionId}:`,
        answers[lastFocusedQuestionId]
      );
    }
    setLastFocusedQuestionId(questionId);
  };

  const getSelectedAnswers = () => {
    const selectedAnswers = Object.fromEntries(
      Object.entries(answers).map(([questionId, answer]) => [
        questionId,
        answer,
      ])
    );
    onSubmitAction(selectedAnswers);
  };

  return (
    <>
      <h4>Writing</h4>
      {loading ? (
        // Conditionally render loading message
        <div className="loading-container">
          <p>AIBO忙碌緊...</p>
          <DotLoader color="#21B0ED" />
        </div>
      ) : (
        <>
          {writingInfo && (
            <>
              <p className="readingPara">{writingInfo.scenario}</p>
              {Object.entries(writingInfo.assessment).map(
                ([questionId, questionData]) => (
                  <div key={questionId}>
                    <p className="writingQ">
                      {questionId}. {questionData.question}
                    </p>
                    <textarea
                      className="writingA"
                      placeholder="Enter your answer here..."
                      value={answers[questionId] || ""}
                      onChange={(event) =>
                        handleAnswerChange(questionId, event)
                      }
                      onBlur={() => handleBlur(questionId)}
                    />
                  </div>
                )
              )}
              <button
                className={` ${
                  !allQuestionsAnswered
                    ? "submitButton-disabled"
                    : "submitButton"
                }`}
                disabled={!allQuestionsAnswered}
                onClick={() => getSelectedAnswers()}
              >
                Submit Answers
              </button>
            </>
          )}
        </>
      )}
    </>
  );
};

export default MainWrite;
