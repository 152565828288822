import React, { useState } from 'react';
import styled from 'styled-components';
import StudentInformation from '../../components/InfoTab/InfoTab';
import ChatHistory from '../../components/MathChatHistory/MathChatHistory';
import Documents from '../../components/UpDoc/UpDoc';
import ChatInterface from '../../components/MathChat/MathChat';

const Container = styled.div`
  display: flex;
  height: 100vh;
  overflow-y: auto; // Enable vertical scrolling if content overflows
`;

const InfoSection = styled.div`
  width: 30%;
  border-right: 1px solid #ccc;
  padding: 20px;
`;

const ChatSection = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
`;

const MathChatPage: React.FC = () => {
  const [chatHistory, setChatHistory] = useState<string[]>([]);
  const [currentChat, setCurrentChat] = useState<string[]>([]);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [student_id, setStudentId] = useState<string | null>(null);

  const handleSendMessage = (message: string) => {
    setCurrentChat([...currentChat, message]);
    setChatHistory([...chatHistory, message]);
    setCurrentChat(prevChat => [...prevChat, `You: ${message}`]);
  };

  const handleViewHistory = (index: string) => {
    const numericIndex = parseInt(index, 10);
    if (!isNaN(numericIndex)) {
      setCurrentChat([chatHistory[numericIndex]]);
    } else {
      console.error('Invalid index:', index);
    }
  };

  const handleSelectId = (id: string) => {
    setSelectedId(id);
  };
  

  return (
    <Container>
      <InfoSection>
        <StudentInformation studentId={student_id} />
        <ChatHistory onSelectId={handleSelectId} />
        {selectedId}
        <Documents studentId={student_id}/>
      </InfoSection>
      <ChatSection>
        <ChatInterface selectedId={selectedId} initialChat={currentChat} />
      </ChatSection>
    </Container>
  );
};

export default MathChatPage;