import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios"; // Make sure to install axios if you haven't already
import { useLocation } from "react-router-dom";
import "./HomeworkTab.css";
import Modal from "../../components/Modal/Modal"; // Import the Modal component
import { DotLoader } from "react-spinners";

const Box = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
`;

interface HomeworkTabProps {
  studentId?: string | null;
  homeworkJustSubmitted: string;
  onAction: (
    subject: string,
    homeworkName: string,
    homeworkIndex: string
  ) => void;
}

interface HomeworkInfoResponse {
  due_date: string;
  name: string;
  subject: string;
  seek_assistance: boolean;
  complete: boolean;
  homework_index: string;
}

interface HomeworkCheckResponse {
  comment: string;
  status: string;
}

const HomeworkInformation: React.FC<HomeworkTabProps> = ({
  studentId,
  homeworkJustSubmitted,
  onAction,
}) => {
  const [HomeworkInfo, setHomeworkInfo] = useState<HomeworkInfoResponse[]>([]);
  const [student_id, setStudentId] = useState<string | null>(studentId || null);
  const location = useLocation();
  const hasFetchedData = useRef(false);

  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [loading, setLoading] = useState(false);
  const [selectedHomeworkFile, setSelectedHomeworkFile] = useState<File | null>(
    null
  ); // New state for selected file
  const [multipleSelectedHomeworkFile, setMultipleSelectedHomeworkFile] =
    useState<FileList | null>(null);
  const [currentSubject, setCurrentSubject] = useState<string>("");
  const [currentHomework, setCurrentHomework] = useState<string>("");
  const [currentHomeworkIndex, setCurrentHomeworkIndex] = useState<string>("");
  const [attachedHomework, setAttachedHomework] = useState(false);
  const [attachmentHomeworkText, setAttachmentHomeworkText] =
    useState<string>("Attach");
  const [homeworkResponse, setHomeworkResponse] =
    useState<HomeworkCheckResponse>();
  const [homeworkJustChecked, setHomeworkJustChecked] = useState<string>("");

  //get the current date in the format of yyyy-mm-dd
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();
  const formattedDate = `${year}-${month < 10 ? `0${month}` : month}-${
    day < 10 ? `0${day}` : day
  }`;

  useEffect(() => {
    const { studentId } = location.state || {};
    setStudentId(studentId);
    // console.log(hasFetchedData.current);
    if (!hasFetchedData.current && studentId) {
      fetchData(studentId);
      hasFetchedData.current = true;
    }
  }, []);

  const fetchData = async (studentId: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/homework/${studentId}`
      );
      // console.log("hw", response.data);
      setHomeworkInfo(response.data.payload);
      // console.log(response.data.payload);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleHomeworkButtonClick = async (
    subject: string,
    homeworkName: string,
    homeworkIndex: string
  ) => {
    onAction(subject, homeworkName, homeworkIndex);
  };

  // Separate homework by subjects
  const subjects: string[] = [];
  HomeworkInfo.forEach((homework) => {
    if (!subjects.includes(homework.subject)) {
      subjects.push(homework.subject);
    }
  });

  const handleSubmitFile = () => {
    setLoading(true);
    if (selectedHomeworkFile) {
      const { studentId } = location.state || {};
      //   console.log(studentId);
      const formData = new FormData();
      formData.append("image_files", selectedHomeworkFile);
      formData.append("student_id", studentId);
      formData.append("subject", currentSubject);
      formData.append("homework_index", currentHomeworkIndex);
      UploadHomework(formData);
    } else if (multipleSelectedHomeworkFile) {
      const { studentId } = location.state || {};
      const formData = new FormData();
      for (let i = 0; i < multipleSelectedHomeworkFile.length; i++) {
        formData.append("image_files", multipleSelectedHomeworkFile[i]);
      }
      formData.append("student_id", studentId);
      formData.append("subject", currentSubject);
      formData.append("homework_index", currentHomeworkIndex);
      UploadHomework(formData);
    }
  };

  const UploadHomework = async (formData: FormData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/homework-check`,
        formData
      );
      setHomeworkResponse(response.data.payload);
      if (response.data.payload.status !== "finished") {
        setSelectedHomeworkFile(null);
        setMultipleSelectedHomeworkFile(null);
        setAttachedHomework(false);
      } else {
        setHomeworkJustChecked(currentHomeworkIndex);
        closeModal();
      }

      // setHandbookQ(response.data.payload);
    } catch (error) {
      console.error("Error updating english progress:", error);
    } finally {
      setLoading(false);
    }
    // setUploadedHandbook(true);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedHomeworkFile(null);
    setMultipleSelectedHomeworkFile(null);
    setAttachedHomework(false);
    setHomeworkResponse({ comment: "", status: "" });
  };

  const triggerFileSelect = () => {
    document.getElementById("hw-check-file-input")?.click(); // Trigger file input click
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAttachedHomework(false);
    setSelectedHomeworkFile(null);
    setMultipleSelectedHomeworkFile(null);
    setHomeworkResponse({ comment: "", status: "" });
    if (event.target.files && event.target.files.length > 0) {
      // const { studentId } = location.state || {};
      if (event.target.files.length > 1) {
        setMultipleSelectedHomeworkFile(event.target.files);
        setAttachedHomework(true);
      } else {
        setSelectedHomeworkFile(event.target.files[0]);
        setAttachedHomework(true);
      }
    }
  };

  const handleCheckButtonClick = (
    subject: string,
    homework_name: string,
    homework_index: string
  ) => {
    // Send API request to check the homework
    setCurrentHomework(homework_name);
    setCurrentHomeworkIndex(homework_index);
    setCurrentSubject(subject);
    openModal();
  };

  useEffect(() => {
    if (selectedHomeworkFile) {
      setAttachmentHomeworkText(selectedHomeworkFile.name);
    } else if (multipleSelectedHomeworkFile) {
      setAttachmentHomeworkText(
        `${multipleSelectedHomeworkFile.length} files selected`
      );
    } else {
      setAttachmentHomeworkText("Upload files");
    }
  }, [selectedHomeworkFile, multipleSelectedHomeworkFile]);

  useEffect(() => {
    // console.log(homeworkJustSubmitted);
    setHomeworkInfo((prevHomeworkInfo) =>
      prevHomeworkInfo.map((homework) =>
        homework.homework_index === homeworkJustSubmitted
          ? { ...homework, seek_assistance: true }
          : homework
      )
    );
  }, [homeworkJustSubmitted]);

  useEffect(() => {
    // console.log(homeworkJustChecked);
    setHomeworkInfo((prevHomeworkInfo) =>
      prevHomeworkInfo.map((homework) =>
        homework.homework_index === homeworkJustChecked
          ? { ...homework, complete: true }
          : homework
      )
    );
  }, [homeworkJustChecked]);

  return (
    <>
      <Box className="homeworkContainer">
        <h2 className="homeworkTitle">Homework</h2>
        {subjects.map((subject) => (
          <React.Fragment key={subject}>
            <div className="subjectTitle">{subject}</div>
            <div className="homeworkRows">
              {HomeworkInfo.filter(
                (homework) => homework.subject === subject
              ).map((homework) => (
                <div key={homework.name} className="eachHomework">
                  <button
                    className={` ${
                      homework.complete
                        ? "disabledCheckButton"
                        : "homeworkButton"
                    }`}
                    disabled={homework.complete}
                    onClick={() =>
                      handleHomeworkButtonClick(
                        subject,
                        homework.name,
                        homework.homework_index
                      )
                    }
                  >
                    {homework.name}
                  </button>
                  {homework.seek_assistance ? (
                    <>
                      {homework.complete ? (
                        <div className="checkIcon">✅</div>
                      ) : (
                        <button
                          className={`checkButton ${
                            homework.complete ? "disabledCheckButton" : ""
                          }`}
                          disabled={homework.complete}
                          onClick={() => {
                            handleCheckButtonClick(
                              subject,
                              homework.name,
                              homework.homework_index
                            );
                          }}
                        >
                          Check
                        </button>
                      )}
                    </>
                  ) : null}
                </div>
              ))}
            </div>
          </React.Fragment>
        ))}
      </Box>
      <Modal show={isModalOpen} onClose={closeModal}>
        <h2>Upload {currentHomework} images for checking</h2>
        <button
          className="attachment-button-handbook"
          onClick={triggerFileSelect}
        >
          📤{attachmentHomeworkText}
        </button>
        <input
          id="hw-check-file-input"
          type="file"
          style={{ display: "none" }}
          onChange={handleFileSelect}
          multiple
        />
        {attachedHomework === true ? (
          loading === true ? (
            // Show loading button
            <button
              className="submit-button-handbook"
              style={{ backgroundColor: "green" }}
            >
              Loading
              <DotLoader />
            </button>
          ) : (
            // Show submit button
            <button
              className="submit-button-handbook"
              onClick={handleSubmitFile}
              style={{ backgroundColor: "green" }}
            >
              Submit
            </button>
          )
        ) : (
          // Show disabled button
          <button
            className="submit-button-handbook"
            style={{
              backgroundColor: "grey",
              color: "rgba(255, 255, 255, 0.7)",
            }}
          >
            Submit
          </button>
        )}
        {homeworkResponse?.comment ? (
          <>
            <div className="feedbackMessage">
              Feedback: {homeworkResponse?.comment}
            </div>
            <div className="tryAgainMessage">加油！再嚟過！</div>
          </>
        ) : null}
      </Modal>
    </>
  );
};

export default HomeworkInformation;
