import React, { forwardRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import "../Tooltip/Tooltip.css";
import { marked } from "marked";
import { ClipLoader } from "react-spinners";
import { HiMiniSpeakerWave } from "react-icons/hi2";

interface TooltipProps {
  word: string;
  explanation: string; // Allow explanation to be null
  visible: boolean;
  position: { top: number; left: number };
}

const TooltipContainer = styled.div<{
  visible: boolean;
  top: number;
  left: number;
}>`
  position: absolute; // Use absolute positioning
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  z-index: 1000;
`;

const speakText = (text: string) => {
  const synth = window.speechSynthesis;
  const utterance = new SpeechSynthesisUtterance(text);
  synth.speak(utterance);
};

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  ({ word, explanation, visible, position }, ref) => {
    return ReactDOM.createPortal(
      <TooltipContainer
        ref={ref}
        visible={visible}
        top={position.top}
        left={position.left}
      >
        {explanation !== "" ? (
          <>
            <div className="wordTitle">
              <strong>
                {word.replace(/[,.]/g, "").charAt(0).toUpperCase() +
                  word.slice(1)}
              </strong>
              <HiMiniSpeakerWave
                className="speakerIcon"
                onClick={() => speakText(word)}
              />
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: marked(explanation),
              }}
            />
          </>
        ) : (
          <ClipLoader color="#fff" />
        )}
      </TooltipContainer>,
      document.body // Render the tooltip in the body to avoid affecting the layout
    );
  }
);

export default Tooltip;
