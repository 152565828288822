import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./component/style.css";
import "../../App.css";
import axios from "axios";
import ChatInterface from "../../components/MainChatInterface/MainChatInterface";
import StudentInformation from "../../components/InfoTab/InfoTab";
import HomeworkInformation from "../../components/HomeworkTab/HomeworkTab";
import LessonSchedule from "../../components/MainSchedule/MainSchedule";
import MainRead from "../../components/MainRead/MainRead";
import MainReview from "../../components/MainReview/MainReview";
import MainWrite from "../../components/MainWrite/MainWrite";
import { Navigate } from "react-router-dom";
import MainListen from "../../components/MainListen/MainListen";
import MainSpeak from "../../components/MainSpeak/MainSpeak";
import MainHomework from "../../components/MainHomework/MainHomework";
import Modal from "../../components/Modal/Modal"; // Import the Modal component
import { DotLoader } from "react-spinners";

interface StudentInfoResponse {
  name: string;
  age: string;
  grade: string;
  school: string;
}

interface chapterInfoResponse {
  [subject: string]: string[];
}

const MainPage: React.FC = () => {
  const [currentChat, setCurrentChat] = useState<string[]>([]);
  const [student_id, setStudentId] = useState<string | null>(null);
  const [highlightIndex, setHighlightIndex] = useState(0);
  const [topic, setTopic] = useState("");
  const [action, setAction] = useState("");
  const [lastTopic, setLastTopic] = useState(0);
  const [sessionID, setSessionID] = useState("");
  const [status, setStatus] = useState("");
  const [topicNum, setTopicNum] = useState(0);
  const [studentInfo, setStudentInfo] = useState<StudentInfoResponse[]>([]);
  const [answers, setAnswers] = useState<Record<string, string>>({});
  const [chapterInfo, setChapterInfo] = useState<chapterInfoResponse>({});
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [loading, setLoading] = useState(false);
  const [tempIndex, setTempIndex] = useState<number | null>(null);
  const [idleTime, setIdleTime] = useState(0);
  const [passiveIdleTime, setPassiveIdleTime] = useState(0);
  const [userMoved, setUserMoved] = useState(false);
  const [hasLoggedIdle10, sethasLoggedIdle10] = useState(false);
  const [hasLoggedIdle3, sethasLoggedIdle3] = useState(false);
  const [hasLoggedIdle1, sethasLoggedIdle1] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentHomework, setCurrentHomework] = useState<string>("");
  const [currentHomeworkIndex, setCurrentHomeworkIndex] = useState<string>("");
  const [currentSubject, setCurrentSubject] = useState<string>("");
  const [selectedHomeworkFile, setSelectedHomeworkFile] = useState<File | null>(
    null
  ); // New state for selected file
  const [multipleSelectedHomeworkFile, setMultipleSelectedHomeworkFile] =
    useState<FileList | null>(null);
  const [attachedHomework, setAttachedHomework] = useState(false);
  const [attachmentHomeworkText, setAttachmentHomeworkText] =
    useState<string>("Attach");
  const [homeworkResponse, setHomeworkResponse] = useState<string>("");
  const [homeworkIndexJustSubmitted, setHomeworkIndexJustSubmitted] =
    useState<string>("");

  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  const [teacherMentionedEnd, setTeacherMentionedEnd] = useState(false);

  const [idleResponse, setIdleResponse] = useState<string>("");

  useEffect(() => {
    const { studentId } = location.state || {};
    setStudentId(studentId);
    fetchData();
  }, []);

  const fetchData = async () => {
    const { studentId } = location.state || {};
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/students/${studentId}`
      );
      setStudentInfo([response.data]);
      // console.log([response.data]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    try {
      const response1 = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/progress-review/weekly-objective-plan/${studentId}`
      );
      setChapterInfo(response1.data.payload);
      // console.log(response1.data.payload);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateClassStatus = async (studentId: string) => {
    try {
      // Send API request
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/monitor/class-status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            student_id: studentId,
            status: "out_of_class",
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to updating class status");
      }
    } catch (error) {
      console.error("Error updating class status:", error);
    }
  };

  const updateLessonStatus = async (studentId: string, topic_index: number) => {
    try {
      // Send API request
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/monitor/lesson-status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            student_id: studentId,
            topic_index: topic_index,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to updating lesson status");
      }
    } catch (error) {
      console.error("Error updating lesson status:", error);
    }
  };

  const nextButtonClick = () => {
    if (tempIndex !== null) {
      setHighlightIndex(tempIndex);
      setTempIndex(null);
    } else {
      const { studentId } = location.state || {};
      setHighlightIndex((prevIndex) => prevIndex + 1);
      updateLessonStatus(studentId, highlightIndex);
    }
  };

  const prevButtonClick = () => {
    setHighlightIndex((prevIndex) => prevIndex - 1);
  };

  const finishButtonClick = () => {
    try {
      const { studentId } = location.state || {};
      setStudentId(studentId);
      const requestBody = {
        student_id: studentId,
      };
      // console.log(student_id);
      const response = axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/english-finish-progress`,
        requestBody
      );
      updateClassStatus(studentId);
      updateLessonStatus(studentId, highlightIndex);
      navigate("/student", { state: { studentId } });
      // console.log(response.data.payload);
    } catch (error) {
      console.error("Error updating english progress:", error);
    }
  };

  const onAction = async (
    subject: string,
    homework_name: string,
    homework_index: string
  ) => {
    // console.log(homeworkName, subject);
    setCurrentHomework(homework_name);
    setCurrentHomeworkIndex(homework_index);
    setCurrentSubject(subject);
    openModal();
  };

  const handleLearningAction = (
    newTopic: string,
    newAction: string,
    lastTopicNum: number,
    status: string
  ) => {
    if (newTopic !== topic) {
      setTopicNum(topicNum + 1);
      // console.log("new topic");
    }
    setTopic(newTopic);
    // console.log(newTopic);
    setAction(newAction);
    setLastTopic(lastTopicNum);
    setStatus(status);
  };

  const handleProgressAction = (
    newTopic: string,
    newAction: string,
    statusIndex: number
  ) => {
    // console.log(newAction);
    setTopic(newTopic);
    setAction(newAction);
    setHighlightIndex(statusIndex);
    setStatus("in_progress");
  };

  const handleSessionID = (newSessionID: string) => {
    setSessionID(newSessionID);
  };

  const handleSubmitReading = (selectedAnswers: Record<string, string>) => {
    setAnswers(selectedAnswers);
    nextButtonClick();
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedHomeworkFile(null);
    setMultipleSelectedHomeworkFile(null);
    setAttachedHomework(false);
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const triggerFileSelect = () => {
    document.getElementById("hw-file-input")?.click(); // Trigger file input click
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAttachedHomework(false);
    setSelectedHomeworkFile(null);
    setMultipleSelectedHomeworkFile(null);
    if (event.target.files && event.target.files.length > 0) {
      // const { studentId } = location.state || {};
      if (event.target.files.length > 1) {
        setMultipleSelectedHomeworkFile(event.target.files);
        setAttachedHomework(true);
      } else {
        setSelectedHomeworkFile(event.target.files[0]);
        setAttachedHomework(true);
      }
    }
  };

  useEffect(() => {
    if (selectedHomeworkFile) {
      setAttachmentHomeworkText(selectedHomeworkFile.name);
    } else if (multipleSelectedHomeworkFile) {
      setAttachmentHomeworkText(
        `${multipleSelectedHomeworkFile.length} files selected`
      );
    } else {
      setAttachmentHomeworkText("Upload files");
    }
  }, [selectedHomeworkFile, multipleSelectedHomeworkFile]);

  const UploadHomework = async (formData: FormData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/homework`,
        formData
      );
      // console.log(response.data.payload);
      if (action !== "homework") {
        setTempIndex(highlightIndex);
        setHighlightIndex(0);
      }
      setHomeworkResponse(response.data.payload);
      setHomeworkIndexJustSubmitted(currentHomeworkIndex);
      closeModal();
      // setHandbookQ(response.data.payload);
    } catch (error) {
      console.error("Error updating english progress:", error);
    } finally {
      setLoading(false);
    }
    // setUploadedHandbook(true);
  };

  const handleSubmitFile = () => {
    setLoading(true);
    if (selectedHomeworkFile) {
      const { studentId } = location.state || {};
      //   console.log(studentId);
      const formData = new FormData();
      formData.append("image_files", selectedHomeworkFile);
      formData.append("student_id", studentId);
      formData.append("subject", currentSubject);
      formData.append("homework_index", currentHomeworkIndex);
      UploadHomework(formData);
    } else if (multipleSelectedHomeworkFile) {
      const { studentId } = location.state || {};
      const formData = new FormData();
      for (let i = 0; i < multipleSelectedHomeworkFile.length; i++) {
        formData.append("image_files", multipleSelectedHomeworkFile[i]);
      }
      formData.append("student_id", studentId);
      formData.append("subject", currentSubject);
      formData.append("homework_index", currentHomeworkIndex);
      UploadHomework(formData);
    }
  };

  const handleUserActivity = () => {
    setUserMoved(true);
    setIdleTime(0);
    setPassiveIdleTime(0);
  };

  useEffect(() => {
    try {
      const { studentId } = location.state || {};
      setStudentId(studentId);
      const requestBody = {
        student_id: studentId,
        attention_status: "normal",
      };
      // console.log(student_id);
      const response = axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/monitor/attention-status`,
        requestBody
      );
      // console.log(response.data.payload);
    } catch (error) {
      console.error("Error sending normal status:", error);
    }

    const idleTimer = setInterval(() => {
      setIdleTime((prevIdleTime) => prevIdleTime + 1);
      setPassiveIdleTime((prevPassiveIdleTime) => prevPassiveIdleTime + 1);
      setUserMoved(false);
    }, 1000); // Increment idle time every second

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keypress", handleUserActivity);
    window.addEventListener("touchstart", handleUserActivity); // Add touchstart event
    window.addEventListener("touchmove", handleUserActivity); // Add touchmove event

    return () => {
      clearInterval(idleTimer);
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keypress", handleUserActivity);
      window.removeEventListener("touchstart", handleUserActivity); // Remove touchstart event
      window.removeEventListener("touchmove", handleUserActivity); // Remove touchmove event
    };
  }, []);

  useEffect(() => {
    if (idleTime >= 600 && !hasLoggedIdle10) {
      console.log("User idle time exceeded 10 minutes");
      try {
        const { studentId } = location.state || {};
        setStudentId(studentId);
        const requestBody = {
          student_id: studentId,
          attention_status: "idle_10",
        };
        // console.log(student_id);
        const response = axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/api/tools/monitor/attention-status`,
          requestBody
        );
        // console.log(response.data.payload);
      } catch (error) {
        console.error("Error sending idle status:", error);
      }
      sethasLoggedIdle10(true);
    }
    if (idleTime >= 180 && !hasLoggedIdle3) {
      console.log("User idle time exceeded 3 minutes");
      try {
        const { studentId } = location.state || {};
        setStudentId(studentId);
        const requestBody = {
          student_id: studentId,
          attention_status: "idle_3",
        };
        // console.log(student_id);
        const response = axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/api/tools/monitor/attention-status`,
          requestBody
        );
        // console.log(response.data.payload);
      } catch (error) {
        console.error("Error sending idle status:", error);
      }
      sethasLoggedIdle3(true);
    }
    if (passiveIdleTime >= 60) {
      console.log("User idle time exceeded 1 minute");
      try {
        sendIdleOneMinute();
      } catch (error) {
        console.error("Error sending idle status:", error);
      }
      setPassiveIdleTime(0);
      sethasLoggedIdle1(true);
    }
    if (userMoved && hasLoggedIdle1) {
      console.log("User moved after being idle for over 1 minute");
      sethasLoggedIdle1(false); // Reset the log state
    }
    if (userMoved && hasLoggedIdle3) {
      console.log("User moved after being idle for over 3 minutes");
      try {
        const { studentId } = location.state || {};
        setStudentId(studentId);
        const requestBody = {
          student_id: studentId,
          attention_status: "normal",
        };
        // console.log(student_id);
        const response = axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/api/tools/monitor/attention-status`,
          requestBody
        );
        // console.log(response.data.payload);
      } catch (error) {
        console.error("Error sending normal status:", error);
      }
      sethasLoggedIdle10(false);
      sethasLoggedIdle3(false); // Reset the log state
    }
  }, [idleTime, userMoved, hasLoggedIdle1, hasLoggedIdle3, hasLoggedIdle10]);

  const sendIdleOneMinute = async () => {
    const { studentId } = location.state || {};
    setStudentId(studentId);
    const requestBody = {
      student_id: studentId,
      type: "idle",
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/api/agent-passive-input`,
      requestBody
    );
    // console.log(response);
    setIdleResponse(response.data.payload);
  };

  const sendOutOfTab = async () => {
    const { studentId } = location.state || {};
    setStudentId(studentId);
    const requestBody = {
      student_id: studentId,
      type: "out_of_tab",
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/api/agent-passive-input`,
      requestBody
    );
    // console.log(response);
    setIdleResponse(response.data.payload);
  };

  // Add visibilitychange event listener
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        console.log("User has left the tab");
        try {
          const { studentId } = location.state || {};
          setStudentId(studentId);
          const requestBody = {
            student_id: studentId,
            attention_status: "out_of_focus",
          };
          const response = axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/api/tools/monitor/attention-status`,
            requestBody
          );
          sendOutOfTab();
        } catch (error) {
          console.error("Error sending out_of_focus status:", error);
        }
      } else {
        console.log("User has returned to the tab");
        try {
          const { studentId } = location.state || {};
          setStudentId(studentId);
          const requestBody = {
            student_id: studentId,
            attention_status: "normal",
          };
          const response = axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/api/tools/monitor/attention-status`,
            requestBody
          );
        } catch (error) {
          console.error("Error sending normal status:", error);
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const handleNagivateProfile = () => {
    const { studentId } = location.state || {};
    navigate("/profile", { state: { studentId: studentId } });
  };

  const handleNagivateHome = () => {
    const { studentId } = location.state || {};
    navigate("/student", { state: { studentId: studentId } });
  };

  const setAudioPlaying = (audioPlaying: boolean) => {
    // console.log(audioPlaying);
    if (audioPlaying) {
      setIsAudioPlaying(true);
    } else {
      setIsAudioPlaying(false);
    }
  };

  const setEnd = (end: boolean) => {
    if (end) {
      setTeacherMentionedEnd(true);
    }
  };

  return (
    <div className="page-container">
      <div className="e-learning-page">
        <header className="header">
          <div className="header-left">
            <div className="title">
              <strong>Student UI</strong>
            </div>
          </div>
          {studentInfo.map((student, index) => (
            <div key={index} className="header-mid">
              <p>
                <strong>SID:</strong> {student_id} |<strong> Name:</strong>{" "}
                {student.name} | <strong>School:</strong> {student.school}
              </p>
            </div>
          ))}
          <div className="header-right">
            <button className="header-button" onClick={handleNagivateHome}>
              Home
            </button>
            <button className="header-button" onClick={handleNagivateProfile}>
              Profile
            </button>
          </div>
        </header>
        <div className="main-content">
          <div className="tutor-wrapper">
            <div className="top-main-content">
              {action === "homework" ? (
                <div className="sidebar">
                  <div className="homework">
                    <HomeworkInformation
                      studentId={student_id}
                      homeworkJustSubmitted={homeworkIndexJustSubmitted}
                      onAction={onAction}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div
                className={`interactiveNchatbot ${
                  action === "homework" ? "homework-width" : "no-homework-width"
                }`}
              >
                {action !== "learning" && (
                  <div className="interactive-area">
                    {Object.entries(chapterInfo).map(
                      ([subject, chapters], index) => (
                        <div key={index}>
                          {chapters.map((chapter, chapterIndex) => (
                            <p key={chapterIndex} className="chapter-info">
                              <strong>Current Lesson:</strong>{" "}
                              <span style={{ textTransform: "capitalize" }}>
                                {subject}
                              </span>{" "}
                              -{" "}
                              <span style={{ textTransform: "capitalize" }}>
                                {chapter}
                              </span>
                            </p>
                          ))}
                        </div>
                      )
                    )}
                    <div
                      className="interactive-area-content"
                      style={
                        action === "learning" ? { overflowY: "hidden" } : {}
                      }
                    >
                      {/* Conditionally render based on action */}
                      {action === "reading" ? (
                        <MainRead
                          topic={topic}
                          studentId={student_id}
                          sessionID={sessionID}
                          onSubmitAction={handleSubmitReading}
                          highlightIndex={highlightIndex}
                          status={status}
                        />
                      ) : action === "review" ? (
                        <MainReview
                          answers={answers}
                          topic={topic}
                          sessionID={sessionID}
                          highlightIndex={highlightIndex}
                          studentId={student_id}
                          status={status}
                        />
                      ) : action === "speaking" ? (
                        <MainSpeak
                          topic={topic}
                          studentId={student_id}
                          sessionID={sessionID}
                          onSubmitAction={nextButtonClick}
                          highlightIndex={highlightIndex}
                          status={status}
                        />
                      ) : action === "writing" ? (
                        <MainWrite
                          topic={topic}
                          studentId={student_id}
                          sessionID={sessionID}
                          onSubmitAction={handleSubmitReading}
                          highlightIndex={highlightIndex}
                          status={status}
                        />
                      ) : action === "learning" ? (
                        <ChatInterface
                          initialChat={currentChat}
                          topic={topic}
                          action={action}
                          highlightIndex={highlightIndex}
                          onSessionID={handleSessionID}
                          topicNum={topicNum}
                          status={status}
                          setAudioPlaying={setAudioPlaying}
                          setEnd={setEnd}
                          idleResponse={idleResponse}
                          setPassiveIdleTime={setPassiveIdleTime}
                        />
                      ) : // <div>Default Content</div>
                      action === "listening" ? (
                        // <ChatInterface initialChat={currentChat} topic={topic} action={action} highlightIndex={highlightIndex} onSessionID={handleSessionID}/>
                        <MainListen
                          topic={topic}
                          studentId={student_id}
                          sessionID={sessionID}
                          onSubmitAction={handleSubmitReading}
                          highlightIndex={highlightIndex}
                          status={status}
                        />
                      ) : action === "homework" ? (
                        <MainHomework homeworkResponse={homeworkResponse} />
                      ) : (
                        // Default component or fallback content
                        <div>Default Content</div>
                      )}
                    </div>
                  </div>
                )}
                <div
                  className={`chat-bot-container ${
                    action === "learning" ? "learning-chatbot" : ""
                  }`}
                >
                  <div className="chat-bot">
                    <ChatInterface
                      initialChat={currentChat}
                      topic={topic}
                      action={action}
                      highlightIndex={highlightIndex}
                      onSessionID={handleSessionID}
                      topicNum={topicNum}
                      status={status}
                      setAudioPlaying={setAudioPlaying}
                      setEnd={setEnd}
                      idleResponse={idleResponse}
                      setPassiveIdleTime={setPassiveIdleTime}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-schedule">
          {/* {action === "review" ? (
            <button className="nav-button" onClick={prevButtonClick}>
              <strong>Previous stage{"<-"}</strong>
            </button>
          ) : (
            <button
              className="nav-button"
              disabled={true}
              style={{
                color: "#49E377",
              }}
            >
              Previous stage
            </button>
          )} */}
          <LessonSchedule
            highlightIndex={highlightIndex}
            onLearningAction={handleLearningAction}
            onProgressAction={handleProgressAction}
            studentId={student_id}
          />
          {highlightIndex === lastTopic - 1 ? (
            <button className="nav-button" onClick={finishButtonClick}>
              Finish
            </button>
          ) : action === "reading" ||
            action === "writing" ||
            action === "listening" ||
            action === "speaking" ? (
            <button
              className="nav-button"
              disabled={true}
              style={{
                color: "#49E377",
              }}
            >
              Next stage
            </button>
          ) : isAudioPlaying ? (
            <button
              className="nav-button"
              disabled={true}
              style={{
                color: "#49E377",
              }}
            >
              Next stage
            </button>
          ) : !teacherMentionedEnd && action === "learning" ? (
            <button
              className="nav-button"
              disabled={true}
              style={{
                color: "#49E377",
              }}
            >
              Next stage
            </button>
          ) : (
            <button className="nav-button" onClick={nextButtonClick}>
              <strong> Next stage {"->"}</strong>
            </button>
          )}
        </div>
      </div>
      <Modal show={isModalOpen} onClose={closeModal}>
        <h2>Upload images for {currentHomework}</h2>
        <button
          className="attachment-button-handbook"
          onClick={triggerFileSelect}
        >
          📤{attachmentHomeworkText}
        </button>
        <input
          id="hw-file-input"
          type="file"
          style={{ display: "none" }}
          onChange={handleFileSelect}
          multiple
        />
        {attachedHomework === true ? (
          loading === true ? (
            // Show loading button
            <button
              className="submit-button-handbook"
              style={{ backgroundColor: "green" }}
            >
              Loading
              <DotLoader />
            </button>
          ) : (
            // Show submit button
            <button
              className="submit-button-handbook"
              onClick={handleSubmitFile}
              style={{ backgroundColor: "green" }}
            >
              Submit
            </button>
          )
        ) : (
          // Show disabled button
          <button
            className="submit-button-handbook"
            style={{
              backgroundColor: "grey",
              color: "rgba(255, 255, 255, 0.7)",
            }}
          >
            Submit
          </button>
        )}
      </Modal>
    </div>
  );
};

export default MainPage;
