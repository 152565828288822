import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "./MainReview.css";
import { DotLoader } from "react-spinners";

interface ReviewInfoProps {
  answers: Record<string, string>;
  topic: string;
  sessionID: string;
  studentId?: string | null;
  highlightIndex: number;
  status: string;
}

interface QuestionReview {
  student_answer: string;
  correct_or_suggested_answer: string;
  analysis: string;
}

interface ReviewInfoResponse {
  score: string;
  questionReviews: { [questionId: string]: QuestionReview };
}

const MainReview: React.FC<ReviewInfoProps> = ({
  answers,
  topic,
  sessionID,
  highlightIndex,
  studentId,
  status,
}) => {
  const location = useLocation();
  const hasFetchedData = useRef(false);
  const [student_id, setStudentId] = useState<string | null>(null);
  const [reviewInfo, setReviewInfo] = useState<ReviewInfoResponse | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!hasFetchedData.current) {
      console.log("effect");
      // setReviewInfo(testData);
      fetchData();
      hasFetchedData.current = true;
      // setReadingInfo(testData)
    }
    if (status !== "not_started") {
      // console.log("effect")
      const { studentId } = location.state || {};
      setStudentId(studentId);
      fetchLog();
      hasFetchedData.current = true;
    }
  }, []);

  const fetchLog = async () => {
    const { studentId } = location.state || {};
    setStudentId(studentId);
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based in JavaScript
    const date = String(now.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${date}`;
    // const formattedDate = `${year}-${month}-21`;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/monitor/lesson-log/${studentId}/${formattedDate}`
      );
      const botResponse = response.data.payload;
      console.log("getHistory");
      // Check if the bot response has the same structure as testData
      if (typeof botResponse === "object" && !Array.isArray(botResponse)) {
        Object.values(botResponse).forEach((record: unknown) => {
          // Type guard to ensure the record has the expected properties
          if (
            typeof record === "object" &&
            record !== null &&
            "action" in record &&
            "log" in record &&
            "topic" in record
          ) {
            const learningRecord = record as {
              action: string;
              log: ReviewInfoResponse;
              topic: string;
            };
            if (
              learningRecord.action === "review" &&
              learningRecord.topic === topic
            ) {
              // Display the log for the record
              setReviewInfo(learningRecord.log);
            }
          }
        });
      } else {
        console.warn("Unexpected bot response format.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    const { studentId } = location.state || {};
    setStudentId(studentId);
    try {
      const requestBody = {
        student_id: studentId,
        topic: topic,
        session_id: sessionID,
        answers: answers,
        topic_index: highlightIndex.toString(),
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/english-review`,
        requestBody
      );
      // console.log(response.data.payload);
      setReviewInfo(response.data.payload);
    } catch (error) {
      console.error("Error fetching review info:", error);
    } finally {
      setLoading(false);
      hasFetchedData.current = true;
    }
  };

  return (
    <>
      <h4>Review</h4>
      {loading ? (
        <>
          <div className="loading-container">
            <p>AIBO忙碌緊.....</p>
            <DotLoader color="#21B0ED" />
          </div>
        </>
      ) : (
        <>
          {reviewInfo && (
            <div>
              {Object.entries(reviewInfo)
                .filter(([key]) => key !== "score")
                .map(([key, value]) => (
                  <div key={key}>
                    <p className="SAns">
                      Q{key}: Your answer - {value.student_answer}
                    </p>
                    <p className="CAns">
                      Correct answer - {value.correct_or_suggested_answer}
                    </p>
                    <p className="analysis">{value.analysis}</p>
                  </div>
                ))}
              <p>Total Score: {reviewInfo.score}</p>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MainReview;
