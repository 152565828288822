import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import "./MathChat.css";
import axios from "axios";
import ChatHistory from "../ChatHistory/ChatHistory";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";

type ChatMessageProps = {
  isUser?: boolean;
  children: React.ReactNode;
};

const ChatMessage = styled.div<ChatMessageProps>`
  text-align: ${({ isUser }) => (isUser ? "right" : "left")};
`;

interface ChatInterfaceProps {
  initialChat: any[];
  selectedId?: string | null; // Add selectedId property
}

interface ChatMessage {
  isUser: boolean;
  message: string | { text: string; latex: string };
}

const testData = {
  errorMessage: "",
  payload:
    "Let's work on this together! When we add fractions, we need to have the same denominator. \n\nFirst, can you tell me what the denominators of the fractions \\( \\frac{2}{3} \\) and \\( \\frac{6}{19} \\) are?",
};

// Replace with your actual API call
const ChatInterface: React.FC<ChatInterfaceProps> = ({
  initialChat,
  selectedId: initialSelectedId,
}) => {
  const [chat, setChat] = useState(initialChat);
  const [student_Id, setstudentId] = useState("");
  const [isNewChat, setIsNewChat] = useState(false);
  const [timeStamp, settimeStamp] = useState<string | null>(null);
  const [history, setHistory] = useState<any[]>([]);
  const [selectedId, setSelectedId] = useState<string | null>(
    initialSelectedId || null
  );
  const location = useLocation();

  const resetChat = () => {
    setChat([]);
    setIsNewChat(true);
    setSelectedId(null); // Clear selected id when resetting chat
    setHistory([]); // Clear chat history
  };

  useEffect(() => {
    const { studentId } = location.state || {};
    if (studentId) {
      setstudentId(studentId);
      console.log(studentId);
    }
  }, [location]);

  useEffect(() => {
    const now = new Date();
    const initialtimeStamp = `${student_Id}_${now.getFullYear}-${
      now.getMonth() + 1
    }-${now.getDate()}_${now.getHours()}${now.getMinutes()}${now.getSeconds()}`;
    settimeStamp(initialtimeStamp);
  }, [student_Id]);

  useEffect(() => {
    if (isNewChat) {
      const now = new Date();
      const newtimeStamp = `${student_Id}_${now.getFullYear}-${
        now.getMonth() + 1
      }-${now.getDate()}_${now.getHours()}${now.getMinutes()}${now.getSeconds()}`;
      settimeStamp(newtimeStamp);
      setIsNewChat(false);
      setSelectedId(null);
    }

    console.log(selectedId);
    // Call API if there's a selectedId
    if (selectedId && student_Id) {
      axios
        .get(
          `${process.env.REACT_APP_API_ENDPOINT}/api/math-chatbot/history/${student_Id}/${selectedId}`
        )
        .then((response) => {
          console.log("API response:", response.data);
          setHistory(response.data.payload);
          const formattedHistory = response.data.payload
            .map((item: any) => {
              if (item.user) {
                return { isUser: true, message: item.user };
              } else if (item.bot) {
                return { isUser: false, message: item.bot };
              }
              return null;
            })
            .filter((item: any) => item !== null);
          // Append the formatted history to the existing chat
          setChat((prevChat) => [...prevChat, ...formattedHistory]);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [location, isNewChat, selectedId, student_Id]);

  const renderMessageWithLatex = (message: string) => {
    const parts = message.split(/(\\\(.*?\\\))/g);
    return parts.map((part, index) => {
      if (part.match(/\\\(.*?\\\)/)) {
        return <InlineMath key={index} math={part.replace(/\\\(|\\\)/g, "")} />;
      }
      return <span key={index}>{part}</span>;
    });
  };

  return (
    <div className="chat-container-lesson-chat">
      <button className="new-chat-button" onClick={resetChat}>
        + New Chat
      </button>
      <div className="messagesWrapper-lesson-chat">
        {/* Render chat messages in chronological order */}
        {chat.map((msg, index) => {
          let renderedMessage;
          if (typeof msg.message === "string") {
            // Regular text message or message with LaTeX
            renderedMessage = renderMessageWithLatex(msg.message);
          } else {
            // Message with LaTeX
            renderedMessage = (
              <>
                {msg.message.text}
                {msg.message.latex
                  .split("\\(")
                  .map((latexPart: string, idx: number) => {
                    if (idx % 2 === 0) {
                      return <span key={idx}>{latexPart}</span>;
                    } else {
                      return (
                        <InlineMath
                          key={idx}
                          math={latexPart.replace("\\)", "")}
                        />
                      );
                    }
                  })}
              </>
            );
          }
          return (
            <div
              key={index}
              className={`chat-message ${
                msg.isUser ? "user-message" : "bot-message"
              }`}
            >
              {msg.isUser ? "User: " : "Bot: "}
              <div className="text-pre-line">{renderedMessage}</div>
            </div>
          );
        })}
      </div>
      {/* The ChatInput component remains at the bottom */}
      <ChatInput
        setChat={setChat}
        studentId={student_Id}
        timeStamp={timeStamp}
      />
    </div>
  );
};

interface ChatInputProps {
  setChat: React.Dispatch<React.SetStateAction<any[]>>;
  studentId: string;
  timeStamp: string | null;
}

const ChatInput: React.FC<ChatInputProps> = ({
  setChat,
  studentId,
  timeStamp,
}) => {
  const [message, setMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]); // New state for selected files
  const [attachmentText, setAttachmentText] = useState<string>("Attach");

  const sessiontimeStamp = timeStamp || "";

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSend();
    }
  };

  const handleSend = async () => {
    try {
      const formData = new FormData();
      formData.append("message", message);
      formData.append("student_id", studentId);
      formData.append("session_id", sessiontimeStamp);

      // Append each file individually
      if (selectedFiles.length > 0) {
        selectedFiles.forEach((file) => {
          formData.append("images", file, file.name);
        });
      }

      let response;
      response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/math-chatbot`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response) {
        throw new Error("No response from the server.");
      }
      const data = await response.json();
      console.log(data);
      if (!response.ok) {
        console.error("Server responded with an error:", data);
        return;
      }
      // Format the bot's response to match the chat structure
      const botResponse = data.payload; // Assuming the payload is already correctly formatted
      const newChat = [
        { isUser: true, message: message }, // User message
        { isUser: false, message: botResponse }, // Bot message
      ];

      // Use a functional state update to ensure the latest state is used
      setChat((prevChat) => [...prevChat, ...newChat]);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFiles(Array.from(event.target.files)); // Convert FileList to Array
    }
  };

  const triggerFileSelect = () => {
    document.getElementById("file-input")?.click(); // Trigger file input click
  };

  return (
    <div>
      <div className="chat-input-container2">
        <input
          type="text"
          className="input-field-prac"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)} // Add the onKeyDown handler here
          placeholder="Type a message..."
        />
        <button className="attachment-button" onClick={triggerFileSelect}>
          📎 {attachmentText} {/* Display dynamic text here */}
        </button>
        {/* Hidden file input */}
        <input
          id="file-input"
          type="file"
          multiple // Add the multiple attribute
          style={{ display: "none" }}
          onChange={handleFileSelect}
        />
        <button className="send-button" onClick={handleSend}>
          Send
        </button>
      </div>
    </div>
  );
};

export default ChatInterface;
export { ChatInput };
