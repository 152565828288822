import React from "react";
import styled from "styled-components";
import { useState, useEffect } from "react";
import "./PreNotes.css";

type ChatMessageProps = {
  isUser?: boolean;
  children: React.ReactNode;
};

const ChatMessage = styled.div<ChatMessageProps>`
  text-align: ${({ isUser }) => (isUser ? "right" : "left")};
`;

interface ChatInterfaceProps {
  initialChat: string[];
}

// Replace with your actual API call
const ChatInterface: React.FC<ChatInterfaceProps> = ({ initialChat }) => {
  const [chat, setChat] = useState(initialChat);

  const sendMessageToBot = async (message: string) => {
    console.log("HIHIHI");
    try {
      let response;
      if (typeof message === "string") {
        response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/api/chatbot/test`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ user_input: message }),
          }
        );
      }
      if (!response) {
        throw new Error("No response from the server.");
      }
      const data = await response.json();
      if (!response.ok) {
        console.error("Server responded with an error:", data);
        return;
      }
      // console.log(data.payload)
      const botResponse = JSON.stringify(data.payload) ?? "Message received.";
      setChat([
        ...chat,
        typeof message === "string" ? message : "Voice Message Sent",
        botResponse,
      ]);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className="ChatContainer">
      <div className="messagesWrapper_pracq">
        {/* Render chat messages in chronological order */}
        {chat.map((msg, index) => {
          const isUser = index % 2 !== 0;
          const messageClass = isUser ? "user-message" : "bot-message";

          // If the message is a string, replace \n with actual new lines for rendering
          let renderedLines;
          if (typeof msg === "string") {
            // Splitting the message by \n and mapping each part to a new line
            // console.log(msg)
            // renderedLines = msg.split('\n').map((line, i) => <span key={i}>{line}<br /></span>);
            renderedLines = msg.replace(/\\n/g, "\n");
          } else {
            // If the message isn't a string, render it directly (assuming it's a valid JSX element or other renderable content)
            renderedLines = msg;
          }

          return (
            <div key={index} className={`chat-message ${messageClass}`}>
              {isUser ? "Bot: " : "User: "}
              <div className="text-pre-line">{renderedLines}</div>
            </div>
          );
        })}
      </div>
      {/* The ChatInput component remains at the bottom */}
      <ChatInput onSendMessage={sendMessageToBot} setChat={setChat} />
    </div>
  );
};

interface ChatInputProps {
  onSendMessage: (message: string) => Promise<void>;
  setChat: React.Dispatch<React.SetStateAction<string[]>>;
}

const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage, setChat }) => {
  const [message, setMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // New state for selected file
  const [attachmentText, setAttachmentText] = useState<string>("Attach");
  const [publisher, setPublisher] = useState("");
  const [subject, setSubject] = useState("");
  const [grade, setGrade] = useState("");
  const [topic, setTopic] = useState("");
  const [length, setLength] = useState("");

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSend();
    }
  };

  const handleSend = async () => {
    try {
      // Combine message, selectedFile, and dropdown values into a single payload
      let userPayload: any = {
        input_comment: message,
        publisher: publisher,
        subject: subject,
        grade: grade,
        topic: topic,
        length: length,
      };
      console.log(userPayload);

      // Convert the payload to JSON string before sending
      const jsonStringPayload = JSON.stringify(userPayload);

      // Determine which API to call based on whether there's a file or not
      let response;
      response = await fetch(
        "http://0.0.0.0:8010/api/content-creation/pre-class",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: jsonStringPayload,
        }
      );
      // console.log(response);

      if (!response) {
        throw new Error("No response from the server.");
      }
      const data = await response.json();
      console.log(data);
      if (!response.ok) {
        console.error("Server responded with an error:", data);
        return;
      }
      const botResponse = JSON.stringify(data.payload) ?? "Message received.";
      setChat((prevChat) => [
        ...prevChat,
        typeof message === "string" ? message : "Voice Message Sent",
        botResponse,
      ]);
      setMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className="chat-input-container">
      <input
        type="text"
        className="input-field-prac"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e)} // Add the onKeyDown handler here
        placeholder="Type a message..."
      />

      <select
        className="dropdown-menu-prac"
        value={publisher}
        onChange={(e) => setPublisher(e.target.value)}
      >
        <option value="">Choose Publisher</option>
        <option value="pearson">Pearson</option>
      </select>

      <select
        className="dropdown-menu-prac"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      >
        <option value="">Choose Subject</option>
        <option value="english">English</option>
        <option value="mathematics">Mathematics</option>
      </select>

      <select
        className="dropdown-menu-prac"
        value={grade}
        onChange={(e) => setGrade(e.target.value)}
      >
        <option value="">Choose Grade</option>
        <option value="primary_1">Primary 1</option>
        <option value="primary_2">Primary 2</option>
        <option value="primary_3">Primary 3</option>
        <option value="primary_4">Primary 4</option>
      </select>

      <select
        className="dropdown-menu-prac"
        value={topic}
        onChange={(e) => setTopic(e.target.value)}
      >
        <option value="">Choose Topic</option>
        <option value="adverbs">Adverb</option>
        <option value="grammar">Grammar</option>
        <option value="4">4</option>
      </select>
      <input
        type="length"
        className="input-field-time"
        value={length}
        onChange={(e) => setLength(e.target.value)}
        placeholder="Time for practice."
      />
      <button className="send-button" onClick={handleSend}>
        Send
      </button>
    </div>
  );
};

export default ChatInterface;
export { ChatInput };
