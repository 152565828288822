import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { ReactMic, ReactMicStopEvent } from "react-mic";
import convertToWav from "audiobuffer-to-wav";
import "./MainSpeak.css";
import TextToSpeech from "../Text2speech/text2speech";
import { DotLoader } from "react-spinners";
import {
  FaMicrophone,
  FaMicrophoneSlash,
  FaHourglassHalf,
} from "react-icons/fa";
import Dictionary from "../Dictionary/Dictionary"; // Import the Dictionary component

interface SpeakInfoProps {
  topic: string;
  studentId?: string | null;
  sessionID: string;
  highlightIndex: number;
  onSubmitAction: (selectedAnswers: Record<string, string>) => void;
  status: string;
}

interface SpeakInfoResponse {
  speaking_story: string;
  speaking_assessment: Record<
    string,
    {
      speaking_question: string;
    }
  >;
}

interface QuestionOption {
  label: string;
  value: string;
  checked: boolean;
}

interface AssessmentFormState {
  [key: string]: QuestionOption[];
}

const MainSpeak: React.FC<SpeakInfoProps> = ({
  topic,
  studentId,
  sessionID,
  onSubmitAction,
  highlightIndex,
  status,
}) => {
  const location = useLocation();
  const hasFetchedData = useRef(false);
  const [student_id, setStudentId] = useState<string | null>(null);
  const [speakInfo, setSpeakingInfo] = useState<SpeakInfoResponse | null>(null);
  const [answers, setAnswers] = useState<Record<string, string>>({});
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
  const [isRecording, setIsRecording] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [recordedBlob, setRecordedBlob] = useState<{
    [key: string]: Blob | null;
  }>({});
  const [submittedQuestions, setSubmittedQuestions] = useState<{
    [key: string]: boolean;
  }>({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const recordedDataRef = useRef<{ [key: string]: ReactMicStopEvent | null }>(
    {}
  );
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [sendError, isSendError] = useState<boolean>(false);

  const [selectedWord, setSelectedWord] = useState<string | null>(null);
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [tooltipPosition, setTooltipPosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });

  useEffect(() => {
    if (!hasFetchedData.current && status === "not_started") {
      // console.log("effect")
      const { studentId } = location.state || {};
      setStudentId(studentId);

      fetchLog();
      hasFetchedData.current = true;
    }
    if (status !== "not_started") {
      // console.log("effect")
      const { studentId } = location.state || {};
      setStudentId(studentId);

      fetchLog();
      hasFetchedData.current = true;
    }
  }, []);

  const fetchLog = async () => {
    const { studentId } = location.state || {};
    setStudentId(studentId);
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based in JavaScript
    const date = String(now.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${date}`;
    // const formattedDate = `${year}-${month}-21`;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/monitor/lesson-log/${studentId}/${formattedDate}`
      );
      const botResponse = response.data.payload;
      console.log("getHistory");

      // Check if the bot response has the same structure as testData
      if (typeof botResponse === "object" && !Array.isArray(botResponse)) {
        Object.values(botResponse).forEach((record: unknown) => {
          // Type guard to ensure the record has the expected properties
          if (
            typeof record === "object" &&
            record !== null &&
            "action" in record &&
            "log" in record &&
            "topic" in record
          ) {
            const learningRecord = record as {
              action: string;
              log: SpeakInfoResponse;
              topic: string;
            };

            if (
              learningRecord.action === "speaking" &&
              learningRecord.topic === topic
            ) {
              // Display the log for the record
              setSpeakingInfo(learningRecord.log);
            }
          }
        });
      } else {
        console.warn("Unexpected bot response format.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    const { studentId } = location.state || {};
    setStudentId(studentId);
    try {
      const requestBody = {
        student_id: studentId,
        topic: topic,
        session_id: sessionID,
        topic_index: highlightIndex.toString(),
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/tools/english-speaking`,
        requestBody
      );
      setSpeakingInfo(response.data.payload);
    } catch (error) {
      console.error("Error fetching reading info:", error);
    } finally {
      setLoading(false);
      hasFetchedData.current = true;
    }
  };

  useEffect(() => {
    const allAnswered = Object.values(answers).every((answer) => answer !== "");
    setAllQuestionsAnswered(allAnswered);
  }, [answers]);

  const createHandleStopRecording = (questionId: string) => {
    return (recordedData: ReactMicStopEvent) => {
      setIsRecording((prevState) => ({
        ...prevState,
        [questionId]: false,
      }));
      recordedDataRef.current[questionId] = recordedData;
      setRecordedBlob((prevState) => ({
        ...prevState,
        [questionId]: recordedData.blob,
      }));
    };
  };

  const handleSendVoiceMessage = async (questionId: string) => {
    console.log("Sending voice message for question:", questionId);
    if (recordedBlob[questionId]) {
      try {
        setSubmitLoading(true);
        const { studentId } = location.state || {};
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const date = String(now.getDate()).padStart(2, "0");
        const initialtimeStamp = `${year}-${month}-${date}`;
        const audioContext = new AudioContext();
        const arrayBuffer = await recordedBlob[questionId]!.arrayBuffer();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        const wavArrayBuffer = convertToWav(audioBuffer, { float32: true });
        const wavBlob = new Blob([wavArrayBuffer], { type: "audio/wav" });
        const formData = new FormData();
        formData.append("audio_file", wavBlob, "audio_file.wav");
        formData.append(
          "text_message",
          speakInfo!.speaking_assessment[questionId].speaking_question
        );
        formData.append("student_id", studentId);
        formData.append("question_index", questionId);
        formData.append("topic_index", highlightIndex.toString());
        formData.append("date", initialtimeStamp);
        await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/api/tools/english-speaking-assessment`,
          formData
        );
        setRecordedBlob((prevState) => ({ ...prevState, [questionId]: null }));
        setSubmittedQuestions((prevState) => ({
          ...prevState,
          [questionId]: true,
        }));
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      } catch (error) {
        console.error("Error converting WebM to WAV or sending:", error);
        isSendError(true);
        setSubmitLoading(false);
      }
      setSubmitLoading(false);
      console.log("questionId", questionId);
      console.log("length", Object.keys(speakInfo!.speaking_assessment).length);
      if (
        Number(questionId) ===
        Object.keys(speakInfo!.speaking_assessment).length
      ) {
        onSubmitAction(answers);
      }
    }
  };

  const currentQuestionId = Object.keys(speakInfo?.speaking_assessment || {})[
    currentQuestionIndex
  ];
  const currentQuestionData = speakInfo?.speaking_assessment[currentQuestionId];

  const renderParagraphWithDictionary = (paragraph: string) => {
    return paragraph.split(" ").map((word, index) => (
      <>
        <Dictionary
          key={index}
          word={word}
          selectedWord={selectedWord}
          setSelectedWord={setSelectedWord}
          tooltipVisible={tooltipVisible}
          setTooltipVisible={setTooltipVisible}
          tooltipPosition={tooltipPosition}
          setTooltipPosition={setTooltipPosition}
        />{" "}
      </>
    ));
  };

  return (
    <>
      <h4>Speaking</h4>
      {loading ? (
        <div className="loading-container">
          <p>AIBO忙碌緊...</p>
          <DotLoader color="#21B0ED" />
        </div>
      ) : (
        <>
          {speakInfo && currentQuestionData && (
            <div key={currentQuestionId}>
              <p className="speakingQ">
                {currentQuestionId}.{" "}
                {renderParagraphWithDictionary(
                  currentQuestionData.speaking_question
                )}
                <div className="speakButtonRow">
                  <TextToSpeech
                    text={currentQuestionData.speaking_question}
                    pause={false}
                    stop={true}
                  />
                  {!submittedQuestions[currentQuestionId] ? (
                    <>
                      {isRecording[currentQuestionId] ? (
                        <button
                          className="voice-button-main"
                          onClick={() => {
                            isSendError(false);

                            setIsRecording((prevState) => ({
                              ...prevState,
                              [currentQuestionId]: false,
                            }));
                          }}
                        >
                          <FaMicrophoneSlash />
                          Stop Recording
                        </button>
                      ) : (
                        <button
                          className="voice-button-main"
                          onClick={() => {
                            setIsRecording((prevState) => ({
                              ...prevState,
                              [currentQuestionId]: true,
                            }));
                          }}
                        >
                          <FaMicrophone />
                          Record
                        </button>
                      )}
                      <ReactMic
                        record={isRecording[currentQuestionId]}
                        className="sound-wave hidden-mic"
                        onStop={createHandleStopRecording(currentQuestionId)}
                      />
                      {recordedBlob[currentQuestionId] && !sendError && (
                        <button
                          className="send-voice-button"
                          onClick={() =>
                            handleSendVoiceMessage(currentQuestionId)
                          }
                          disabled={submitLoading}
                        >
                          Submit
                        </button>
                      )}
                      {submitLoading ? <DotLoader color="#21B0ED" /> : <></>}
                      {sendError && (
                        <p className="error-message">
                          AIBO老師聽唔清楚，試下錄多次！加油！
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      <button className="voice-button-main" disabled>
                        🎙️Play
                      </button>
                      <button className="send-voice-button" disabled>
                        Submitted
                      </button>
                    </>
                  )}
                </div>
              </p>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MainSpeak;
