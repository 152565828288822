// Setup function for this ParentPage
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ParentTable from "../../components/ParentTable/ParentTable";
import StudentTable from "../../components/PStudyPlan/PStudyPlan";
import StudentInformation from "../../components/PInfoTab/PInfoTab";
import "./component/style.css";

export default function ParentPage() {
  const [showForm, setShowForm] = useState(true);
  const [studentId, setStudentId] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowForm(false);
  };

  useEffect(() => {
    const { studentId } = location.state || {};
    setStudentId(studentId);
  }, []);
  const handleLogout = () => {
    navigate("/");
  };
  return (
    <div className="page-container-parent">
      <div className="parent-page">
        <header className="header">
          <div className="header-left">
            <h1 className="title">家長報告平台</h1>
          </div>
          <div className="header-right">
            <button className="header-button" onClick={handleLogout}>
              登出
            </button>
          </div>
        </header>
        <div className="main-content-parent">
          {!studentId ? (
            <form className="studentId-form" onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Enter Student ID"
                value={studentId}
                onChange={(e) => setStudentId(e.target.value)}
              />
              <button type="submit">提交</button>
            </form>
          ) : (
            <>
              <div className="tutor-wrapper">
                <div className="top-main-content">
                  <div className="sidebar">
                    <div className="student-info">
                      <StudentInformation studentId={studentId} />
                    </div>
                  </div>
                  <div className="interactive-area-parent">
                    <div className="interactive-content">
                      <ParentTable studentId={studentId} />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
