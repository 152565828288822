import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import { useLocation } from "react-router-dom";

const Box = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
`;

interface DocTabProps {
  studentId?: string | null;
}

interface StudentDocResponse {
  textbook: Record<string, SubInfo>;
}

interface SubInfo {
  subject: string;
  publisher: string;
  grade: string;
}

const Documents: React.FC<DocTabProps> = ({ studentId }) => {
  const [docInfo, setDocInfo] = useState<SubInfo[]>([]);
  const [student_id, setStudentId] = useState<string | null>(studentId || null);
  const location = useLocation();
  const hasFetchedData = useRef(false);

  useEffect(() => {
    const { studentId } = location.state || {};
    setStudentId(studentId);

    if (!hasFetchedData.current && studentId) {
      const fetchData = async () => {
        try {
          const response = await axios.get<StudentDocResponse>(
            `${process.env.REACT_APP_API_ENDPOINT}/api/students/${studentId}`
          );
          // Type the textbooks variable to match the expected structure
          const textbooks = response.data.textbook;
          const formattedTextbooks = Object.entries(textbooks).map(
            ([subject, info]: [string, SubInfo]) => ({
              subject,
              publisher: info.publisher,
              grade: info.grade,
            })
          );
          setDocInfo(formattedTextbooks);
          console.log(response.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
      hasFetchedData.current = true;
    }
  }, [location.state]);

  return (
    <Box>
      <h3>Documents</h3>
      {/* Map through the documents array */}
      {docInfo.map((textbook, index) => (
        <div key={index}>
          <p>{textbook.subject}:</p>
          <p>{textbook.publisher}</p>
          <p>Grade: {textbook.grade}</p>
        </div>
      ))}
    </Box>
  );
};

export default Documents;
