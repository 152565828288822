import React, { useRef, useState, useEffect, act } from "react";
import { useLocation } from "react-router-dom";
import "./MainChatInterface.css";
import { DotLoader, BeatLoader } from "react-spinners";
import { FaMicrophone, FaMicrophoneSlash } from "react-icons/fa";
import axios from "axios";
import {
  SpeechConfig,
  SpeechSynthesizer,
  ResultReason,
  AudioConfig,
  SpeechRecognizer,
} from "microsoft-cognitiveservices-speech-sdk";
import { HiMiniSpeakerWave } from "react-icons/hi2";
import { FaCopy } from "react-icons/fa";

interface TeachingResponse {
  note: string;
  script: string;
  length: number;
}

interface ChatInterfaceProps {
  initialChat: any[];
  topic: string;
  action: string;
  highlightIndex: number;
  onSessionID: (sessionID: string) => void;
  studentId?: string | null;
  topicNum: number;
  status: string;
  setAudioPlaying: (audioPlaying: boolean) => void;
  setEnd: (end: boolean) => void;
  idleResponse: string;
  setPassiveIdleTime: (passiveIdleTime: number) => void;
}

interface ChatMessage {
  type: "user" | "streamBot";
  chat_index: number;
  content: string;
}

const ChatInterface: React.FC<ChatInterfaceProps> = ({
  initialChat,
  topic,
  action,
  highlightIndex,
  onSessionID,
  studentId,
  topicNum,
  status,
  setAudioPlaying,
  setEnd,
  idleResponse,
  setPassiveIdleTime,
}) => {
  const [chat, setChat] = useState<ChatMessage[]>(
    initialChat.map((msg) => ({
      type: "streamBot",
      chat_index: 0,
      content: msg,
    }))
  );
  const [student_id, setStudentId] = useState<string | null>(studentId || null);
  const location = useLocation();
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [chatIndex, setChatIndex] = useState(0);
  const [wholeResponse, setWholeResponse] = useState<TeachingResponse>();
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(
    null
  );
  const [currentAudioIndex, setCurrentAudioIndex] = useState<number>(0);
  const [audioIsPlaying, setAudioIsPlaying] = useState(false);
  const [sentFirstMessage, setSentFirstMessage] = useState(false);

  const key = process.env.REACT_APP_AZURE_SPEECH_KEY; // Add your subscription key here
  const region = "eastus"; // Add your region here
  const speechConfig = SpeechConfig.fromSubscription(key, region);
  speechConfig.speechSynthesisVoiceName = "zh-HK-HiuMaanNeural";
  const audioConfig = AudioConfig.fromDefaultSpeakerOutput();
  const synthesizer = new SpeechSynthesizer(speechConfig, audioConfig);

  const speakText = (text: string) => {
    synthesizer.speakTextAsync(
      text,
      (result) => {
        if (result.reason === ResultReason.SynthesizingAudioCompleted) {
          // console.log("Synthesis finished.");
          // console.log(result.audioDuration);
          setAudioIsPlaying(true);

          //wait for audio duration
          setTimeout(() => {
            // console.log("Resuming audio...");
            if (text.includes("老師走先喇")) {
              setEnd(true);
            }
            setAudioIsPlaying(false);
          }, result.audioDuration / 10000);
        } else {
          console.error(
            `Speech synthesis canceled: ${result.errorDetails} \nDid you set the speech resource key and region values?`
          );
        }
        synthesizer.close();
      },
      (err) => {
        console.trace(`Error: ${err}`);
        synthesizer.close();
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    const { studentId } = location.state || {};
    setStudentId(studentId);
    if (action === "learning") {
      const formData = new FormData();
      formData.append("topic", topic);
      formData.append("student_id", studentId);
      formData.append("topic_index", highlightIndex.toString());
      formData.append("agent_type", "learning");
      formData.append("message", "hi");
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based in JavaScript
      const date = String(now.getDate()).padStart(2, "0");
      const initialtimeStamp = `${studentId}_${year}-${month}-${date}_topic1`;
      formData.append("session_id", initialtimeStamp);
      onSessionID(initialtimeStamp);
      // setAPItools("tools/english-teaching");
      // console.log(wholeResponse);
      if (sentFirstMessage === false) {
        sendFirstMessageToBot(formData);
        setSentFirstMessage(true);
        console.log("sendFirstMessageToBot");
      }
    }
  }, [action]);

  let hasFetchedData = false; // Flag to track whether data has been fetched
  useEffect(() => {
    // console.log(hasFetchedData);
    // console.log(status);
    if (hasFetchedData === false && status !== "not_started") {
      hasFetchedData = true;
      // fetchLog();
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based in JavaScript
      const date = String(now.getDate()).padStart(2, "0");
      const initialtimeStamp = `${studentId}_${year}-${month}-${date}`;
      onSessionID(initialtimeStamp);
    }
  }, []); // Empty dependency array ensures this effect runs only once

  useEffect(() => {
    if (idleResponse !== "") {
      setChat((prevChat) => [
        ...prevChat,
        {
          type: "streamBot",
          chat_index: chatIndex,
          content: idleResponse,
        },
      ]);
      setChatIndex(chatIndex + 1);
      speakText(idleResponse);
    }
  }, [idleResponse]);

  // const fetchLog = async () => {
  //   const { studentId } = location.state || {};
  //   setStudentId(studentId);
  //   const now = new Date();
  //   const year = now.getFullYear();
  //   const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based in JavaScript
  //   const date = String(now.getDate()).padStart(2, "0");
  //   const formattedDate = `${year}-${month}-${date}`;
  //   // const formattedDate = `${year}-${month}-21`;
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_ENDPOINT}/api/tools/monitor/lesson-log/${studentId}/${formattedDate}`
  //     );
  //     const botResponse = response.data.payload;
  //     // console.log("getHistory");
  //     // Check if the bot response has the same structure as testData
  //     if (typeof botResponse === "object" && !Array.isArray(botResponse)) {
  //       Object.values(botResponse).forEach((record: unknown) => {
  //         // Type guard to ensure the record has the expected properties
  //         if (
  //           typeof record === "object" &&
  //           record !== null &&
  //           "action" in record &&
  //           "log" in record
  //         ) {
  //           const learningRecord = record as {
  //             action: string;
  //             log: string;
  //           };
  //           if (learningRecord.action === "learning") {
  //             // Display the log for the record
  //             setChat((prevChat) => [
  //               ...prevChat,
  //               {
  //                 type: "streamBot",
  //                 chat_index: chatIndex,
  //                 content: learningRecord.log,
  //               },
  //             ]);
  //           }
  //         }
  //       });
  //     } else {
  //       console.warn("Unexpected bot response format.");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const sendStreamMessageToBot = async (
    message: FormData,
    messageContent: string
  ) => {
    try {
      setChat([
        ...chat,
        { type: "user", chat_index: chatIndex, content: messageContent },
      ]);
      setChatIndex(chatIndex + 1);
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/agent`,
        {
          method: "POST",
          body: message,
        }
      );
      console.log(response);
      if (!response.body) {
        throw new Error("No response body.");
      }
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let botMessage = "";
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        botMessage += decoder.decode(value, { stream: true });
        setChat((prevChat) => {
          const updatedChat = [...prevChat];
          updatedChat[chatIndex + 1] = {
            type: "streamBot",
            chat_index: chatIndex + 1,
            content: botMessage,
          };
          return updatedChat;
        });
      }
      speakText(botMessage);
      setChatIndex(chatIndex + 2);
    } catch (error) {
      console.error("Error reading stream:", error);
    }
  };

  const sendFirstMessageToBot = async (message: FormData) => {
    // setLoading(true);
    // console.log(message);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/agent`,
        {
          method: "POST",
          body: message,
        }
      );
      console.log(response);
      if (!response.body) {
        throw new Error("No response body.");
      }
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let botMessage = "";
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        botMessage += decoder.decode(value, { stream: true });
        setChat((prevChat) => {
          const updatedChat = [...prevChat];
          updatedChat[chatIndex] = {
            type: "streamBot",
            chat_index: chatIndex,
            content: botMessage,
          };
          return updatedChat;
        });
      }
      console.log(botMessage);
      speakText(botMessage);
      setChatIndex(chatIndex + 1);
      console.log(chat);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      // setLoading(false);
    }
  };

  const stopCurrentAudio = () => {
    if (currentAudio) {
      setAudioIsPlaying(false);
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setCurrentAudio(null);
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chat]);

  useEffect(() => {
    if (audioIsPlaying) {
      setAudioPlaying(true);
    } else {
      setAudioPlaying(false);
    }
  }, [audioIsPlaying]);

  return (
    <div className="ChatContainer-main">
      <div className="convoContainer">
        <div className="messagesWrapper-main">
          {loading === true ? (
            <div className="loading-container-chatbot">
              <p>
                <strong>Loading...</strong>
              </p>
              <DotLoader color="#21B0ED" />
            </div>
          ) : (
            <>
              {chat.map((msg, index) => {
                return (
                  <>
                    <div
                      key={index}
                      className={`chat-message  ${msg.type}-message`}
                    >
                      <span className="stream-message">
                        {msg.content.replace(/\\n/g, "\n")}
                      </span>
                      {msg.type === "streamBot" ? (
                        <div className="icon-list">
                          {!audioIsPlaying ? (
                            <HiMiniSpeakerWave
                              className="speaker-icon"
                              onClick={() => speakText(msg.content)}
                            />
                          ) : (
                            <HiMiniSpeakerWave className="speaker-icon-disabled" />
                          )}
                          <FaCopy
                            className="copy-icon"
                            onClick={() => {
                              navigator.clipboard.writeText(msg.content);
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                  </>
                );
              })}
              <div ref={messagesEndRef} />
            </>
          )}
        </div>
        {/* The ChatInput component remains at the bottom */}
        <ChatInput
          onSendMessage={sendStreamMessageToBot}
          studentId={studentId}
          highlightIndex={highlightIndex}
          topic={topic}
          action={action}
          topicNum={topicNum}
          loading={loading}
          stopCurrentAudio={stopCurrentAudio}
          audioIsPlaying={audioIsPlaying}
          setPassiveIdleTime={setPassiveIdleTime}
        />
      </div>
    </div>
  );
};

interface ChatInputProps {
  onSendMessage: (message: FormData, messageContent: string) => void;
  topic: string;
  action: string;
  highlightIndex: number;
  studentId?: string | null;
  topicNum: number;
  loading: boolean;
  stopCurrentAudio: () => void;
  audioIsPlaying: boolean;
  setPassiveIdleTime: (passiveIdleTime: number) => void;
}

const ChatInput: React.FC<ChatInputProps> = ({
  onSendMessage,
  topic,
  action,
  highlightIndex,
  studentId,
  topicNum,
  loading,
  stopCurrentAudio,
  audioIsPlaying,
  setPassiveIdleTime,
}) => {
  const [message, setMessage] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [mentionedTeacher, setMentionedTeacher] = useState(false);
  const [readyToSendMessage, setReadyToSendMessage] = useState(false);
  const location = useLocation();
  const [recognizer, setRecognizer] = useState<SpeechRecognizer | null>(null);
  const [partialResult, setPartialResult] = useState("");

  useEffect(() => {
    if (readyToSendMessage) {
      handleSend();
      setReadyToSendMessage(false);
    }
  }, [readyToSendMessage]);

  const handleSend = async () => {
    if (!message.trim()) {
      // If the message is empty, do not proceed
      return;
    }
    try {
      const { studentId } = location.state || {};
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const date = String(now.getDate()).padStart(2, "0");
      const initialtimeStamp = `${studentId}_${year}-${month}-${date}`;
      const formData = new FormData();
      formData.append("message", message);
      formData.append(
        "agent_type",
        action === "learning" ? "learning" : "general"
      );
      formData.append("session_id", initialtimeStamp);
      formData.append("topic", topic);
      formData.append("student_id", studentId);
      formData.append("topic_index", highlightIndex.toString());
      if (selectedFile) {
        formData.append("image", selectedFile, selectedFile.name);
        setSelectedFile(null);
      }
      setMessage("");
      stopCurrentAudio(); // Stop the current audio before sending the message
      await onSendMessage(formData, message);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSend();
    }
  };

  const startAzureSpeechRecognition = () => {
    const speechConfig = SpeechConfig.fromSubscription(
      process.env.REACT_APP_AZURE_SPEECH_KEY!,
      "eastus"
    );
    speechConfig.speechRecognitionLanguage = "zh-HK";
    const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
    const recognizer = new SpeechRecognizer(speechConfig, audioConfig);

    recognizer.recognizing = (s, e) => {
      if (e.result.reason === ResultReason.RecognizingSpeech) {
        setPartialResult(e.result.text);
      }
    };

    recognizer.recognized = (s, e) => {
      if (e.result.reason === ResultReason.RecognizedSpeech) {
        setMessage((prevMessage) => prevMessage + e.result.text);
        setPartialResult(""); // Clear partial result
      } else if (e.result.reason === ResultReason.NoMatch) {
        console.error("No speech could be recognized.");
      }
    };

    recognizer.canceled = (s, e) => {
      console.error(`Recognition canceled: ${e.errorDetails}`);
      recognizer.stopContinuousRecognitionAsync();
      setIsRecording(false);
    };

    recognizer.sessionStopped = (s, e) => {
      recognizer.stopContinuousRecognitionAsync();
      setIsRecording(false);
    };

    recognizer.startContinuousRecognitionAsync();
    setRecognizer(recognizer);
  };

  const handleStopRecording = () => {
    if (recognizer) {
      recognizer.stopContinuousRecognitionAsync();
    }
    setReadyToSendMessage(true);
    setIsRecording(false);
  };

  const handleStartRecording = () => {
    setIsRecording(true);
    startAzureSpeechRecognition();
    stopCurrentAudio(); // Stop the current audio before starting the recording
  };

  const insertQuickInput = (text: string) => {
    setMessage((prevMessage) => prevMessage + text);
  };

  const quickInputs = [
    { text: "...點讀架？", onClick: () => insertQuickInput("點讀架？") },
    { text: "...咩意思呀？", onClick: () => insertQuickInput("咩意思呀？") },
    { text: "...嘅英文...", onClick: () => insertQuickInput("嘅英文") },
  ];

  return (
    <div className="chat-inputs-container">
      <div className="chat-inputs">
        <div className="chat-input-quick-input">
          <div className="quick-input-title">快速輸入:</div>
          {quickInputs.map((input, index) => (
            <div key={index} className="quick-input" onClick={input.onClick}>
              {input.text}
            </div>
          ))}
        </div>
        <div className="chat-input-container1-MCI">
          {!mentionedTeacher ? (
            <input
              type="text"
              className="input-field-main"
              value={loading ? "" : message + partialResult}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e)}
              disabled={isRecording || audioIsPlaying}
              placeholder="問一問AIBO老師！"
            />
          ) : (
            <BeatLoader />
          )}
        </div>
        <div className="chat-input-container2-MCI">
          {isRecording ? (
            <button className="voice-button-main" onClick={handleStopRecording}>
              <FaMicrophoneSlash />
              停止錄音
            </button>
          ) : (
            <button
              className={`voice-button-main ${
                audioIsPlaying ? "button-disabled" : ""
              }`}
              onClick={handleStartRecording}
              disabled={audioIsPlaying}
            >
              <FaMicrophone />
              開始錄音
            </button>
          )}
          {loading === true ? (
            <button className="send-button-main">⏳Loading...</button>
          ) : (
            <button
              className={`send-button-main ${
                audioIsPlaying ? "button-disabled" : ""
              }`}
              onClick={handleSend}
              disabled={!message.trim() || audioIsPlaying}
            >
              📩發送
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatInterface;
export { ChatInput };
